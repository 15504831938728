// 블로그데이터를 클릭하게 되면 들어오게 되는 자세히 보기 페이지입니다.
// 블로그 데이터를 클릭시 글 idx 값을 넘겨 주어서 자세히 조회를 진행합니다.
import React from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
// 로딩용 이미지
import loading_gif from '../../images/loading.gif';
//통신 유틸
import * as Api from '../../utils/Api';
// 함수 유틸
import {ie_check, get_previous_url} from '../../utils/Func';

//css 임포트
import './Page_Read.css';
// ui 임포트
import {Button} from 'primereact/button';
import {OverlayPanel} from 'primereact/overlaypanel';
import {Messages} from 'primereact/messages';
import {Editor } from 'react-draft-wysiwyg';
import {InputTextarea} from 'primereact/inputtextarea';
import {Password} from 'primereact/password';
import {Dialog} from 'primereact/dialog';
import {ProgressBar} from 'primereact/progressbar';
import {EditorState, ContentState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

// seo 검색용의성을 위한 헬멧 추가
import {Helmet} from 'react-helmet'

// 언어 
import * as lang_pack from '../../utils/language_pack'; 
// 함수 유틸 패키지
import * as util from '../../utils/Func';

class page_read extends React.Component{
     //저장하지 않는 스테이터스들
     constructor(props) {
        super(props);

        this.state = {
            board_idx : "",     //Link 로 넘어온 board idx값                
            type : this.props.lang,        //언어별 구분
            name : "",          //작성자
            thumnail_img : "",  //썸네일 이미지
            profile_img : "",     //  프로필 이미지
            profile_explain : [],   //프로필 설명
            profile_idx : "",       //작성자 idx값
            reg_dt : "",    //등록일
            tags : "",      //태그
            title : "",     //타이틀
            sub_title : "", //요약
            view_count : "", //조회수            
            content_state : EditorState.createEmpty(), //에디터 스테이트를 담는다.
            is_del : false, //수정 삭제를 구분하기 위한 구분자
            
            // 스크롤관련
            bring_top_btn : "none",  //스크롤시 가장 최상위로 올려주는 버튼을 보여줄지 말지 선택하는 메서드
            now_scroll_percent : 0,  //현재 위치한 포지션값
            scroll_visible : false,       //페이지의 전체 height

            // 댓글 관련 스테이터스
            // 댓글 작성
            write_comment_text : "",    //댓글 작성 택스트
            write_comment_pw : "",      //댓글 작성 비밀번호

            // 대댓글 작성
            reply_show_id : "", //답글 달기 버튼 보이기 안보이기를 담당하는 변수
            write_reply_text : "",
            write_reply_pw : "",

            // 댓글 리스트
            comment_list : [],  //전체 댓글 리스트
            comment_tot_cnt :   "0",  //댓글 총 개수
            comment_ori_last : 0,  //최상위 댓글 마지막 인덱스

            // 댓글 에러 다이얼로그
            comment_err_visible : false,    //에러 다이얼로그 보여주니?
            comment_err_text    :  ""   ,   //에러 다이얼로그에 보여줄 텍스트

            // 댓글 삭제 dialog 관리
            // 삭제
            comment_del_visible : false,
            comment_del_text : "",
            // 수정
            comment_modi_visible : false,
            comment_modi_text : "",
            comment_modi_write : "",


            // 댓글 관리에 사용할 맵
            comment_map : {}, 

            //언어
            lang_data : {},

            // 등록 버튼 disabled 속성 관리
            add_btn_disabled : false,

            // 페이지 통신이 다 되었는지 판단
            is_loading_ok : false,

       };

       //펑션 바인딩
       this.board_del = this.board_del.bind(this);
       this.board_modify = this.board_modify.bind(this);
    }

    componentWillMount(){
        //console.log(this.props.lang);
        //언어 관리
        let lang_data;
        if(this.props.lang ===lang_pack.KOREAN){
            lang_data = lang_pack.lang_pageread(lang_pack.KOREAN);
            
        }else if(this.props.lang ==="EN"){
            lang_data = lang_pack.lang_pageread(lang_pack.ENGLISH);
        }
        this.setState({
            lang_data : lang_data
        })

        get_previous_url();
    }

    componentWillReceiveProps(next_props) {    
        //언어 관리
        let lang_data;
        if(next_props.lang ===lang_pack.KOREAN){
            lang_data = lang_pack.lang_pageread(lang_pack.KOREAN);
        }else if(next_props.lang ==="EN"){
            lang_data = lang_pack.lang_pageread(lang_pack.ENGLISH);
        }
        this.setState({
            lang_data : lang_data
        })
      }
    
    // 넘어온 데이터값을 조회합니다.
    // static getDerivedStateFromProps(data){
    componentDidMount() {
        window.scrollTo(0,0);
        
        let current = this; 
        //url 로 받은 board_idx 를 저장해둡니다.
        let board_idx = this.props.match.params.idx;
        // console.log(board_idx+"/"+this.props.lang);
        // 받은 idx값으로 통신을 진행합니다.
        // 통신 관련은 utils.Api 를 확인하세요.
        //통신을 위한 데이터 형식 가져우기 
        let send_data = Api.BOARD_GET(board_idx,this.props.match.params.lang);

        //통신을 진행합시다!
        Api.axios_instance.post(`${process.env.REACT_APP_BOARD_URL}`,send_data)
        .then(function(res){
            //리턴값 체크
            var retJson = res.data;
            // console.log(retJson);            
            //성공 코드
            if(retJson["retCode"] === 0){
                //리스트 가져오기
                var list = retJson["retBody"]["body"]["list"][0];
                //console.log(list["content"]); 
                //리스트값 스테이터스에 넣어주기
                let content;
                //일단 널값 체크
                if(list["content"] === null || list["content"] === undefined){
                    // content = EditorState.createEmpty();
                    //컨텐츠 내용이 없으면 404페이지로 이동시킨다.
                    // 뒤로가기 버튼 클릭시 홈화면으로 이동시키기 위해서 history에 넣어줌

                    current.props.history.push('/home');
                    current.props.history.push('/404_page'); 

                }else{
                    //https://www.npmjs.com/package/html-to-draftjs 참고하기
                    // 2019-06-20 해상도 별로 글자 크기 조절
                    const {contentBlocks, entityMap} = htmlToDraft(current.device_fit_str(list["content"]));
                    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                    content = EditorState.createWithContent(contentState);

                }
                // 댓글 정보 가져오기
                var temp_comment_totcnt  =   retJson["retBody"]["comment"]["totCnt"];    //댓글 총 개수
                var temp_comment_list = retJson["retBody"]["comment"]["list"];           //댓글 정보 리스트
                
                //최상위, 댓글 순서 파싱
                var temp_comment_ori_last = 0;
                for(var i = 0 ; i < temp_comment_list.length;i++){
                    var ori_no = temp_comment_list[i]["ori_no"]*1;
                    // console.log(ori_no);
                    if(temp_comment_ori_last <= ori_no) temp_comment_ori_last = ori_no;
                }
                current.setState({
                    thumnail_img : list['thumnail_img'],
                    name : list['name'],
                    profile_img : list['profile_img'],
                    profile_explain : list['default_explain'].split('\n'),
                    profile_idx : list['idx_member'],
                    reg_dt : list['reg_dt'],
                    tags : list['tags'],
                    title : list['title'],
                    sub_title : list['sub_title'],
                    view_count : list['view_count'],
                    content_state : content,
                    board_idx : current.props.match.params.idx,
                    // 댓글 정보
                    comment_tot_cnt : parseInt(temp_comment_totcnt)+1,
                    comment_list : (temp_comment_totcnt === "0") ? null : temp_comment_list,
                    comment_ori_last : (temp_comment_ori_last*1)+1,
                    lang : current.props.match.params.lang,
                    is_loading_ok : true,
                });



                window.scrollTo(0, 0);
                //스크롤 이벤트를 적용시켜서 , 일정 범위(10% 이상 내려갔을 경우 맨위로 올리기 버튼 만들어줌.)
                window.addEventListener('scroll', current.handle_scroll);
            }
        }).catch(function(err){
            console.log(err);
        });

         
    }
    // 언마운트
    componentWillUnmount() {
        window.removeEventListener("scroll",this.handle_scroll);
    }

    
    device_fit_str = (str) =>{
        //기기 해상도를 구해서 css에서 다룰 수 없는 span 태그 (작성 p 태그 ) 폰트 사이즈를 화면에 맞게 변경해줍니다.
        // var size = {
        //     width: window.innerWidth || document.body.clientWidth,
        //     height: window.innerHeight || document.body.clientHeight 
        // }

        //텍스트 변경사항
        //기본 p 태그 폰트 사이즈 종류 18,16,14,12,11,10,9,8,5,3
        //사이즈별 p태그 폰트 사이즈
        // 작은 글 태그부터 변경 : 큰 태그 부터 변경하면 쭉쭉타고 내려와서 제일 작은 사이즈가 된다.
        //1024
        //16,14,12,11,10,9,8,5,3,3
        // 2019-07-12 
        // 변경사항 -> 따로 컨트롤 하려고 했으나, 에디터 라이브러리의 기본 폰트 미지정
        // 으로 인해 에디터의 자동 변경에 맡기기로 함.

        // if(size["width"] < 1025){
        //     str = str.split("font-size: 3px").join("font-size: 3px");
        //     str = str.split("font-size: 5px").join("font-size: 5px");
        //     str = str.split("font-size: 8px").join("font-size: 8px");
        //     str = str.split("font-size: 9px").join("font-size: 9px");
        //     str = str.split("font-size: 10px").join("font-size: 10px");
        //     str = str.split("font-size: 12px").join("font-size: 11px");
        //     str = str.split("font-size: 14px").join("font-size: 12px");
        //     str = str.split("font-size: 16px").join("font-size: 14px");
        //     str = str.split("font-size: 18px").join("font-size: 16px");
        // }else if (size["width"] < 769){
        //     //769
        //     //14,12,11,10,9,8,5,3,3
        //     str = str.split("font-size: 3px").join("font-size: 3px");
        //     str = str.split("font-size: 5px").join("font-size: 3px");
        //     str = str.split("font-size: 8px").join("font-size: 5px");
        //     str = str.split("font-size: 9px").join("font-size: 8px");
        //     str = str.split("font-size: 10px").join("font-size: 9px");
        //     str = str.split("font-size: 12px").join("font-size: 10px");
        //     str = str.split("font-size: 14px").join("font-size: 11px");
        //     str = str.split("font-size: 16px").join("font-size: 12px");
        //     str = str.split("font-size: 18px").join("font-size: 14px");
        // }else if (size["width"] < 415){
        //     //769
        //     //12,11,10,9,8,5,3,3,3
        //     str = str.split("font-size: 3px").join("font-size: 4px");
        //     str = str.split("font-size: 5px").join("font-size: 4px");
        //     str = str.split("font-size: 8px").join("font-size: 4px");
        //     str = str.split("font-size: 9px").join("font-size: 6px");
        //     str = str.split("font-size: 10px").join("font-size: 7px");
        //     str = str.split("font-size: 12px").join("font-size: 8px");
        //     str = str.split("font-size: 14px").join("font-size: 9px");
        //     str = str.split("font-size: 16px").join("font-size: 10px");
        //     str = str.split("font-size: 18px").join("font-size: 11px");
        // }
        // console.log(str);
        return str;
    }

    //태그리스트의 구성을 담당.
    get_tag_list(arr_tags){
        var tag_list = "";
        //',' 구분자로 이루어진 태그들을 스플릿 후 
        var arr_tags_split = arr_tags.split(',');
        //버튼형태로 반환합니다.
        //버튼은 특별한 의미는 없고 ui 적 요소로서만 사용합니다.
        for(var i in arr_tags_split){
            // tag_list.push(arr_tags_split[i]);
            tag_list += "#"+arr_tags_split[i]+"    ";
        }
        return (
            <span id="tag_item_span" >{tag_list}</span>
        )
        // var counter = 0;
        // return tag_list.map((item) => {
        //     counter++;
        //     return(
        //         // <Button  key={counter} label={item} className="p-button-secondary"  style={{marginRight : "5px",marginTop : "3px"}}/>
        //         <span key={counter} id="tag_item_span">#{item} </span>
        //     )
        // })
    }

    /*********************수정 삭제 권한 주기***************************** */
    //내가 쓴글 인지 파악해서 수정 --> Write.js 페이지로 보내고 
    //삭제 --> state = "N" 으로 변경처리 합니다.

    // ui 관련
    modify_board_ui(idx_member){      //멤버의 idx값으로 받습니다.
        //로그인 안했으면 리턴
        if(!this.props.is_login) return; 
        //내가쓴글이 아니면 리턴
        // console.log(this.state.profile_idx);
        // console.log(this.props.idx);
        if(idx_member !== this.props.idx) return;
        return(
            <span className="read_modify_content"> 
                <Button type="button" label="수정" onClick={this.board_modify} className="p-button-raised p-button-secondary"/>
                <Button type="button" label="삭제" onClick={(e) => this.op.toggle(e)} className="p-button-raised p-button-danger"/>
                <OverlayPanel ref={(el) => this.op = el}>
                    <div>
                        <h3>정말로 삭제 하시겠습니까?</h3>
                        <Button label="확인" className="p-button-success" onClick={this.board_del}/>
                        <Button label="취소" className="p-button-secondary"/>
                    </div>
                </OverlayPanel>
            </span>
        )
    }
    //삭제 기능
    board_del(){
        let current = this;

        let data = Api.BOARD_DEL(this.props.match.params.idx,this.state.type);
        Api.axios_instance.post(`${process.env.REACT_APP_BOARD_URL}`,data) 
        .then(function(res){
            //리턴값 체크
            var retJson = res.data;
            //console.log(retJson);            
            //성공 코드
            if(retJson["retCode"] === 0){
                current.props.history.push('/home');
            }else{
                current.showError("게시판 삭제","게시판 삭제에 실패 했습니다");
            }
        }).catch(function(err){
            console.log(err);
        });
    }
    //수정기능
    //작성 페이지와 동일한 페이지로 보내서 재사용합니다.
    board_modify(){
        this.props.history.push('/write/modify_board/'+this.props.match.params.idx);
    }
    /******************메세지************************ */
    showError(title,content) {
        this.messages.show({severity: 'error', summary: title, detail: content});
    }
    // 스크롤 콜백 메서드
    handle_scroll = (e) =>{
        if(ie_check()) return;

        let { scrollHeight } = document.body;                         //총 높이 
        let my_scroll_pos = window.scrollY;                            //현재 스크롤 위치
        let max_height_per_10 = scrollHeight* 0.10;                   //위로 버튼을 보여주고 싶은 기준 (10%)        

        //스크롤 퍼센트
        let my_scroll_percent = parseInt(((my_scroll_pos+window.innerHeight) / scrollHeight)*100);      //현제 스크롤의 마지막 위치
        // 예외처리 5 이하는 0으로 계산 100이상은 100으로 계산
        if(my_scroll_percent < 10) my_scroll_percent = 0;
        else if( my_scroll_percent >= 100) my_scroll_percent = 100;
        // 전체 길이가 짧다면 페이지 퍼센트를 보여주지 않습니다.
        let page_per_visible = (scrollHeight > 10000) ? true : false ;

        //console.log(my_scroll_pos+"/"+scrollHeight +"/"+max_height_per_10);
        if(my_scroll_pos >= max_height_per_10){
            this.setState({
                bring_top_btn : "",
                now_scroll_percent : my_scroll_percent,
                scroll_visible : page_per_visible
            })
        }else{
            this.setState({
                bring_top_btn : "none",
                now_scroll_percent : my_scroll_percent,
                scroll_visible : page_per_visible
            })   
        }
        
    }
    // 최상위로 올려주는 펑션
    move_top=()=>{
        window.scrollTo(0,0);
    }

    // **********************************************
    // **************   댓글    *********************
    // *********************************************
    // 댓글 버튼 아이콘 통신
    comment_btn_click =(data) => {
        this.setState({
            add_btn_disabled : false
        })
        var split = data.target.alt.split("_");
        var type = split[0];            //버튼 타입
        var ori_no = split[1];          //최상위, 댓글 인덱스
        var depth = split[2];           //깊이

        var send_type;
        //타입 구분
        switch(type){
            case "m":
                send_type = "modify";
                // 댓글 정보 파싱
                var comment ;
                for(var i = 0 ; i < this.state.comment_list.length; i++){
                    if(this.state.comment_list[i]["ori_no"] === ori_no && this.state.comment_list[i]["depth"] === depth){
                        comment = this.state.comment_list[i]["comment"];
                    }
                }
 
                this.setState({
                    comment_modi_visible : true,
                    comment_modi_text : "댓글을 수정합니다",
                    comment_map : {
                        comment : comment,
                        type : send_type,
                        ori_no : ori_no,
                        depth : depth
                    },
                    comment_modi_write : comment,
                })
                return;
            case "d":
                send_type = "delete";
                this.setState({ 
                    comment_del_visible : true,
                    comment_del_text : "댓글이 삭제됩니다",
                    comment_map : {
                        type : send_type,
                        ori_no : ori_no,
                        depth : depth
                    }
                })
                return;
            case "b":
                send_type = "ban";
                this.setState({
                    comment_del_visible : true,
                    comment_del_text : "댓글 차단 비밀번호를 입력하지 않아도 됩니다",
                    comment_map : {
                        type : send_type,
                        ori_no : ori_no,
                        depth : depth
                    }
                })
                break;
            default : break;
        }
    }
    // 댓글 삭제 통신
    on_comment_del = () => {
        var current = this;
        current.setState({
            add_btn_disabled : true
        })
        var data_map = this.state.comment_map;

        let send_data = Api.BOARD_COMMENT_MANAGE(
            this.state.type,
            this.state.board_idx,
            this.state.write_reply_pw,
            "",
            data_map["ori_no"],
            data_map["depth"],
            data_map["type"]
        )

        Api.axios_instance.post(`${process.env.REACT_APP_BOARD_URL}`,send_data) 
        .then(function(res){
            //리턴값 체크
            var retJson = res.data;
            //console.log(retJson);            
            //성공 코드
            if(retJson["retCode"] === 0){
                current.setState({
                    add_btn_disabled : false
                })

                var temp_comment_totcnt  =   retJson["retBody"]["totCnt"];    //댓글 총 개수
                var temp_comment_list = retJson["retBody"]["list"];           //댓글 정보 리스트

                // 성공하면 갱신된 댓글 리스트를 받고 갱신 시켜준다.
                current.setState({
                    // 비밀번호 초기화
                    write_reply_pw : "",
                    // 다이얼로그 관리
                    comment_del_visible : false,
                    comment_del_text : "",
                    comment_map : {},
                    // 댓글 갱신 정보
                    comment_tot_cnt : temp_comment_totcnt,
                    comment_list : temp_comment_list,
                    add_btn_disabled : false
                })
            }else if(retJson["retCode"] === 404){
                // 패스워드 오류
                current.setState({
                    comment_del_text : "패스워드가 일치하지 않습니다",
                    add_btn_disabled : false
                });
            }
        }).catch(function(err){
            current.setState({
                add_btn_disabled : false
            })
            console.log(err);
        });

    }
    on_comment_modify = () => {
        var current = this;
        current.setState({
            add_btn_disabled : true
        })

        var data_map = this.state.comment_map;

        let send_data = Api.BOARD_COMMENT_MANAGE(
            this.state.type,
            this.state.board_idx,
            this.state.write_reply_pw,
            this.state.comment_modi_write,
            data_map["ori_no"],
            data_map["depth"],
            data_map["type"]
        )

        Api.axios_instance.post(`${process.env.REACT_APP_BOARD_URL}`,send_data) 
        .then(function(res){
            //리턴값 체크
            var retJson = res.data;
            current.setState({
                add_btn_disabled : false
            })
            //console.log(retJson);            
            //성공 코드
            if(retJson["retCode"] === 0){
                var temp_comment_totcnt  =   retJson["retBody"]["totCnt"];    //댓글 총 개수
                var temp_comment_list = retJson["retBody"]["list"];           //댓글 정보 리스트

                // 성공하면 갱신된 댓글 리스트를 받고 갱신 시켜준다.
                current.setState({
                    // 비밀번호 초기화
                    write_reply_pw : "",
                    // 다이얼로그 관리
                    comment_modi_visible : false,
                    comment_modi_text : "",
                    comment_map : {},
                    // 댓글 갱신 정보
                    comment_tot_cnt : temp_comment_totcnt,
                    comment_list : temp_comment_list,
                    add_btn_disabled : false
                })
            }else if(retJson["retCode"] === 404){
                // 패스워드 오류
                current.setState({
                    comment_modi_text : "패스워드가 일치하지 않습니다",
                    add_btn_disabled : false
                });
            }
        }).catch(function(err){
            current.setState({
                add_btn_disabled : false
            })
            console.log(err);
        });
   
    }
    // 권한에 맞게 아이콘 뿌려주기
    comment_icons_return_ui = (data) => {
        let ui = [];

        if(this.props.grade === "10"){
            ui.push(
                <img 
                    key = {"m_"+data["ori"]+"_"+data["depth"]}
                    alt = {"m_"+data["ori"]+"_"+data["depth"]} 
                    id="read_comment_icons" 
                    src={process.env.REACT_APP_BASE_URL+"/_nova/_img/_icon/modify.png"}
                    onClick ={(e)=>this.comment_btn_click(e)}
                    ></img>
            )
            ui.push(
                <img 
                key = {"d_"+data["ori"]+"_"+data["depth"]} 
                alt = {"d_"+data["ori"]+"_"+data["depth"]}
                id="read_comment_icons"  
                src={process.env.REACT_APP_BASE_URL+"/_nova/_img/_icon/delete.png"}
                onClick ={(e)=>this.comment_btn_click(e)}
                ></img>
            )
            ui.push(
                <img 
                key = {"b_"+data["ori"]+"_"+data["depth"]} 
                alt = {"b_"+data["ori"]+"_"+data["depth"]}
                id="read_comment_icons" 
                src={process.env.REACT_APP_BASE_URL+"/_nova/_img/_icon/ban.png"}
                onClick ={(e)=>this.comment_btn_click(e)}
                ></img>
            )
        }else{
            ui.push( 
                <img 
                key = {"m_"+data["ori"]+"_"+data["depth"]} 
                alt = {"m_"+data["ori"]+"_"+data["depth"]}
                id="read_comment_icons" 
                src={process.env.REACT_APP_BASE_URL+"/_nova/_img/_icon/modify.png"}
                onClick ={(e)=>this.comment_btn_click(e)}
                ></img>
            )
            ui.push(
                <img 
                key = {"d_"+data["ori"]+"_"+data["depth"]} 
                alt = {"d_"+data["ori"]+"_"+data["depth"]}
                id="read_comment_icons" 
                src={process.env.REACT_APP_BASE_URL+"/_nova/_img/_icon/delete.png"}
                onClick ={(e)=>this.comment_btn_click(e)}
                ></img>
            )
        }

        return ui;
    }
    // 댓글 리스트 뿌려주기 -> ori_num(original_number 을 기준으로 뿌려주고 depth를 기준으로 대댓글을 판단)
    comment_list_view=()=>{
        // didmount 에서 통신한 정보를 가지고 뿌려줍니다.
        // var tot_cnt = this.state.comment_tot_cnt;       //총 댓글 개수
        var list = this.state.comment_list;             //댓글 리스트 정보
        if(!list) return;
        //기본 틀 형성
        let comment_ui_return = [];
        
        // 댓글이 0개가 아니라면 파싱 시작
        for(var i = 0; i < list.length; i++){
           var map_list = list[i];          //인덱스로 맵 정보를 가지고 있는 리스트 파싱
           var ori_no = map_list["ori_no"];     //최상위 , 댓글인덱스
           var depth  = map_list["depth"];       //대댓글 인덱스 번호
           var ip     = map_list["ip"];          //작성자 ip 정보
           var reg_dt = map_list["reg_dt"];     //댓글 작성일자
           var visible = map_list["visible"];   //블라인드 처리된 글자인가?
           var comment = map_list["comment"];   //내용
           var state   = map_list["state"] ;    //상태값
            //console.log(ori_no +"/"+depth);
            // ip값을 숨기는 작업
            // console.log(ip);
            // console.log(ip.split("."));
            var ip_split = ip.split(".");
            ip = ip_split[0]+"."+ip_split[1]+"."+ip_split[2]+".***";
            // 코멘트 줄바꿈 변경
            var change_comment=[];
            var split_comment = comment.split("\n");
            for(var c = 0 ; c < split_comment.length; c++){
                change_comment.push(split_comment[c]) ;
                change_comment.push(<br key ={ori_no+"_"+depth+"_"+c}/>);
            }
           //먼저 이 댓글이 대댓글인가를 판단 , 댓글은 0 대댓글은  0이상
            if(depth === "0"){
                //블라인드 처리 된 글자인지 판단.
                if(visible ==="Y" && state ==="Y"){
                    // 일반 댓글
                    comment_ui_return.push(
                        <li className="read_comment_ori" key={i}>
                            <div>	
                            <i id="read_comment_ori_ip_icon" className="pi pi-user"></i>
                            {/* 유저 아이피 정보 */}
                            <span id="read_comment_ori_ip">{ip}</span>
                            <i id="read_comment_ori_date_icon" className="pi pi-clock"></i>
                            {/* 유저 입력 date정보 */}
                            <span id="read_comment_ori_date">{reg_dt}</span>
                            {/* 기능 아이콘 */}
                            {this.comment_icons_return_ui({"ori": ori_no , "depth" : depth})}
                            </div>
                            {/* 텍스트 내용 */}
                            <div className="read_comment_text">
                                <p>{change_comment}</p>
                            </div>
                            <div className="read_comment_reply">
                                <p id ={ori_no+"_"+depth} onClick={(e) => this.reply_show_click(e.target.id)}>{this.state.lang_data.get("comment").get("comment_reply")}</p>
                                {this.reply_ui(ori_no+"_"+depth)}
                            </div>
                        </li>
                    )
                }else{
                    // 블라인드 처리된 댓글
                    comment_ui_return.push(
                        <li className="read_comment_ori" key = {i}>
                            <div>	
                            <i id="read_comment_ori_ip_icon" className="pi pi-user"></i>
                            {/* 유저 아이피 정보 */}
                            <span id="read_comment_ori_ip">{ip}</span>
                            <i id="read_comment_ori_date_icon" className="pi pi-clock"></i>
                            {/* 유저 입력 date정보 */}
                            <span id="read_comment_ori_date">{reg_dt}</span>
                            {/* 기능 아이콘 */}
                            {/* <img id="read_comment_icons" alt="modify_icon" src={process.env.REACT_APP_BASE_URL+"/_nova/_img/_icon/modify.png"}></img>
                            <img id="read_comment_icons" alt="delete_icon" src={process.env.REACT_APP_BASE_URL+"/_nova/_img/_icon/delete.png"}></img>
                            <img id="read_comment_icons" alt="ban_icon" src={process.env.REACT_APP_BASE_URL+"/_nova/_img/_icon/ban.png"}></img> */}
                            </div>
                            {/* 텍스트 내용 */}
                            <div className="read_comment_text">
                                <p  style={{fontSize : "14px", fontWeight: "bold",color:"gray"}}>{this.state.lang_data.get("comment").get("comment_delete_reply")}</p>
                            </div>
                            <div className="read_comment_reply">
                                <p id ={ori_no+"_"+depth} onClick={(e) => this.reply_show_click(e.target.id)}>{this.state.lang_data.get("comment").get("comment_reply")}</p>
                                {this.reply_ui(ori_no+"_"+depth)}
                            </div>
                        </li>
                    )
                }
                
           }else{//대댓글이라고 판단.
                if(visible ==="Y" && state === "Y"){
                    comment_ui_return.push(
                        <li className="read_comment_ori"  key ={i}>
                            {/* 대댓글 내용 */}
                            <div className="read_comment_reply_list">
                                <ul>
                                    <li>
                                    <div>
                                        <i id="read_comment_ori_reply_icon" className="pi pi-arrow-right"></i>	
                                        <i id="read_comment_ori_ip_icon" className="pi pi-user"></i>
                                        {/* 대댓글 아이피 정보 */}
                                        <span id="read_comment_ori_ip">{ip}</span>
                                        <i id="read_comment_ori_date_icon" className="pi pi-clock"></i>
                                        {/* 대댓글 입력 날짜 정보 */}
                                        <span id="read_comment_ori_date">{reg_dt}</span>
                                        {/* 대댓글 수정 아이콘 */}
                                        {this.comment_icons_return_ui({"ori": ori_no , "depth" : depth})}
                                    </div>
                                    {/* 대댓글 내용 */}
                                    <div className="read_comment_reply_text">
                                        <p>{change_comment}</p>
                                    </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    )
                }else{
                    comment_ui_return.push(
                        <li className="read_comment_ori"  key ={i}>
                    
                            {/* 대댓글 내용 */}
                            <div className="read_comment_reply_list">
                                <ul>
                                    <li>
                                    <div>
                                        <i id="read_comment_ori_reply_icon" className="pi pi-arrow-right"></i>	
                                        <i id="read_comment_ori_ip_icon" className="pi pi-user"></i>
                                        {/* 대댓글 아이피 정보 */}
                                        <span id="read_comment_ori_ip">{ip}</span>
                                        <i id="read_comment_ori_date_icon" className="pi pi-clock"></i>
                                        {/* 대댓글 입력 날짜 정보 */}
                                        <span id="read_comment_ori_date">{reg_dt}</span>
                                    </div>
                                    {/* 대댓글 내용 */}
                                    <div className="read_comment_reply_text">
                                        <p  style={{fontSize : "14px", fontWeight: "bold",color:"gray"}}>{this.state.lang_data.get("comment").get("comment_delete_reply")}</p>
                                    </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    )
                }
           }


        }
        return comment_ui_return;
    }
    //답글 ui 컨트롤
    reply_show_click=(id) => {
        // console.log(id);
        if(this.state.reply_show_id === id){
            this.setState({
                // 화면 담당 변수 변경
                reply_show_id : "",
                // 대댓글 작성 정보 초기화
                write_reply_pw: "",
                write_reply_text : "",
            })
        }else{
            this.setState({
                reply_show_id : id,
                // 대댓글 작성 정보 초기화
                write_reply_pw: "",
                write_reply_text : "",
            })
        }
        
    }
    //답글 달기 ui 
    reply_ui = (id) => {
        if(id === this.state.reply_show_id){
            return (
                <ul>
                    <li id="read_comment_reply_li">
                        <InputTextarea placeholder ={this.state.lang_data.get("comment").get("comment_add_reply")} rows={1} cols={30} autoResize={true}
                        value={this.state.write_reply_text} onChange={(e) => this.setState({write_reply_text: e.target.value})} />
                    </li>
                    <li id="read_comment_reply_passwd">
                        <Password feedback={false} placeholder={this.state.lang_data.get("comment").get("comment_add_reply_pw")}
                        value={this.state.write_reply_pw} onChange={(e) => this.setState({write_reply_pw: e.target.value})}/>
                    </li>
                    <li id="read_comment_reply_btn">
                        <Button label={this.state.lang_data.get("comment").get("comment_add_reply_add")} className="p-button-raised p-button-success" onClick={this.add_reply} disabled ={this.state.add_btn_disabled}/>
                    </li>
                </ul>
            )
        }else{
            return null;
        }
    }
    //대댓글 달기 버튼 클릭 ---> 통신 진행
    add_reply = () => {
        var current = this;

        // 내용 입력 체크
        if(!this.state.write_reply_text){
            this.setState({
                comment_err_text : this.state.lang_data.get("comment").get("comment_add_err_no_text"),
                comment_err_visible : true
            })
            return;
        }
        //비밀번호 입력했니?
        if(!this.state.write_reply_pw){
            this.setState({
                comment_err_text : this.state.lang_data.get("comment").get("comment_add_err_no_pw"),
                comment_err_visible : true
            })
            return;
        }

        current.setState({
            add_btn_disabled : true
        })

        //ori_no 파악하기
        var ori_no = this.state.reply_show_id.split("_")[0];
        //depth 파악하기
        var depth;

        for(var i = 0 ; i < this.state.comment_list.length ; i ++){
            var map_list = this.state.comment_list[i];

            var temp_ori_no = map_list["ori_no"];     //최상위 , 댓글인덱스
            var temp_depth  = map_list["depth"];       //대댓글 인덱스 번호

            if(ori_no === temp_ori_no){
                // 최상위 댓글 번호가 같을 때 , 뎊스를 입력
                depth = temp_depth;
            }
        }

        // 통신 진행
        let data = Api.BOARD_COMMENT_ADD(
            this.state.type,
            this.state.board_idx,
            this.state.write_reply_pw,
            this.state.write_reply_text,
            ori_no,
            (depth*1)+1
        )
        //통신 진행
        Api.axios_instance.post(`${process.env.REACT_APP_BOARD_URL}`,data)
        .then(function(res){
            //리턴값 체크
            var retJson = res.data;
            // console.log(retJson);            
            current.setState({
                add_btn_disabled : false
            })
            //성공 코드
            if(retJson["retCode"] === 0){
                //마지막 데이터 추가!
                // 댓글 정보 가져오기
                var temp_comment_totcnt  =   retJson["retBody"]["totCnt"];    //댓글 총 개수
                var temp_comment_list = retJson["retBody"]["list"];           //댓글 정보 리스트
                // 댓글 추가 및 갱신
                current.setState({
                    // 댓글 정보
                    comment_tot_cnt : temp_comment_totcnt,
                    comment_list : temp_comment_list,

                    //대댓글 작성 정보
                    write_reply_text : "",
                    write_reply_pw : "",

                    // 대댓글 입력 창 닫기
                    reply_show_id : "",
                    add_btn_disabled : false
                })
            }else{
                current.setState({
                    comment_err_text : this.state.lang_data.get("comment").get("comment_add_err"),
                    comment_err_visible : true,
                    add_btn_disabled : false
                })
                return;
            }
        }).catch(function(err){
            current.setState({
                add_btn_disabled : false
            })
            console.log(err);
        });
        
    }
    //댓글 달기 버튼 클릭
    add_comment = () => {
        var current = this;
        //내용 입력 했니?
        if(!this.state.write_comment_text){
            this.setState({
                comment_err_text : this.state.lang_data.get("comment").get("comment_add_err_no_text"),
                comment_err_visible : true
            })
            return;
        }
        //비밀번호 입력했니?
        if(!this.state.write_comment_pw){
            this.setState({
                comment_err_text : this.state.lang_data.get("comment").get("comment_add_err_no_pw"),
                comment_err_visible : true
            })
            return;
        }
        current.setState({
            add_btn_disabled : true
        })

        
        //댓글을 다는 통신 진행
        //통신이 완료 되면 댓글 추가하기      
        let data = Api.BOARD_COMMENT_ADD(
            this.state.type,
            this.state.board_idx,
            this.state.write_comment_pw,
            this.state.write_comment_text,
            this.state.comment_ori_last,
            0
        ) 
        // console.log(this.state.comment_ori_last);
        Api.axios_instance.post(`${process.env.REACT_APP_BOARD_URL}`,data)
        .then(function(res){
            //리턴값 체크
            var retJson = res.data;

            current.setState({
                add_btn_disabled : false
            })
            // console.log("-----------------");            
            // console.log(retJson);            
            //성공 코드
            if(retJson["retCode"] === 0){
                //마지막 데이터 추가!
                // 댓글 정보 가져오기
                var temp_comment_totcnt  =   retJson["retBody"]["totCnt"];    //댓글 총 개수
                var temp_comment_list = retJson["retBody"]["list"];           //댓글 정보 리스트
                // 댓글 추가 및 갱신
                current.setState({
                    // 댓글 정보
                    comment_tot_cnt : temp_comment_totcnt,
                    comment_list : temp_comment_list,

                    //댓글 작성 정보
                    write_comment_text : "",
                    write_comment_pw : "",

                    comment_ori_last : parseInt(temp_comment_totcnt)+1,
                    add_btn_disabled : false
                })
                
                window.scrollTo(0,document.body.scrollHeight);
            }else{
                current.setState({
                    comment_err_text : this.state.lang_data.get("comment").get("comment_add_err"),
                    comment_err_visible : true,
                    add_btn_disabled : false
                })
                return;
            }
        }).catch(function(err){
            current.setState({
                add_btn_disabled : false
            })
            console.log(err);
        });

        
    }
    
    // 댓글 에러 숨기기
    onHide = () =>{
        this.setState({
            comment_err_visible : false
        })
    }
    // 댓글 삭제 다이얼로그 숨기기
    onHide_del = () =>{
        this.setState({
            comment_del_visible : false,
            write_reply_pw : "",
        })
    }
    // 댓글 수정 다이얼로그 숨기기
    onHide_modi = () =>{
        this.setState({
            comment_modi_visible : false,
            write_reply_pw : "",
        })
    }
    // 페이지 스크롤링 퍼센테이지 구하기
    page_percent = () =>{
        if(ie_check()) return;
        
        return(
            <ProgressBar value={this.state.now_scroll_percent} showValue={false}/>
        )
        
    }
    // 뒤로가기 버튼 클릭 메서드
    backbtn_click = () =>{
        this.props.history.goBack();
    }
    // 로딩페이지 로드
    loading_padge_load =() => {
        if(this.state.is_loading_ok){
            return null;
        }else{
            return(
                <div id="pageread_loading">
                        <img src={loading_gif} alt={"teamnovatech loading"} title={"teamnovatech loading"}/>
                    </div>
            )
        }
    }

    // url 카피 기능 
    url_copy = () => {
        var now_url = process.env.REACT_APP_BASE_URL+this.props.match.url;
        console.log(now_url);
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = now_url;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert(now_url+this.state.lang_data.get("content").get("copy_url_ok")); 
    }
    //메인 렌더링
    render(){
        let des = "팀노바 테크 ";
        let tag_split = this.state.tags.split(",");

        for(var i = 0; i < tag_split.length; i++){
            des += tag_split[i]+" ";
        }
        // console.log(des);
        
        

        return (
            <div id="back_div">
                <Helmet>
                    <title>팀노바테크 | {this.state.title}</title>
                    <meta
                        name="description"
                        content={this.state.sub_title}
                    />
                    <meta name="keywords" content={"팀노바테크,"+this.state.tags} />
                    {/* 페이지별 카카오톡 설명 추가 */}
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={"팀노바테크 - "+this.state.name}/> 
                    <meta property="og:description" content={this.state.title} />
                    <meta property="og:image" content={process.env.REACT_APP_BASE_URL+this.state.thumnail_img} />
                    {/* 표시 주소 입니다. */}
                    <meta property="og:url" content="http://www.teamnovatech.co.kr" /> 
                </Helmet>
                    {this.loading_padge_load()}

                <div className="pageRead_read_percent">
                    {this.state.scroll_visible ? this.page_percent() : null}
                </div>
                
                <div id="con_div">

                    <div className = "pageread_back_div" onClick={() => this.backbtn_click()}>
                        <i className="pi pi-arrow-left" id="pageread_back_icon"> BACK</i>
                    </div>
                    <div className="pageRead_main_div">
                        <div className="top_content">
                                <span id="top_info"><i className="pi pi-clock" />{this.state.reg_dt}</span>
                                <span id="top_info"><i className="pi pi-eye" />{util.number_with_comma(this.state.view_count)}</span>
                                <span id="top_url_info"><Button label={this.state.lang_data.get("content").get("copy_url")} className="p-button-rounded p-button-secondary" onClick={()=>this.url_copy()}/></span>
                                {/* 수정 삭제 */}
                                {this.modify_board_ui(this.state.profile_idx)}
                        </div>
                        <div className = "pageread_top_div">
                            <div className = "top_thumnail_img">
                                <img alt = {des} title ={des} src={process.env.REACT_APP_BASE_URL+this.state.thumnail_img}/>
                            </div>
                            
                            <div className = "top_thumnail_title">
                                <h1>{this.state.title}</h1> 
                            </div>
                        </div>
                        {/* 에러메세지 */}
                        <Messages ref={(el) => this.messages = el}></Messages>
                        {/* 상단 제목 날짜 뷰카운트 */}
                        <div className = "pageread_pagepadding">
                            <div className="tag_content">       
                                <div><i className="pi pi-tag">Tags</i></div>
                                {this.get_tag_list(this.state.tags)}
                            </div>

                            {/* 본문 */}
                            <br />
                            <div className = "mid_content">
                                <Editor
                                readOnly
                                toolbarHidden 
                                editorState={this.state.content_state} 
                                />
                            </div>
                            <br />

                            <div className="profile">
                                <ul className="profile_ul">
                                    <li><img id="profile_img" src={process.env.REACT_APP_BASE_URL+this.state.profile_img} alt="profile_img"/></li>
                                    <li><p>{this.state.name}</p></li>
                                </ul>
                                <div>
                                    <div id="profile_name">
                                    {
                                        this.state.profile_explain.map(i => {
                                        return (
                                            <div key={i}>{i}</div>
                                        )
                                    })}
                                    </div>
                                </div>                        
                            </div>

                            {/* 익명 댓글 */}
                            <div className = "read_comment">
                                {/* 댓글 총 개수 판단 */}
                                <h3>{this.state.comment_ori_last-1}{this.state.lang_data.get("comment").get("comment_title")}</h3>
                                <hr />
                                {/* 댓글 작성 ui */}
                                <ul className="read_comment_write_box">
                                    <li id="read_comment_text_li">
                                        <InputTextarea placeholder ={this.state.lang_data.get("comment").get("comment_main_PH")} rows={1} cols={30} autoResize={true} 
                                        value={this.state.write_comment_text} onChange={(e) => this.setState({write_comment_text: e.target.value})}/>
                                    </li>
                                    <li id="read_comment_passwd">
                                        <Password feedback={false} placeholder={this.state.lang_data.get("comment").get("comment_main_pw")} 
                                        value={this.state.write_comment_pw} onChange={(e) => this.setState({write_comment_pw: e.target.value})}/>
                                    </li>
                                    <li id="read_comment_btn">
                                        <Button label={this.state.lang_data.get("comment").get("comment_main_add")} className="p-button-raised p-button-success" onClick={this.add_comment} disabled ={this.state.add_btn_disabled}/>
                                    </li>
                                </ul>
                                {/* 댓글 작성 ui에 따른 오류 dialog */}
                                <Dialog header={this.state.lang_data.get("comment").get("comment_err_dialog_comment")} visible={this.state.comment_err_visible} style={{width: '300px'}} onHide={this.onHide} dismissableMask={true}>
                                    {this.state.comment_err_text}
                                </Dialog>
                                {/* 댓글 관리에 따른 dialog */}
                                {/* 댓글 삭제 */}
                                <Dialog header={this.state.lang_data.get("comment").get("comment_err_dialog_remove")} visible={this.state.comment_del_visible} style={{width: '300px'}} onHide={this.onHide_del} dismissableMask={true}>
                                    {this.state.comment_del_text}
                                    <br/>
                                    <br/>
                                    <Password feedback={false} placeholder={this.state.lang_data.get("comment").get("comment_err_dialog_pw")}
                                    value={this.state.write_reply_pw} onChange={(e) => this.setState({write_reply_pw: e.target.value})}/>
                                    <br/>
                                    <br/>
                                    <Button label ={this.state.lang_data.get("comment").get("comment_err_dialog_remove_cancle")} style={{width:"45%",margin : "0 2% 2% 0"}} onClick={this.onHide_del} disabled ={this.state.add_btn_disabled}/>
                                    <Button label ={this.state.lang_data.get("comment").get("comment_err_dialog_remove_delete")} style={{width:"45%",margin : "0 2% 2% 0"}} onClick={()=>this.on_comment_del()} disabled ={this.state.add_btn_disabled}/>
                                </Dialog>
                                {/* 댓글 수정 */}
                                <Dialog header={this.state.lang_data.get("comment").get("comment_err_dialog_modify")} visible={this.state.comment_modi_visible} style={{width: '300px'}} onHide={this.onHide_modi} dismissableMask={true}>
                                    {this.state.comment_modi_text}
                                    <br />
                                    <br />
                                    <InputTextarea placeholder ={this.state.lang_data.get("comment").get("comment_err_dialog_modify_title")} rows={5} cols={30} autoResize={true} 
                                    value={this.state.comment_modi_write} onChange={(e) => this.setState({comment_modi_write: e.target.value})}
                                    />
                                    <br/>
                                    <br/>
                                    <Password feedback={false} placeholder={this.state.lang_data.get("comment").get("comment_err_dialog_modify_pw")}
                                    value={this.state.write_reply_pw} onChange={(e) => this.setState({write_reply_pw: e.target.value})}/>
                                    <br/>
                                    <br/>
                                    <Button label ={this.state.lang_data.get("comment").get("comment_err_dialog_modify_cancle")} style={{width:"48%",margin : "0 2% 2% 0"}} onClick={this.onHide_modi} disabled ={this.state.add_btn_disabled}/>
                                    <Button label ={this.state.lang_data.get("comment").get("comment_err_dialog_modify_modify")} style={{width:"48%",margin : "0 2% 2% 0"}} onClick={()=>this.on_comment_modify()} disabled ={this.state.add_btn_disabled}/>
                                </Dialog>
                                {/* 댓글 리스트 뿌려주기 */}
                                
                                {/* 익명 댓글 - 리스트 */}
                                <ul className="read_comment_list">
                                    {/* 통신한 정보를 기준으로 댓글을 뿌려줍니다. */}
                                    {(this.state.comment_tot_cnt === "0") ? null : this.comment_list_view()}
                                </ul>
                                
                                
                            </div>
                        </div>
                        </div>

                    </div>
                    
                    
                    


                <div id="read_bring_top_btn" style={{display:this.state.bring_top_btn}} onClick={this.move_top}>
                    <img alt="bring_top_icon" src={process.env.REACT_APP_BASE_URL+"/_nova/_img/_icon/bring_top.png"} />
                </div>
            </div>
        )
    }
}


let mapStateToProps = ( state ) => {
	return {
        is_login : state.user.is_login,
        idx : state.user.idx,
        lang : state.user.lang,
        grade : state.user.grade,   
    }
}



export default withRouter(connect(mapStateToProps)(page_read));
// 클라이언트에게 보여줄 기본 root 컨테이너 입니다.
//주 목적은 로그인 유지를 하기 위해서 사용합니다.

import React from 'react';
import { connect } from 'react-redux';

import {BrowserRouter } from 'react-router-dom';

//필요한 컴포넌트
import TopNavi from '../components/TopNavi';     //네비게이션
import Content from '../components/Content';    //페이지
import Footer from '../components/Footer';    //푸터
//액션 연결
import * as userAction from '../store/modules/User';
//통신용
import * as API from '../utils/Api';
//개별통신용
import axios from 'axios';

export class BaseContainer extends React.Component{
    //로드된 다음 로그인 유지 체크
    componentDidMount(){
        //로그인 체크
        this.chk_login();
    }
    

    chk_login = () => {
        let currnet = this;
        //새로고침을 하게되면 기존에 있던 store 데이터가 초기화되는데 user 정보를 유지해주기 위한 방책!
        //저장해둔 정보가 있다면!
        if(window.sessionStorage.getItem("user_info")){
            //데이터 파싱진행
            let user_info = JSON.parse(window.sessionStorage.getItem("user_info"));
            let user_idx = user_info.idx;
            
            //auto login 진행 통신을 한 다음에 
            // 서버에서 지정한 방식으로 조합된 암호와된 세션 키와 비교
            //세션이 아직 살아있다면 세션 갱신 후 로그인 정보 리턴
            //세션이 죽어있다면 로그인 해제 

            //통신용 데이터 형식 만들기
            let data = API.AUTO_LOGIN(user_idx);
            
            API.axios_instance.post(`${process.env.REACT_APP_MEMBER_URL}`,data)
            .then(function(res){
                var retJson = res.data;
                //console.log(retJson);

                //올바른 리턴이 들어오면 로그인을 유지시켜주고 아니라면 로그인을 해제 시켜 줍니다.
                if(retJson["retCode"] === 0){
                    let retBody = retJson["retBody"];
                    //올바른 리턴
                    
                    //로그인데이터 입력
                    currnet.props.user_login_data_input(
                        retBody["idx"],
                        retBody["e_address1"]+"@"+retBody["e_address2"],
                        retBody["name"],
                        null,
                        process.env.REACT_APP_BASE_URL+retBody["profile_img"],
                        retBody["default_explain"],
                        retBody["grade"]
                    )
                }else{
                    //올바르지 않은 리턴은 로그아웃 시킨 다음 localstorage를 삭제해줍니다.
                    currnet.props.user_logout();
                    window.sessionStorage.removeItem("user_info");
                }
            }).catch(function(error){
                //에러
                console.log(error);
            })
        }
    }

    // 접속자의 정보를 알아냅니다.
    get_user_info = () => {
        // console.log("들온다"); 
        let current = this;
        // 해당 사이트는 접속하게되면 아이피를 기반으로 유저의 정보를 알려주게됩니다        
        axios.get('https://ipinfo.io')
            .then(function (res) {
                // handle success
                // console.log(res);
                let city = res.data.city;               //접속자 도시
                let region = res.data.region;           //접속자 지역
                let country = res.data.country;         //접속자 국가
                let ip = res.data.ip;                   //접속자 ip
                let location = res.data.loc;            //접속자 위경도
                let org = res.data.org;                 //접속자 ISP (인터넷 서비스 제공 사업자)
                
                //언어 설정
                //일단은 한국이 아니면 모두 영어로 대체!
                //구글 seo 다국어 콘텐츠 통합을 위해서 일단 사용 보류
                if(country === "KR"){
                    current.props.user_lang_ko();
                }else{
                    current.props.user_lang_en();
                }
                //통신 진행 --> db에 로그를 쌓습니다.
                let data = API.ACCESS_LOG(city,region,country,ip,location,org);
                API.axios_instance.post(`${process.env.REACT_APP_LOG}`,data)
                    .then(function(res){
                        //var retJson = res.data;
                        // console.log(retJson);
                        //아직 리턴값으로 뭘 하지는 않습니다.
                    });
 
            });
    }


    render(){
        // 유저의 정보를 체크
        this.get_user_info();
        return(
                <BrowserRouter >
                    {/* 네비게이션 */}
                    <TopNavi />
                    {/* 중앙 페이지 영역 */}
                    <Content />
                    {/* 하단 푸터 영역 */}
                    <Footer />
                </BrowserRouter>
        )
    }

}

//함수연결
const map_dispatch_top_props = (dispatch) => ({
    user_login_data_input: (idx,email,name,sessionId,profile_img,default_explain,grade) => dispatch(userAction.user_login_data_input(idx,email,name,sessionId,profile_img,default_explain,grade)),
    user_logout:() => dispatch(userAction.user_logout()),
    user_lang_ko:() => dispatch(userAction.user_lang_ko()),
    user_lang_en:() => dispatch(userAction.user_lang_en())
});

export default connect(null,map_dispatch_top_props)(BaseContainer);
// 하단에 항상 붙어있는 푸터입니다.
import React from 'react';
import './Footer.css';
import PropTypes from 'prop-types';
//store 연결
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
//언어팩
import * as lang_pack from '../utils/language_pack';

class Footer extends React.Component{    
    constructor(props){
        super(props);
        this.state = {
            lang_data : {}
        }
    }
    static propType = {
        is_user_write_start : PropTypes.bool,
    }

    componentWillMount(){
        //언어팩 설정
        let lang_data;
        switch(this.props.lang){
            case lang_pack.KOREAN:
                lang_data = lang_pack.lang_footer(lang_pack.KOREAN);
                break;
            case lang_pack.ENGLISH:
                lang_data = lang_pack.lang_footer(lang_pack.ENGLISH);
                break;
            default : break;
        }

        this.setState({
            lang_data : lang_data
        })
    }

    componentWillReceiveProps(next_props) {
        //console.log(next_props);
        //언어 관리
        let lang_data;
        switch(next_props.lang){
            case lang_pack.KOREAN:
                lang_data = lang_pack.lang_footer(lang_pack.KOREAN);
                break;
            case lang_pack.ENGLISH:
                lang_data = lang_pack.lang_footer(lang_pack.ENGLISH);
                break;
                default : break;
        }

        this.setState({
            lang_data : lang_data
        })
    }
    // 현제 전채화면이 필요한 상황에서는 푸터를 보여주지 않습니다.
    // 1. 유저가 글 작성을 했을 때 
    footer_view_select(){
        if(this.props.is_user_write_start){
            return null;
        }else{
            //언어팩 
            let lang = this.state.lang_data;
            return(
                <div>
                <div className='list'>
                    <div id='our_name'>
                        <h1>{lang.get("title")}</h1>
                    </div>

                    <div className='foot_block'>
                        <ul>
                            <li className='our_site_list_title'>{lang.get("service_title")}</li>
                            <li><Link to ="/serviceterms" >{lang.get("service_term")}</Link></li>
                            <li><Link to ="/serviceterms" >{lang.get("service_personal")}</Link></li>
                        </ul> 
                        <ul>
                            <li className='our_site_list_title'>{lang.get("site_title")}</li>
                            <li><a href='http://teamnova.co.kr/index2.php' rel="noopener noreferrer" target='_blank'>{lang.get("site_official")}</a></li>
                            <li><a href='https://www.teamnovalight.co.kr/index.php' rel="noopener noreferrer" target='_blank'>{lang.get("site_light")}</a></li>
                            <li><a href='https://www.youtube.com/channel/UCQIEU_pBV2wkn-gHg7RPvdQ' rel="noopener noreferrer" target='_blank'>{lang.get("site_youtube")}</a></li> 
                            <li><a href='https://www.facebook.com/codingfinal/?ref=br_rs' rel="noopener noreferrer" target='_blank'>{lang.get("site_facebook")}</a></li>
                            <li><a href='https://blog.naver.com/manadra' rel="noopener noreferrer" target='_blank'>{lang.get("site_blog")}</a></li>
                            <li><a href='https://cafe.naver.com/teamnovaopen' rel="noopener noreferrer" target='_blank'>{lang.get("site_cafe")}</a> </li>
                            <li><a href='https://teamnova0410-dev.tistory.com/' rel="noopener noreferrer" target='_blank'>{lang.get("site_tstory")}</a></li>
                        </ul>
                        <ul>
                            <li className='our_site_list_title'>{lang.get("company_title")}</li>
                            <li>{lang.get("company_name")}</li>
                            <li>{lang.get("company_mail")}</li>
                            <li>{lang.get("company_location")}</li>
                            <li>{lang.get("company_phone")}</li>
                            <li>{lang.get("company_copy")}</li>
                        </ul>
                    </div>
                </div>
                <div className='foot_inc'>
                    <div id='foot_text'></div>
                </div>
            </div>
            )
        }
    }

    render(){
        return(
            <div>
                {this.footer_view_select()}
            </div>
        )
    }
}

const map_state_to_prop = (state) => ({
    is_user_write_start : state.user.is_user_write_start,
    lang : state.user.lang
});


export default connect(map_state_to_prop)(Footer);
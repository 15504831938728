// 언어 번역을 가지고 있는 언어 팩입니다.
// props.lang 을 기준으로 언어를 반환합니다.

//번역 언어
export const KOREAN = "KR";            //한글
export const ENGLISH ="EN";            //영어



//상단 메뉴
export function lang_menu(type){
    let lang_pack = new Map();
    let menu = new Map();
    let search = new Map();
    let my_menu = new Map();
    //Additional Information
    let a_info = new Map();
    switch(type){
        case KOREAN:
            //메뉴
            menu
                .set("home","홈")
                .set("tech","연구")
                .set("contect","문의하기")
                .set("admin","관리자")
                .set("trend","트렌드")
            //검색창
            search
                .set("search","태그로 검색");

            //개인 메뉴        
            my_menu
                .set("write","글쓰기")
                .set("my","내정보")
                .set("signin","로그인")
                .set("signout","로그아웃")
                .set("signup","회원가입") 
            //추가 정보
            a_info
                .set("summary","추가 정보 입력")
                .set("detail","추가 정보를 입력해주세요");
            break;

        case ENGLISH:
                //메뉴
                menu
                    .set("home","HOME")
                    .set("tech","TECH")
                    .set("contect","CONTACT")
                    .set("admin","ADMIN")
                    .set("trend","TREND")
                //검색창
                search
                    .set("search","Search Tags");
    
                //개인 메뉴
                my_menu
                    .set("write","Write")
                    .set("my","My")
                    .set("signin","Sign in")
                    .set("signout","Sign out")
                    .set("signup","Sign up")
                //추가 정보
                a_info
                    .set("summary","Enter additional information")
                    .set("detail","Please provide additional information");
            break;
        default : break;
    }

    //하나로 묶기
    lang_pack.set("menu",menu);
    lang_pack.set("search",search);
    lang_pack.set("my_menu",my_menu);
    lang_pack.set("a_info",a_info);

    return lang_pack;
}

// 회원가입 및 로그인 메뉴
export function lang_sign(type){
    //큰틀
    let lang_pack = new Map();
    //작은틀
    let signup = new Map(); //회원가입
    let signin = new Map(); //로그인
    //하나로 묶기
    switch(type){
        case KOREAN:
            //회원가입
            signup
                //회원가입 양식
                .set("title", "팀노바와 함께하기")
                .set("sub_title","초대된 회원만 가입할 수 있습니다")
                .set("user_name","이름")
                .set("user_name_exp","팀노바 공식카페에 등록되어 있는 이름으로 사용해주세요")
                .set("user_email","이메일")
                .set("user_email_exp","실제 사용중인 이메일을 입력해주세요")
                .set("password","비밀번호")
                .set("password_exp","영문, 숫자, 특수문자를 포함한 비밀번호를 입력해수세요")
                .set("password_chk","비밀번호 확인")
                .set("password_chk_exp","비밀번호 확인")
                .set("invite_code","초대 코드")
                .set("invite_code_exp","발급받은 초대 코드를 입력해주세요")
                // 버튼
                .set("sign_btn","회원가입")
                //정보 동의
                .set("agree_title","이용약관, 개인정보 수집")
                .set("agree_sub_title","회원가입 버튼을 클릭하면 이용약관 및 개인정보 수집에 동의하며 읽고 이해하신 것으로 간주됩니다.")
                .set("agree_terms","이용약관 *")    //이용약관
                .set("agree_personal","개인정보 수집 *")
                //초대코드 설명
                .set("code_title","초대코드")
                .set("code_sub_title","초대코드는 팀노바 팀원만 발급 받을 수 있으며 아직 발급 받지 못한 팀원은 공식 홈페이지에 요청하세요")

                //회원가입 성공
                .set("suc_title","회원가입 성공")
                .set("suc_sub_title","로그인 후 이용해주세요")
                .set("suc_btn_home","  메인  ")
                .set("suc_btn_signin","로그인")

                //에러상황
                .set("err_empty_name","이름을 입력해주세요")
                .set("err_empty_email","이메일을 입력해주세요")
                .set("err_email_type","올바른 이메일 형식을 입력해주세요")
                .set("err_empty_password","비밀번호를 입력해주세요")
                .set("err_password_chk","비밀번호가 일치하지 않습니다")
                .set("err_empty_invite_code","초대코드를 입력해주세요")
                .set("err_wrong_password","사용할 수 없는 비밀번호 입니다.") 

                .set("err_500","인증받지 않은 초대코드 입니다.")
                .set("err_424","중복된 이메일 입니다.")
                .set("err_409","회원가입에 실패했습니다. 잠시후 다시시도해 주세요")
                .set("err_1","회원가입에 실패했습니다. 잠시후 다시시도해 주세요")
            //로그인
            signin
                .set("email_addr","이메일 주소")
                .set("password","비밀번호")
                .set("forgot","비밀번호 찾기")
                .set("signin_btn","로그인")
                .set("signup_exp","팀노바 팀원이세요?")
                .set("signup","회원가입")

                //에러상황
                .set("err_404","ID / PW 가 일치하지 않습니다.")
                .set("err_undefined","로그인에 실패 하였습니다. 잠시 후 시도 해주세요")
            break;
            
        case ENGLISH:
            //회원가입
            signup
                //회원가입 양식
                .set("title", "JOIN TEAMNOVA")
                .set("sub_title","Only invited members can join")
                .set("user_name","Username")
                .set("user_name_exp","Please use the name registered in TEAMNOVA official cafe")
                .set("user_email","Useremail")
                .set("user_email_exp","Please enter your email")
                .set("password","Password")
                .set("password_exp","Enter your password, including letters, numbers, and special characters")
                .set("password_chk","Password Check")
                .set("password_chk_exp","Please enter your password again")
                .set("invite_code","Invite Code")
                .set("invite_code_exp","Please enter your invitation code")
                // 버튼
                .set("sign_btn","Create account")
                //정보 동의
                .set("agree_title","Terms of Use, Privacy Policy")
                .set("agree_sub_title","If you click the sign up button, you agree to collect and understand the terms and conditions and personal information.")
                .set("agree_terms","Terms of service *")    //이용약관
                .set("agree_personal","Privacy Policy *")
                //초대코드 설명
                .set("code_title","Invitation code")
                .set("code_sub_title","The invite code can only be issued by TeamNova members. Request a team member who has not been issued yet on the official website")

                //회원가입 성공
                .set("suc_title","Successful signup")
                .set("suc_sub_title","please try again after signin")
                .set("suc_btn_home","  Home  ")
                .set("suc_btn_signin","Signin")

                //에러상황
                .set("empty_name","Input your name")
                .set("empty_email","Please enter your email address")
                .set("email_type","Please enter a valid email format")
                .set("empty_password","Please enter a password")
                .set("password_chk","Passwords do not match")
                .set("empty_invite_code","Please enter the invitation code")
                .set("err_wrong_password","Unavailable password")

                .set("err_500","Unauthorized invitation code")
                .set("err_424","Duplicate email")
                .set("err_409","Signup failed. Please try again in a few minutes")
                .set("err_1","Signup failed. Please try again in a few minutes")
            //로그인    
            signin
                .set("email_addr","Email address")
                .set("password","Password")
                .set("forgot","forgot password?")
                .set("signin_btn","Sign in")
                .set("signup_exp","New to Teamnova")
                .set("signup","Create Account")

                //에러상황
                .set("err_404","ID / PW does not match")
                .set("err_undefined","Signin failed. Please try after a while")
            break;
        default : break;
    }
    //하나로 묶기
    lang_pack.set("signup",signup);
    lang_pack.set("signin",signin);
    return lang_pack;
}
// ********************댓글 읽기*************************/
export function lang_pageread(type){
    let lang_pack = new Map();
    let content = new Map();
    let comment = new Map();
    switch(type){
        case KOREAN:
            content
                .set("copy_url","주소 복사")
                .set("copy_url_ok"," 복사 완료")
            comment
                .set("comment_title","개 댓글")
                .set("comment_main_PH","익명 댓글 작성")
                .set("comment_main_pw","비밀번호")
                .set("comment_main_add","등록")

                .set("comment_err_dialog_comment","댓글 오류")

                .set("comment_err_dialog_remove","댓글 삭제")
                .set("comment_err_dialog_remove_pw","비밀번호")
                .set("comment_err_dialog_remove_cancle","취소")
                .set("comment_err_dialog_remove_delete","삭제")

                .set("comment_err_dialog_modify","댓글 수정")
                .set("comment_err_dialog_modify_title","익명 댓글 작성")
                .set("comment_err_dialog_modify_pw","비밀번호")
                .set("comment_err_dialog_modify_cancle","취소")
                .set("comment_err_dialog_modify_modify","수정")

                .set("comment_reply","답글")
                .set("comment_delete_reply"," * 삭제된 댓글입니다 * ")

                .set("comment_add_reply","답글 달기")
                .set("comment_add_reply_pw","비밀번호")
                .set("comment_add_reply_add","등록")

                .set("comment_add_err_no_text","내용을 입력해주세요")
                .set("comment_add_err_no_pw","비밀번호를 입력해주세요")
                .set("comment_add_err","댓글 입력 실패")

            break;
        case ENGLISH:
            content
                .set("copy_url","Copy Url")
                .set("copy_url_ok"," Copy!")
            comment
                .set("comment_title"," Comments")
                .set("comment_main_PH","Create anonymous comments")
                .set("comment_main_pw","Password")
                .set("comment_main_add","Add")

                .set("comment_err_dialog_comment","Comment error")

                .set("comment_err_dialog_remove","Comment delete")
                .set("comment_err_dialog_remove_pw","Password")
                .set("comment_err_dialog_remove_cancle","Cancle")
                .set("comment_err_dialog_remove_delete","Delete")

                .set("comment_err_dialog_modify","Edit comment")
                .set("comment_err_dialog_modify_title","Create anonymous comments")
                .set("comment_err_dialog_modify_pw","Password")
                .set("comment_err_dialog_modify_cancle","Cancle")
                .set("comment_err_dialog_modify_modify","Edit")

                .set("comment_reply","Reply")
                .set("comment_delete_reply"," * Deleted comment * ")

                .set("comment_add_reply","Reply")
                .set("comment_add_reply_pw","Password")
                .set("comment_add_reply_add","Add")

                .set("comment_add_err_no_text","Please enter your details")
                .set("comment_add_err_no_pw","Please enter a password")
                .set("comment_add_err","Failed to enter comment")
            break;
        default :
                comment
                .set("comment_title","개 댓글")
                .set("comment_main_PH","익명 댓글 작성")
                .set("comment_main_pw","비밀번호")
                .set("comment_main_add","등록")

                .set("comment_err_dialog_comment","댓글 오류")

                .set("comment_err_dialog_remove","댓글 삭제")
                .set("comment_err_dialog_remove_pw","비밀번호")
                .set("comment_err_dialog_remove_cancle","취소")
                .set("comment_err_dialog_remove_delete","삭제")

                .set("comment_err_dialog_modify","댓글 수정")
                .set("comment_err_dialog_modify_title","익명 댓글 작성")
                .set("comment_err_dialog_modify_pw","비밀번호")
                .set("comment_err_dialog_modify_cancle","취소")
                .set("comment_err_dialog_modify_modify","수정")

                .set("comment_reply","답글")
                .set("comment_delete_reply"," * 삭제된 댓글입니다 * ")

                .set("comment_add_reply","답글 달기")
                .set("comment_add_reply_pw","비밀번호")
                .set("comment_add_reply_add","등록")

                .set("comment_add_err_no_text","내용을 입력해주세요")
                .set("comment_add_err_no_pw","비밀번호를 입력해주세요")
                .set("comment_add_err","댓글 입력 실패")

            break;
    }

    lang_pack.set("comment",comment);
    lang_pack.set("content",content);

    return lang_pack;
}

/******************푸터*********************** */
export function lang_footer(type){
    //큰틀
    let lang_pack = new Map();
    //작은 틀
    

    switch(type){
        case KOREAN:
            lang_pack
                //제목
                .set("title","팀노바 KR")
                //서비스
                .set("service_title","Service")
                .set("service_term","이용약관")
                .set("service_personal","개인정보 처리방침")
                //사이트
                .set("site_title","Family Site")
                .set("site_official","TeamNova")
                .set("site_light","TeamNova light")
                .set("site_youtube","Youtube")
                .set("site_facebook","Facebook")
                .set("site_blog","Blog")
                .set("site_cafe","Cafe")
                .set("site_tstory","T_Story")
                //기본 회사 정보
                .set("company_title","Information")
                .set("company_name","팀노바 주식회사")
                .set("company_mail","teamnovatech0410@gmail.com")
                .set("company_location","서울특별시 동작구 사당로 17길 21 2층")
                .set("company_phone","070-4656-2316")
                .set("company_copy","Copyright. TeamNova 2019. All Right Reserved.")
            break;
        case ENGLISH:
            lang_pack
                //제목
                .set("title","TeamNova EN")
                //서비스
                .set("service_title","Service")
                .set("service_term","Terms of service")
                .set("service_personal","Privacy Policy")
                //사이트
                .set("site_title","Family Site")
                .set("site_official","TeamNova")
                .set("site_light","TeamNova light")
                .set("site_youtube","Youtube")
                .set("site_facebook","Facebook")
                .set("site_blog","Blog")
                .set("site_cafe","Cafe")
                .set("site_tstory","T_Story")
                //기본 회사 정보
                .set("company_title","Information")
                .set("company_name","TeamNova Co., Ltd.")
                .set("company_mail","teamnovatech0410@gamil.com")
                .set("company_location","21, Sadang-ro 17-gil, Dongjak-gu, Seoul, Republic of Korea")
                .set("company_phone","070-4656-2316")
                .set("company_copy","Copyright. TeamNova 2019. All Right Reserved.")
            break;
            default : break;
    }

    return lang_pack;
}
/************************************* */
/****************페이지 관련*********** */ 
/************************************* */

//내정보
export function lang_my_page(type){
    //큰틀
    let lang_pack = new Map();
    //세부틀
    let menu = new Map();           //메뉴
    let my_info = new Map();        //개인정보 수정
    let pass_change = new Map();    //비밀번호 변경
    let user_bye    = new Map();    //회원 탈퇴
    //개인정보 수정
    switch(type){
        case KOREAN:
            menu
                .set("menu_1","개인정보 수정")
                .set("menu_2","비밀번호 변경")
                .set("menu_3","회원탈퇴");
            my_info
                .set("img_btn","파일 선택")
                .set("profile_text_title","기본 설명 ( 최대 500자 )")
                .set("email","이메일")
                .set("name","이름")
                .set("password","비밀번호")
                .set("save_btn","저장")

                // 에러 메세지
                .set("err_file_size_title","파일 사이즈 오류")
                .set("err_file_size_subtitle","파일 사이즈는 최대 2MB 까지 지원합니다.")
                .set("err_profile_change","수정 오류")
                .set("err_not_change","변경된 정보가 없습니다")
                .set("err_empty_pw","비밀번호를 입력해 주세요")
                .set("err_500","비밀번호가 일치하지 않습니다")
                .set("err_404","입력정보가 일치하지 않습니다")
                .set("err_600","변경된 정보가 없습니다")

                //성공
                .set("suc_ok","변경 완료")
                .set("suc_ok_exp","회원정보 변경에 성공 했습니다.")
            pass_change
                .set("pre_pw","기존 비밀번호")
                .set("next_pw","변경할 비밀번호")
                .set("next_pw_chk","변경할 비밀번호 확인")
                .set("save_btn","변경")

                //에러 메세지
                .set("err_empty","비밀번호 입력")
                .set("err_empty_exp","비밀번호를 입력해 주세요")
                .set("err_wrong","사용할수 없는 비밀번호")
                .set("err_wrong_exp","사용할 수 없는 비밀번호 입니다")
                .set("err_change","비밀번호 변경")
                .set("err_change_exp","변경할 비밀번호를 입력해 주세요")
                .set("err_pw","비밀번호 변경")
                .set("err_pw_exp","변경할 비밀번호가 일치하지 않습니다")

                //성공
                .set("suc_pw_change","비밀번호 변경")
                .set("suc_pw_change_exp","성공적으로 비밀번호를 변경 했습니다")

            user_bye
                .set("title","회원탈퇴를 위해 비밀번호를 한번 더 입력해 주세요")
                .set("sub_title","회원 탈퇴를 하더라도 작성한 글은 삭제되지 않습니다")
                .set("pw","비밀번호")
                .set("bye_btn","회원 탈퇴")

                //에러
                .set("err_empty_pw","비밀번호")
                .set("err_empty_pw_exp","비밀번호 입력해 주세요")

                //성공
                .set("suc_bye","회원 탈퇴")
                .set("suc_bye_exp","자동적으로 로그아웃 처리 됩니다")
            break;
        case ENGLISH:
            menu
                .set("menu_1","Member")
                .set("menu_2","Password")
                .set("menu_3","Delete account");
            my_info
                .set("img_btn","Select File")
                .set("profile_text_title","Description (max 500 characters)")
                .set("email","Email")
                .set("name","Name")
                .set("password","Password")
                .set("save_btn","Save")

                // 에러 메세지
                .set("err_file_size_title","File size error")
                .set("err_file_size_subtitle","The file size is up to 2MB.")
                .set("err_profile_change","Editing error")
                .set("err_not_change","No information changed")
                .set("err_empty_pw","Please enter a password")
                .set("err_500","Passwords do not match")
                .set("err_404","Input information does not match")
                .set("err_600","No information changed")

                //성공
                .set("suc_ok","Change completed")
                .set("suc_ok_exp","Your membership information changed successfully")
            pass_change
                .set("pre_pw","Original password")
                .set("next_pw","Password to change")
                .set("next_pw_chk","Confirm password to change")
                .set("save_btn","Save")

                //에러 메세지
                .set("err_empty","Enter Password")
                .set("err_empty_exp","Please enter a password")
                .set("err_wrong","Password Error")
                .set("err_wrong_exp","Unavailable password")
                .set("err_change","Change Password")
                .set("err_change_exp","Please enter a password to change")
                .set("err_pw","Change Password")
                .set("err_pw_exp","Passwords to change do not match")

                //성공
                .set("suc_pw_change","Change Password")
                .set("suc_pw_change_exp","Successfully changed password")

            user_bye
                .set("title","Please enter your password again to delete your account")
                .set("sub_title","Deleting your account will not delete your posts")
                .set("pw","Password")
                .set("bye_btn","Delete account")

                //에러
                .set("err_empty_pw","Password")
                .set("err_empty_pw_exp","Please enter your password")

                //성공
                .set("suc_bye","Delete account")
                .set("suc_bye_exp","It will be automatically logged out")
            break;
            default : break;
    }
    lang_pack.set("menu",menu);         //메뉴
    lang_pack.set("my",my_info);        //내정보 수정
    lang_pack.set("pw",pass_change);    //비밀번호 수정
    lang_pack.set("bye",user_bye);      //회원 탈퇴

    return lang_pack;
}

/*********문의하기***************** */
export function lang_contect(type){
    let lang_pack = new Map();
    
    switch(type){
        case KOREAN:
            lang_pack
                .set("title","위치 & 문의")
                .set("location_title","찾아오시는 길")
                .set("location_text","서울특별시 동작구 사당로 17길 21 2층")
                .set("email_title","문의사항 메일 주소")
                .set("official_phone_title","공식 번호")
                .set("official_phone","070-4656-2316")
            break;
        case ENGLISH:
            lang_pack
                .set("title","Location & Contact")
                .set("location_title","Directions")
                .set("location_text","21, Sadang-ro 17-gil, Dongjak-gu, Seoul, Republic of Korea")
                .set("email_title","Inquiry E-mail address")
                .set("official_phone_title","Official phone number")
                .set("official_phone","+82-70-4656-2316")
            break;
        default : 
            lang_pack
            .set("title","위치 & 문의")
            .set("location_title","찾아오시는 길")
            .set("location_text","서울특별시 동작구 사당로 17길 21 2층")
            .set("email_title","문의사항 메일 주소")
            .set("official_phone_title","공식 번호")
            .set("official_phone","070-4656-2316")
        break;

    }
    return lang_pack;
}


//홈 화면 
export function lang_home(type){
    //큰틀
    let lang_pack = new Map();
    //세부틀
    let banner = new Map();     //배너
    //타이틀
    let item_title = new Map();
    switch(type){
        case KOREAN:
            banner
                .set("title","TEAM NOVA 기술 블로그")
                .set("sub_title","팀노바 팀원들이 직접 연구한 다양한 시각에서 바라본 연구 데이터")

            item_title
                .set("recently","최근 포스트")
                .set("populor","팀노바 인기 기술")
            break;
        case ENGLISH:
            banner
                .set("title","TEAM NOVA TECH BLOG")
                .set("sub_title","Technical data from various aspects of Team Nova members")

            item_title
                .set("recently","Recently Posts")
                .set("populor","Teamnova Popular")
            break;
            default : break;
    }
    lang_pack.set("banner",banner);
    lang_pack.set("item_title",item_title);

    return lang_pack;
}

//트렌드
export function lang_trend(type){
    //큰틀
    let lang_pack = new Map();
    //작은틀
    let head = new Map();
    let body = new Map();
    let err = new Map();
    switch(type){
        case KOREAN:
            head
                .set("title", "팀노바 사용자들의 검색어 동향을 살펴보세요")
                .set("placeholder","검색어 또는 주제 입력")
            
            body
                .set("pop_title","인기 검색어")
                .set("pop_sub_title","인기 검색어를 하나도 놓치지 마세요")
                .set("pop_more","인기 검색어 더보기")

                .set("pop_change_interest","관심도 변화" )
                .set("pop_count","회")
            
            err
                .set("not_yet","준비중입니다.")
            break;
        case ENGLISH:
            head
                .set("title", "WHAT TEAMNOVA USERS ARE SEARCHING")
                .set("placeholder","Enter search terms or topics")
            
            body
                .set("pop_title","Popular searches")
                .set("pop_sub_title","Do not miss any of top searches")
                .set("pop_more","More popular searches")

                .set("pop_change_interest","Change of interest" )
                .set("pop_count","times")
            
            err
                .set("not_yet","Coming soon.")
            break;
        default : break;
    }

    lang_pack.set("head",head);
    lang_pack.set("body",body);
    lang_pack.set("err",err);

    return lang_pack;
}

//게시판 아이템 단계 표현
export function lang_board_lv(type){
    let lang_pack = new Map();
    switch(type){
        case KOREAN:
            lang_pack
                .set("lv_1", "기초")
                .set("lv_2", "응용 1")
                .set("lv_3", "응용 2")
                .set("title", "해당 팀원이 연구했을 당시의 교육 단계")
            break;
        case ENGLISH:
            lang_pack
                .set("lv_1", "LV 1")
                .set("lv_2", "LV 2")
                .set("lv_3", "LV 3")
                .set("title", "Educational level at the time the team member studied")
            break;
    }

    return lang_pack;
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
//UI 구성
import ViewUser from '../components/items/User_Button';
//store 연결
import {connect} from 'react-redux';
//액션 연결
import * as userAction from '../store/modules/User';

class user_container extends Component{
    static propType = {
        insert_login : PropTypes.bool,
    }
    // 유저가 로그인 메뉴를 클릭 했을 때
    handle_login = () => {
        this.props.user_login();
    }
    // 유저가 아이디 패스워드를 입력하고 로그인을 시작 했을 때
    handle_login_start = () =>{
        this.props.user_login_start();
    }
    //유저가 회원가입 메뉴를 눌렀을 때
    handle_sign = () => {
        this.props.user_sign();
    }

    // 유저가 글 작성 버튼을 눌렀을 때
    handle_write =() => {
        this.props.user_write_start();
    }
    //유저 데이터 입력
    handle_user_data_input = (idx,email,name,sessionId,profile_img,default_explain,grade)=> {
        this.props.user_login_data_input(idx,email,name,sessionId,profile_img,default_explain,grade);
    }


    render(){
        // {console.log(this.props.insert_login)}
        //const {handle_login,handle_login_start,handle_sign,handle_write,handle_write_end,handle_user_data_input} = this;
        const {handle_login,handle_login_start,handle_sign,handle_write,handle_user_data_input} = this;
        const {is_login_click,is_login_start,is_login,is_sign_click,idx} = this.props;
        return(
            <ViewUser 
            //변수값 
            insert_login={this.props.insert_login}
            is_login_click={is_login_click}
            is_login_start={is_login_start}
            is_login={is_login}
            is_sign_click={is_sign_click}
            
            idx = {idx}

            //언어
            lang = {this.props.lang}
            
            //액션값
            user_login={handle_login}
            user_login_start={handle_login_start}
            user_sign={handle_sign}
            user_write={handle_write}
            user_login_data_input = {handle_user_data_input}


            />
        );
    }

}
//액션 파일 참조하기
const map_state_to_prop = (state) => ({
    is_login_click : state.user.is_login_click,
    is_login_start : state.user.is_login_start,
    is_login : state.user.is_login,
    is_sign_click : state.user.is_sign_click,
    lang : state.user.lang
});

//함수 연결
const map_dispatch_top_props = (dispatch) => ({
    user_login: () => dispatch(userAction.user_login()),
    user_login_start: () => dispatch(userAction.user_login_start()),
    user_sign: () => dispatch(userAction.user_sign()),
    user_write_start: () => dispatch(userAction.user_write_start()),
    user_login_data_input: (idx,email,name,sessionId,profile_img,default_explain,grade) => dispatch(userAction.user_login_data_input(idx,email,name,sessionId,profile_img,default_explain,grade)),
    user_logout:() => dispatch(userAction.user_logout())
});

//컴포넌트 연동
export default connect(map_state_to_prop,map_dispatch_top_props)(user_container);


import React from 'react';
//import PropTypes from 'prop-types';
import './Mobile_Navi_Menu.css';
import { Link,withRouter} from 'react-router-dom';
//사이드바
import {Sidebar} from 'primereact/sidebar';
//유저 버튼
//import UserBtn from '../../containers/User_Container';
//primereact 관련 css
import 'primereact/resources/themes/nova-dark/theme.css'; 
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

class m_menu extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            visible : false, 
            search_values : "",
            search_dropdown_menu : "none"
        };
    }
    // 로그아웃
    navigate_logout=()=>{
        //로그아웃 진행
        this.props.user_logout();
        //로컬스토리지 삭제
        localStorage.removeItem("user_info");
    }
    //검색
    search_start=(type)=>{
        //상단 검색 입니다.
        //태그를 기준으로 검색을 진행합니다.
        //검색어 안쓴거 예외처리
        if(!this.state.search_values){
            console.log("찾기 데이터 널");
            return;
        }
        this.props.history.push('/tech/'+type+"/"+this.state.search_values);
        this.setState({
            visible: false,
            search_values : ""
        })


    }
    //로그인 했을 경우 상단에 프로필 아이콘 
    view_profile_img = () => {
        if(this.props.is_login === true){
            // 로그인 했을 때
            return(
                <div>
                    <li className="m_nav_user_img">
                        <img id = "menu_profile_img" src={this.props.profile_img} alt = "user_img"/>
                    </li>
                </div>
                
            )
        }
    }
    //로그인 했을 경우 추가 메뉴
    view_profile_menu = () => {
        if(this.props.is_login === true){
            return(
                <div onClick={ (e) => this.setState({visible:false})}>
                    <li id="menu_list_item">
                        <Link to ="/write/new_board/0" className="menu_item">{this.props.lang.get("my_menu").get("write")}</Link>
                    </li>
                    <li id="menu_list_item">
                        <Link to ="/mypage" className="menu_item">{this.props.lang.get("my_menu").get("my")}</Link>
                    </li>
                </div>
            )
        }
    }
    
    //로그인 안 했을 때 하단에 로그인 버튼 생성
    view_login_btn=()=>{
        if(this.props.is_login === false){
            // 로그인 안 했을 떄
            return(
                <ul className="menu_btn_ul" onClick={ (e) => this.setState({visible:false})}>
                    {/* 로그인 */}
                    <li ><Link to ="/signin" ><div>{this.props.lang.get("my_menu").get("signin")}</div></Link></li>
                    {/* 회원가입*/}
                    <li ><Link to ="/signup" ><div>{this.props.lang.get("my_menu").get("signup")}</div></Link></li>
                </ul>
            );
        }else{
            return(
                <div className="menu_btn_ul_logout">
                    {/* 로그아웃 */}
                    <Button label={this.props.lang.get("my_menu").get("signout") } 
                    style={{background : "transparent", color : "black", border: "none" , width : "100%"}}
                    onClick={(e)=> this.navigate_logout()} 
                    />
                </div>
            );
        }
    }
    // 검색어 입력을 시작하면 어떤 검색을 할지 보여주는 메서드
    search_input_start=()=>{
        this.setState({
            search_dropdown_menu : "show"
        }, () => {
            document.addEventListener("click",this.search_input_end);
        })
    }

    search_input_end = () =>{
        this.setState({
            search_dropdown_menu : "none"
        },() => {
            document.removeEventListener('click', this.search_input_end);
        });
    }
    render(){
        return(
            <div id="MENU">
                {/* 클릭값으로 state.visible 변경. show || hide 2가지 존재 */}
                <button id="mobile_menu" type="submit" onClick={(e) => { e.preventDefault(); this.setState({visible:true})}}> 
                MENU
                </button>

                <div className="side_bar_content" >
                    <Sidebar visible={this.state.visible} position="left" baseZIndex={1000000} onHide={(e) => this.setState({visible:false})}>
                        {/* 클레스 네임으로 css 따로 적용 */}
                        {/* 타이틀 이름, 클릭하면 메뉴 창이 사라집니다. */}
                        {this.view_login_btn()}
                        <ul className="menu_list_ul">
                                {this.view_profile_img()}
                                <li >
                                    <button className="menu_flag" type="submit" onClick={this.props.user_lang_ko} ><img className="btn-img" alt="button_img" src={process.env.REACT_APP_BASE_URL+"/_nova/_img/_flags/South-Korea-flag.png"}/></button>
                                    <button className="menu_flag" type="submit" onClick={this.props.user_lang_en} ><img className="btn-img" alt="button_img" src={process.env.REACT_APP_BASE_URL+"/_nova/_img/_flags/USA-flag.png"}/></button>
                                </li>
                                <li id="nav_search">
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-inputgroup" >
                                            <InputText placeholder={this.props.lang.get("search").get("search")} onClick={(e)=> this.search_input_start()} onChange={(e)=> this.setState({search_values : e.target.value})} value={this.state.search_values}/>
                                            {/* <Button icon="pi pi-search" className="p-button-secondary" onClick={(e)=>{this.search_start()}}/> */}
                                        </div>
                                        <div className={"search_dropdown_menu_"+this.state.search_dropdown_menu} id="m_search_dropdown">
                                            <div onClick={()=>this.search_start("tag")}><h3>{"Tag: "+this.state.search_values}</h3><i className="pi pi-search"></i></div>
                                            <div onClick={()=>this.search_start("name")}><h3>{"Name: "+this.state.search_values}</h3><i className="pi pi-search"></i></div>
                                        </div>
                                    </div>    
                                </li>
                                {/* 로그인했을경우 개인 메뉴 */}
                                {this.view_profile_menu()}
                                {/* 기본 경로인 홈 입니다. */}
                                <li id="menu_list_item" onClick={ (e) => this.setState({visible:false})}><Link to ="/home" className="menu_item"><div>{this.props.lang.get("menu").get("home")}</div></Link></li>
                                {/* 기술 내용 콘텐츠 전용 */}
                                <li id="menu_list_item" onClick={ (e) => this.setState({visible:false})}><Link to ="/tech/tag/default" className="menu_item"><div>{this.props.lang.get("menu").get("tech")}</div></Link></li> 
                                {/* 트렌드*/}
                                <li id="menu_list_item" onClick={ (e) => this.setState({visible:false})}><Link to ="/trend" className="menu_item"><div>{this.props.lang.get("menu").get("trend")}</div></Link></li> 
                                {/* 콘택트어스 */}
                                <li id="menu_list_item" onClick={ (e) => this.setState({visible:false})}><Link to ="/contectus" className="menu_item"><div>{this.props.lang.get("menu").get("contect")}</div></Link></li>
                        </ul>
                    </Sidebar>
                </div>
            </div>
        )
    }
}

export default withRouter(m_menu);
import React from 'react';
import BaseContainer from '../containers/BaseContainer';
import {Provider} from 'react-redux';

import store from '../store';
 
class Root extends React.Component{

    render(){
        return(
            // 스토어 연결
            <Provider store={store}>
                <BaseContainer />
            </Provider>
        )
    }
}

export default Root;
 
import { createAction} from 'redux-actions';

//액션 타입 지정
const MOBILE_MENU_SHOW = 'menu/MOBILE_MENU_SHOW';     //열기
const MOBILE_MENU_HIDE = 'menu/MOBILE_MENU_HIDE';     //닫기


//액션 생성자
//메뉴 보이기
export const menuShow = createAction(MOBILE_MENU_SHOW);
//메뉴 숨기기
export const menuHide = createAction(MOBILE_MENU_HIDE);


//초기화
const mobile_menu_initialState= {
    //메뉴를 보이고 안보이고를 설정
    visible : false,
    //메뉴에 들어가는 목록들
    menu_data: [
        {label : 'HOME', values: 'H'},
        {label : 'TECH' , values:'T'}
    ]
};

//클릭 지정
//클릭했을때 show, hide 를 번갈아 가면서 바꿔주는 펑션
export default (state = {mobile_menu_initialState},action) => {
    switch(action.type){
        case MOBILE_MENU_SHOW:
            return {
                visible : true
            };
        case MOBILE_MENU_HIDE:
            return {                
                visible : false
            };
        default:
            return mobile_menu_initialState;
    }
}
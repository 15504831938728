// 네비게이션 테크 클릭시 나오게 되는 기술 블로그 전문입니다.

import React from 'react';
import './TechContainer.css';
import PropTypes from 'prop-types';
//store 연결
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
// 상단 베너용 워드 클라우드
import Wordcloud from '../components/items/Word_Cloud';
//기술 정보에 대한 리스트 ui
import LISTDATA from '../components/items/List_Blog_Item.js';
// 액션
import * as userAction from '../store/modules/User';
//통신
import * as Api from '../utils/Api';
//페이지네이션
import {Paginator} from 'primereact/paginator';
// seo 검색용의성을 위한 헬멧 추가
import {Helmet} from 'react-helmet'

import {get_previous_url} from '../utils/Func';

class Tech extends React.Component{

    constructor(props) {
        super(props);
        this.state = { 
            first: this.props.tech_pagenation_count,       //표시할 아이템 첫번쨰 인덱스
            rows : 5,        //표시 row
            totCnt : 1,     //전체 아이템카운트            
            page_data : null, //페이지에 들어갈 데이터
            word_cloud : null,  //워드 클라우드 데이터
            search_tag : null,  //검색알 아이템
            search_type : null, //이름으로? 제목으로?
            //언어
            lang : "KR",     //선택언어
        };
        this.on_page_change = this.on_page_change.bind(this);
    }
    static propType = {
        user_pagenation_count : PropTypes.func
    }

    componentWillMount(){
        //언어팩 설정
        this.setState({
            lang : this.props.lang,
        })
        // console.log(this.state.lang);

        get_previous_url();

    }

    //컴포넌트가 마운트 되면 데이터 통신을 진행해서 데이터를 변경해줍니다.
    componentDidMount(){
        //검색을 위한 match param을 받습니다.
        //기본 default 로 들어오며, 검색을 했을 경우 다른 값으로 들어오게 되고 
        //그 값을 통해 통신을 진행합니다.
        let tag_name = this.props.match.params.tag_name;
        let search_type = this.props.match.params.search_type;
        // console.log(this.state.first+"//"+tag_name+"//"+this.state.lang+"//"+search_type);
        this.get_page(this.state.first,tag_name,this.state.lang,search_type);
        this.setState({
            search_tag : tag_name,
            search_type : search_type
        })
        window.scrollTo(0,0);
        
    }
    componentDidUpdate(next_data,pre_data){
        // console.log(next_data);
        //무한루프 방지용 기존 태그와 새 태그
        if(this.state.search_tag === this.props.match.params.tag_name){ 
            return;
        }
        // console.log("들어옴 "+this.state.search_tag);
        // console.log("들어옴 "+this.props.match.params.tag_name); 
        //일단 state 초기화
        this.setState({
            first: this.props.tech_pagenation_count,       //표시할 아이템 첫번쨰 인덱스
            rows : 5,        //표시 row
            totCnt : 1,     //전체 아이템카운트            
            page_data : null, //페이지에 들어갈 데이터
            word_cloud : null,  //워드 클라우드 데이터
        })
     
        
        //통신 진행
        let tag_name = this.props.match.params.tag_name;
        let search_type = this.props.match.params.search_type;
        // console.log(tag_name+"/"+search_type);
        this.get_page(this.state.first,tag_name,this.state.lang,search_type);
        this.setState({
            search_tag : tag_name,
            search_type : search_type
        })
        window.scrollTo(0,0);


    }

    componentWillReceiveProps(next_data){

        // console.log(next_data);
        // console.log("----------------------------")
        // 기존 데이터, 이후 데이터의 언어가 바뀌었을 경우 상태값 초기화
        if(next_data["lang"] !== this.state.lang){
            this.setState({
                lang : next_data["lang"],
                first : 0,
                rows : 5,
                page_data : null,
                tag_name :"default",
                search_type : "tag"
            })
            // console.log(0,next_data.match.params.tag_name,next_data["lang"]);
            //언어가 변경되면 검색어를 초기화하고 페이지를 초기화힙니다.
            this.get_page(0,next_data.match.params.tag_name,next_data["lang"],"tag");
            // this.props.history.push('/tech/tag/default');
            // console.log("언어 변경 감지");
            window.scrollTo(0,0);
        }else if(next_data.match.params.search_type !== this.props.match.params.search_type){
            // 검색 타입이 바뀌었다
            this.setState({
                first : 0,
                rows : 5,
                page_data : null,
                tag_name :next_data.match.params.tag_name,
                search_type :next_data.match.params.search_type
            })
            this.get_page(0,next_data.match.params.tag_name,this.state.lang,next_data.match.params.search_type);
        }

        this.setState({
            search_type : next_data.match.params.search_type
        })
    }

    componentWillUnmount(){
        this.props.user_pagenation_reset();
    }

    //페이지네이션
    set_pagination_ui=()=>{
        if(this.state.totCnt >= 5){
            return (
                <div>
                    <Paginator first={this.state.first} rows={this.state.rows} totalRecords={Number(this.state.totCnt)} onPageChange={this.on_page_change}></Paginator>
                </div>
            )
        }
    }
    //페이지 체인지 이벤트 리스너
    on_page_change(event){
        this.setState({
            first: event.first,
            rows: event.rows
        });
        this.get_page(event.first,this.props.match.params.tag_name,this.state.lang,this.state.search_type);
        // 페이지가 바뀌었으니 최 상단으로 이동해줍니다.
        window.scrollTo(0, 0);
    }
    //통신용 함수
    get_page=(first,search_tag_name,lang,search_type)=>{
        //현 클레스 바인딩
        let current = this;
        //페이지 저장
        this.props.user_pagenation_count(first);
        //페이지를 계산합니다. first 는 현 페이지의 마지막 아이템 인덱스 번호
        let page = (first / 5)+1;

        // 기본자
        let search_data = "";
        // 기본자인 default 일 경우가 아닐 경우에만!
        if(search_tag_name !== "default"){
            // console.log(this.props.search_words.length);
            if(this.props.search_words.length >= 1){
                var last_search_word = this.props.search_words[parseInt(this.props.search_words.length-1)];
                // console.log(last_search_word);
                // console.log(search_tag_name);
                search_data = this.props.search_words;
                if(last_search_word !== search_tag_name){
                    this.props.user_search_words(search_tag_name);
                    search_data.push(search_tag_name);    
                    // console.log("같지않아!"); 
                }

                if(search_data.length >= 3){
                    search_data = search_data.slice(search_data.length-2,search_data.length);
                }
            }else{
                // 유저 검색어 리스트를 만들어 줍니다.
                this.props.user_search_words(search_tag_name);
                // 액션자 특성상 랜더링 되고 데이터가 업데이트되기 떄문에, 현 상태에서 따로 업데이트 해줍니다.
                
                search_data = this.props.search_words;
                search_data.push(search_tag_name);
            }
        }
        // console.log(search_data);
        
        //api 유틸 펑션을 사용해서 통신 데이터 폼 만들기
        // console.log(lang+"//"+page+"//"+search_data+"//"+search_type)
        let data = Api.GET_TECH(lang,page,search_data,search_type);

        Api.axios_instance.post(`${process.env.REACT_APP_PAGES}`,data)
        .then(function(res){
            var retJson = res.data;
            // console.log(retJson);
            if(retJson['retCode']===0){
                //보드 데이터
                var board = retJson['retBody']["page_data"];
                var temp_totCnt = retJson['retBody']["page_data"]["totCnt"];
                var temp_word = retJson['retBody']["word_cloud"];
                current.setState({
                    page_data : board,
                    totCnt : temp_totCnt,
                    word_cloud : temp_word,
                });
            }
        })
    }

    // 일반 리스트와 검색어에 따른 결과물 구분 을해줍니다.
    tech_type =() => {
        // console.log(this.state.search_type);
        if(this.state.search_tag === "default"){
            // 기본 리스트
            
        }else{
            if(this.state.search_type ==="tag"){
                return(
                    <div className="tech_type_div">
                        <h1 title = {this.state.search_tag}>
                            <span><i className="pi pi-search"></i>{"Search Tag :"}</span>
                            <span>{" "+this.state.search_tag}</span>
                        </h1>
                    </div>
                )
            }else{
                return(
                    <div className="tech_type_div">
                        <h1 title = {this.state.search_tag}>
                            <span><i className="pi pi-search"></i>{"Search Name :"}</span>
                            <span>{" "+this.state.search_tag}</span>
                        </h1>
                    </div>
                )
            }
        }
    }
    
    render(){
        return(
            <div className="tech_content_root" >
                <Helmet>
                    <title>팀노바테크 | 연구데이터</title>
                    <meta name = "description" content = {"팀노바 팀원들의 100% 직접 연구한 세계 최고의 데이터 검색어 : "+this.state.search_tag}/>
                    <meta name="keywords" content={"팀노바 테크, 팀노바 연구, 팀노바, 팀노바 결과, 팀노바 후기"} />
                </Helmet>

                <Wordcloud data={this.state.word_cloud}/>
                {/* 테크 화면에서 검색, 일반 리스트를 구분해줍니다. */}
                {this.tech_type()}
                <div className="list_div">
                    {/*재사용 하는 컴포넌트 입니다. 현 사용법에서는 페이징 부분까지 표시됩니다. */}
                    <LISTDATA type='full' data ={this.state.page_data} pagenation_idx ={this.state.first} lang={this.props.lang}/>
                </div>
                <div id="pagination_div">
                    {/* 페이징이 필요하면 페이징을 진행합니다. */}
                    {this.set_pagination_ui()}
                </div>
            </div>
        )
    };
}
//props 값으러 넣을 상태값 정의
const map_state_to_props = (state) => ({
    lang : state.user.lang,
    tech_pagenation_count : state.user.tech_pagenation_count,
    search_words : state.user.search_words,

});

const map_dispatch_top_props = (dispatch) => ({
    user_pagenation_count:(pagenation_idx) => dispatch(userAction.user_pagenation_count(pagenation_idx)),
    user_pagenation_reset:() => dispatch(userAction.user_pagenation_reset()),
    user_search_words : (word) => dispatch(userAction.user_search_words(word)),

});


export default withRouter(connect(map_state_to_props,map_dispatch_top_props)(Tech));

// 서비스 이용약관 및 개인정보 처리방침을 보여주는 곳 입니다.
// 진입 장소는 현재는 푸터와 회원가입시 볼 수 있습니다.
import React from 'react';
//탭뷰 형식
import {TabView,TabPanel} from 'primereact/tabview';
import './Service_Terms.css';

class service_terms extends React.Component{
    // 이용약관 인덱스 1
    // 개인정보 처리방침 인덱스 2
    constructor(props) {
        super(props);
  
        this.state = {
            activeIndex: 0
       };
     }

    render(){
        return(
            <div className='terms_main_div'>
                <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                    <TabPanel header="이용약관">
                    <h1>팀노바(주) 이용약관</h1>
                    <p>제 1 장 총칙</p>

                    <p>제 1 조 (목적)<br />
                    본 약관은 팀노바(주)가 운영하는 웹 사이트('https://teamnovatech.co.kr/')의 제반 서비스의 이용조건 및 절차에 관한 사항 및 기타 필요한 사항을 규정함을 목적으로 한다.</p>

                    <p>제 2 조 (용어의 정의)<br />
                    본 약관에서 사용하는 용어는 다음과 같이 정의한다.</p>

                    <p>①팀원 : 팀노바 팀원으로 등록되었고, 팀원 상세 정보를 입력하여 팀노바 사이트의 계정을 부여받은 개인<br /><br />

                    ②아이디(ID) : 팀원식별과 회원의 서비스 이용을 위해 회원의 전자우편(Email)을 아이디로 함<br />
                    ③비밀번호(Password) : 회원이 통신상의 자신의 비밀을 보호하기 위해 선정한 문자와 숫자의 조합<br />
                    ④해지/탈퇴 : 팀노바 커리큘럼 중도 포기자나 수료자 중 별도 사유로 인해 수료가 취소된 자<br />
                    ⑤콘텐츠 : 회사가 제작하여 웹사이트에서 제공 및 판매하는 온라인 강좌 및 기타 관련정보를 의미함으로서, 정보통신망이용촉진 및 정보보호 등에 관한 법률 제2조 제1항 제1호의 규정에 의한 정보통신망에서 사용되는 부호ㆍ문자ㆍ음성ㆍ음향ㆍ이미지 또는 영상 등으로 표현된 자료 또는 정보를 말함<br />
                    ⑥전자우편(Email) : 인터넷을 통한 우편 혹은 전기적 매체를 이용한 우편을 말함<br />
                    ⑦관리자 : 서비스의 전반적인 관리와 원활한 운영을 위하여 회사에서 선정한 사람 또는 회사를 말함</p>

                    <p>제 3 조 (약관의 공시 및 효력과 변경)<br />
                    ①본 약관은 회원가입 화면에 게시하여 공시하며 회사는 사정변경 및 영업상 중요한 사유가 있을 경우 약관을 변경할 수 있으며 변경된 약관은 공지사항을 통해 공시한다<br />
                    ②본 약관 및 차후 회사사정에 따라 변경된 약관은 이용자에게 공시함으로써 효력을 발생한다.</p>

                    <p>제 4 조 (약관 외 준칙)<br />
                    본 약관에 명시되지 않은 사항이 전기통신기본법, 전기통신사업법, 정보통신촉진법, ‘약관의 규제에관한법률’, ‘정보통신망 이용촉진등에 관한 법률’등 기타 관계 법령에 규정되어 있을 경우에는 그 규정을 따르도록 한다.</p>

                    <p>제 2 장 이용계약</p>

                    <p>제 5 조 (이용신청)<br />
                    ①이용신청자가 회원가입 안내에서 본 약관과 개인정보보호정책에 동의하고 등록절차(회사의 소정 양식의 가입 신청서 작성)를 거쳐 '확인' 버튼을 누르면 이용신청을 할 수 있다.<br />
                    ②이용신청자는 반드시 실명과 실제 정보를 사용해야 하며 1개의 생년월일에 대하여 1건의 이용신청을 할 수 있다.<br />
                    ③실명이나 실제 정보를 입력하지 않은 이용자는 법적인 보호를 받을 수 없으며, 서비스 이용에 제한을 받을 수 있다.</p>

                    <p>제 6 조 (이용신청의 승낙)<br />
                    ①회사는 제5조에 따른 이용신청자에 대하여 제2항 및 제3항의 경우를 예외로 하여 서비스 이용을 승낙한다.<br />
                    ②회사는 아래 사항에 해당하는 경우에 그 제한사유가 해소될 때까지 승낙을 유보할 수 있다.<br />
                    가. 서비스 관련 설비에 여유가 없는 경우<br />
                    나. 기술상 지장이 있는 경우<br />
                    다. 기타 회사 사정상 필요하다고 인정되는 경우<br />
                    ③회사는 아래 사항에 해당하는 경우에 승낙을 하지 않을 수 있다.<br />
                    가. 다른 사람의 명의를 사용하여 신청한 경우<br />
                    나. 이용자 정보를 허위로 기재하여 신청한 경우<br />
                    다. 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우<br />
                    라. 기타 회사가 정한 이용신청 요건이 미비한 경우</p>

                    <p>제 3 장 계약 당사자의 의무</p>

                    <p>제 10 조 (회사의 의무)<br />
                    ①회사는 사이트를 안정적이고 지속적으로 운영할 의무가 있다.<br />
                    ②회사는 이용자로부터 제기되는 의견이나 불만이 정당하다고 인정될 경우에는 즉시 처리해야 한다. 단, 즉시 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 공지사항 또는 전자우편을 통해 통보해야 한다.<br />
                    ③제1항의 경우 수사상의 목적으로 관계기관 및 정보통신윤리위원회의 요청이 있거나 영장 제시가 있는 경우, 기타 관계 법령에 의한 경우는 예외로 한다.</p>

                    <p>제 11 조 (이용자의 의무)<br />
                    ①이용자는 본 약관 및 회사의 공지사항, 사이트 이용안내 등을 숙지하고 준수해야 하며 기타 회사의 업무에 방해되는 행위를 해서는 안된다.<br />
                    ②이용자는 회사의 사전 승인 없이 본 사이트를 이용해 어떠한 영리행위도 할 수 없다.<br />
                    ③이용자는 본 사이트를 통해 얻는 정보를 회사의 사전 승낙 없이 복사, 복제, 변경, 번역, 출판, 방송 및 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없다.</p>

                    <p>제 4 장 서비스의 제공 및 이용</p>

                    <p>제 7 조 (서비스 이용)<br />
                    ①이용자는 본 약관의 규정된 사항을 준수해 사이트를 이용한다.<br />
                    ②본 약관에 명시되지 않은 서비스 이용에 관한 사항은 회사가 정해 '공지사항'에 게시하거나 또는 별도로 공지하는 내용에 따른다.</p>

                    <p>제 8 조 (정보의 제공)<br />
                    회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보에 대하여 전자메일이나 서신우편 등의 방법으로 회원에게 정보를 제공할 수 있다.</p>

                    <p>제 9 조 (광고게재)<br />
                    ①회사는 서비스의 운용과 관련하여 서비스 화면, 홈페이지, 전자우편 등에 광고 등을 게재할 수 있다.<br />
                    ②회사는 사이트에 게재되어 있는 광고주의 판촉활동에 회원이 참여하거나 교신 또는 거래의 결과로서 발생하는 모든 손실 또는 손해에 대해 책임을 지지 않는다.</p>

                    <p>제 10 조 (서비스 이용의 제한)<br />
                    본 사이트 이용 및 행위가 다음 각 항에 해당하는 경우 회사는 해당 이용자의 이용을 제한할 수 있다.<br />
                    ①공공질서 및 미풍양속, 기타 사회질서를 해하는 경우<br />
                    ②범죄행위를 목적으로 하거나 기타 범죄행위와 관련된다고 객관적으로 인정되는 경우<br />
                    ③타인의 명예를 손상시키거나 타인의 서비스 이용을 현저히 저해하는 경우<br />
                    ④타인의 의사에 반하는 내용이나 광고성 정보 등을 지속적으로 전송하는 경우<br />
                    ⑤해킹 및 컴퓨터 바이러스 유포 등으로 서비스의 건전한 운영을 저해하는 경우<br />
                    ⑥다른 이용자 또는 제3자의 지적재산권을 침해하거나 지적재산권자가 지적 재산권의 침해를 주장할 수 있다고 판단되는 경우<br />
                    ⑦타인의 아이디 및 비밀번호를 도용한 경우<br />
                    ⑧기타 관계 법령에 위배되는 경우 및 회사가 이용자로서 부적당하다고 판단한 경우</p>

                    <p>제 11 조 (서비스 제공의 중지)<br />
                    회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부의 제공을 중지할 수 있다.<br />
                    ①전기통신사업법 상에 규정된 기간통신 사업자 또는 인터넷 망 사업자가 서비스를 중지했을 경우<br />
                    ②정전으로 서비스 제공이 불가능할 경우<br />
                    ③설비의 이전, 보수 또는 공사로 인해 부득이한 경우<br />
                    ④서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이 어려운 경우<br />
                    ⑤전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우</p>

                    <p>제 12 조 (게시물 관리)<br />
                    회사는 건전한 통신문화 정착과 효율적인 사이트 운영을 위하여 이용자가 게시하거나 제공하는 자료가 제10조에 해당한다고 판단되는 경우에 임의로 삭제, 자료이동, 등록거부를 할 수 있다.</p>

                    <p>제 13 조 (서비스 이용 책임)<br />
                    이용자는 회사에서 권한 있는 사원이 서명한 명시적인 서면에 구체적으로 허용한 경우를 제외하고는 서비스를 이용하여 불법상품을 판매하는 영업활동을 할 수 없으며 특히 해킹, 돈벌기 광고, 음란 사이트를 통한 상업행위, 상용 S/W 불법제공 등을 할 수 없다. 이를 어기고 발생한 영업활동의 결과 및 손실, 관계기관에 의한 구속 등 법적 조치 등에 관해서는 회사가 책임을 지지 않는다.</p>

                    <p>제 6 장 기타</p>

                    <p>제 14 조 (면책 및 손해배상)<br />
                    ①천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 회사의 서비스 제공 책임이 면제된다.<br />
                    ②회사는 이용자간 또는 이용자와 제3자간의 상호거래 관계에서 발생되는 결과에 대하여 어떠한 책임도 부담하지 않는다.<br />
                    ③회사는 이용자가 게시판에 게재한 정보, 자료, 내용 등에 관하여 사실의 정확성, 신뢰도 등에 어떠한 책임도 부담하지 않으며 이용자는 본인의 책임 아래 본 사이트를 이용해야 한다.<br />
                    ④이용자가 게시 또는 전송한 자료 등에 관하여 손해가 발생하거나 자료의 취사선택, 기타 무료로 제공되는 서비스 이용과 관련해 어떠한 불이익이 발생하더라도 이에 대한 모든 책임은 이용자에게 있다.<br />
                    ⑤아이디와 비밀번호의 관리 및 이용자의 부주의로 인하여 발생되는 손해 또는 제3자에 의한 부정사용 등에 대한 책임은 이용자에게 있다.<br />
                    ⑥이용자가 본 약관의 규정을 위반함으로써 회사에 손해가 발생하는 경우 이 약관을 위반한 이용자는 회사에 발생한 모든 손해를 배상해야 하며 동 손해로부터 회사를 면책시켜야 한다.</p>

                    <p>제 15 조 (개인신용정보 제공 및 활용에 대한 동의서)<br />
                    회사가 회원 가입과 관련해 취득한 개인 신용 정보는 신용정보의 이용 및 보호에 관한 법률 제23조의 규정에 따라 타인에게 제공 및 활용 시 이용자의 동의를 얻어야 한다. 이용자의 동의는 회사가 회원으로 가입한 이용자의  신용정보를 신용정보기관, 신용정보업자 및 기타 이용자 등에게 제공해 이용자의 신용을 판단하기 위한 자료로서 활용하거나 공공기관에서 정책자료로 활용하는데 동의하는 것으로 간주한다.</p>

                    <p>제 16 조 (분쟁의 해결)
                    ①회사와 이용자는 본 사이트 이용과 관련해 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 해야 한다.<br />
                    ②제1항의 규정에도 불구하고 동 분쟁으로 인하여 소송이 제기될 경우 동 소송은 회사의 본사 소재지를 관할하는 법원의 관할로 본다.</p>

                    <p>본 약관은 2019년 8월 1일부터 적용한다.</p>

                    </TabPanel>
                    <TabPanel header="개인정보 처리방침">
                    <h1>팀노바(주) 개인정보 처리방침</h1>
                    <p>"팀노바(주)"('https://teamnovatech.co.kr/이하 '회사')은(는) 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.<br />
                    "팀노바(주)"('회사') 은(는) 회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.<br /><br />
                    ○ 본 방침은부터 2019년 8월 1일부터 시행됩니다.</p>

                    <ol>
                    <li><p>개인정보의 처리 목적 "팀노바(주)"('https://teamnovatech.co.kr/이하 '회사')은(는) 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.<br />
                    가. 홈페이지 회원가입 및 관리<br />
                    회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.<br />
                    나. 재화 또는 서비스 제공<br />
                    서비스 제공, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증 등을 목적으로 개인정보를 처리합니다.<br />
                    다. 마케팅 및 광고에의 활용<br />
                    서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.<br />
                    라. 개인영상정보<br />
                    범죄의 예방 및 수사, 시설안전 및 화재예방 등을 목적으로 개인정보를 처리합니다.</p></li>

                    <li><p>개인정보 파일 현황
                    팀노바(주)>('https://teamnovatech.co.kr/'이하 '회사')가 개인정보 보호법 제32조에 따라 등록․공개하는 개인정보파일의 처리목적은 다음과 같습니다.</p></li>

                    <li><p>개인정보 파일명 : 사용자 개인정보</p></li>
                    </ol>

                    <ul>
                    <li>개인정보 항목 : 이메일, 이름, 비밀번호, 직책, 부서, 직업, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보</li>

                    <li>수집방법 : 홈페이지, 전화/팩스</li>

                    <li>보유근거 : 이용약관 및 개인정보처리방침</li>

                    <li>보유기간 : 이용계약에 따른 회원자격이 유지되는 기간동안</li>
                    </ul>

                    <p>※ 기타('https://teamnovatech.co.kr/'이하 '회사')의 개인정보파일 등록사항 공개는 행정안전부 개인정보보호 종합지원 포털(www.privacy.go.kr) → 개인정보민원 → 개인정보열람등 요구 → 개인정보파일 목록검색 메뉴를 활용해주시기 바랍니다.</p>

                    <ol>
                    <li>개인정보의 처리 및 보유 기간</li>
                    </ol>

                    <p>① "팀노바(주)"('회사')은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다.</p>

                    <p>② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br />
                    1."홈페이지 회원가입 및 관리"<br />
                    "홈페이지 회원가입 및 관리"와 관련한 개인정보는 수집.이용에 관한 동의일로부터"이용계약에 따른 회원자격이 유지되는 기간동안"까지 위 이용목적을 위하여 보유.이용됩니다.<br />
                    -보유근거 : 이용약관 및 개인정보처리방침</p>

                    <ol>
                    <li><p>개인정보처리 위탁<br /><br />
                    지적재산권<br />
                    ① "회원"은 "서비스" 에 제공한 "회원"의 "콘텐츠"(게시판에 게시한 글 등 "회원"이 서비스에 제공하거나 서비스를 이용하면서 업로드한 자료 일체)와 관련하여 저작권, 인격권 등 제 3자와의 분쟁 발생시 그 법적 대응 및 결과에 대한 책임을 지며, "회사"는 해당 "콘텐츠"와 관련된 일체의 책임을 명시적으로 부인합니다.<br />
                    ② "회사"는 "서비스"를 통한 저작권 침해행위나 지적재산권 침해를 허용하지 아니하며, "회원"의 "콘텐츠"가 타인의 지적재산권을 침해한다는 사실을 적절하게 고지받거나 인지하게 되는 경우 저작권법 기타 관련 법령에서 정한 절차에 따라 그 "콘텐츠" 일체의 서비스를 중지하거나 제거할 수 있습니다. 또한 "회사"는 "회원"의 "콘텐츠"가 "서비스" 운영 상의 문제가 있다고 판단하는 경우 사전통지 없이 삭제하거나 이동 또는 등록 거부할 수 있는 권리를 보유합니다.<br />
                    ③ "회원"의 "콘텐츠"에 대한 저작권은 원래의 저작자가 보유하되, 본 약관이 정하는 바에 따라 "회사" 혹은 "회사가 지정한 자"에게 "콘텐츠"에 대한 사용 권한을 부여합니다. "회사"가 "회원"의 "콘텐츠"를 사용하는 용도와 방법은 아래와 같습니다.<br />
                    (i) "회원"이 "서비스"에 제공한 "콘텐츠"를 타 회원 기타 이용자가 시청하도록 함.<br />
                    (ii) "회원"이 "서비스"에 제공한 "콘텐츠"를 "회사"나 타 회원 기타 이용자가 녹화/편집/변경하여 새로운 콘텐츠로 제작한 다음 이를 "서비스"의 타 회원 기타 이용자들이 시청하게 하거나, 또는 "회사"의 제휴사에 제공하여 그 이용자들이 이를 시청할 수 있도록 함.<br />
                    (iii) "회원"이 "서비스"에 제공한 "콘텐츠"를 "회사"가 저장한 후 이를 VOD등의 다시 보기 서비스로 "서비스" 회원 기타 이용자들이 시청할 수 있게 하거나, "회사"의 제휴사에 제공함으로써 제휴사가 그 이용자들에게 스트리밍 또는 VOD 등의 다시보기 서비스로 시청할 수 있게끔 함.<br />
                    ④ 본 조 제③항에 규정한 "회사", "타 회원 기타 이용자" 및 "회사의 제휴사"가 "회원"의 "콘텐츠"를 이용할 수 있는 조건은 아래와 같습니다.<br />
                    (i) 콘텐츠 이용 매체/플랫폼 - 현재 알려져 있거나 앞으로 개발된 모든 형태의 매체, 장비, 기술을 포함함.<br />
                    (ii) 콘텐츠 이용 용도 - 상업적 또는 비상업적 이용을 구분하지 않음.<br />
                    (iii) 콘텐츠 이용 범위 - 국내 및 국외에서의 복제, 수정, 각색, 공연, 전시, 방송, 배포, 대여, 공중송신, 2차적 저작물 작성, 기타 이용<br />
                    (iv) 콘텐츠 이용 조건 - 비독점적, 지속적인 무상의 권리로서 양도 가능하며, 취소 불가하고 서브 라이센스가 가능함.<br />
                    ⑤ 본 조 제③항의 "회원"의 사용허락은 "회사"가 공지, 이용안내에서 정한 바에 따라 장래에 대하여 철회할 수 있습니다.<br /><br />
                    제25조 관할법원과 준거법<br />
                    ① 서비스 이용에 관하여 회사와 이용자 간에 분쟁이 발생한 경우 협의로 원만히 해결합니다<br />
                    ② 전항으로 해결되지 아니할 경우 소송의 관할은 “회사”의 주소를 기준으로 합니다.<br />
                    ③ 본 약관의 해석 및 적용은 대한민국 법령을 기준으로 합니다.</p></li>

                    <li><p>정보주체와 법정대리인의 권리·의무 및 그 행사방법 이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.<br />
                    ① 정보주체는 팀노바(주)에 대해 언제든지 개인정보 열람,정정,삭제,처리정지 요구 등의 권리를 행사할 수 있습니다.<br />
                    ② 제1항에 따른 권리 행사는팀노바(주)에 대해 개인정보 보호법 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 팀노바(주)은(는) 이에 대해 지체 없이 조치하겠습니다.<br />
                    ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br />
                    ④ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.<br />
                    ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br />
                    ⑥ 팀노바(주)은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</p></li>

                    <li><p>처리하는 개인정보의 항목 작성 </p></li>
                    </ol>

                    <p>① "팀노바(주)"('https://teamnovatech.co.kr/'이하 '회사')은(는) 다음의 개인정보 항목을 처리하고 있습니다.<br />
                    1"홈페이지 회원가입 및 관리"</p>

                    <ul>
                    <li>필수항목 : 이메일, 비밀번호, 이름, 직책, 부서, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록</li>
                    </ul>


                    <p>개인정보의 파기"팀노바(주)"('회사')은(는) 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.</p>
                    <p>
                    -파기절차<br />
                    이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.</p>


                    <p>-파기기한<br /> 
                    이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.<br /><br />
                    -파기방법<br />
                    전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.</p>

                    <ol>
                    <li><p>개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항<br />
                    ① 팀노바(주) 은 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다. 가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다. 나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다. 다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</p></li>

                    <li><p>개인정보 보호책임자 작성<br />
                    ① 팀노바(주)(‘https://teamnovatech.co.kr/’이하 ‘회사) 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p></li>
                    </ol>

                    <p>▶ 개인정보 보호담당자 <br />
                    성명 :유태봉<br />
                    직책 :개인정보책임자<br />
                    직급 :파트장<br />
                    연락처 :01076314746, ytb4748@gmail.com, <br />
                    ※ 개인정보 보호 담당부서로 연결됩니다.</p>
                    <p>▶ 개인정보보호 책임자<br />
                    성명 : 김운영<br />
                    직책 : 개인정보보호 책임자<br />
                    직급 : 팀장<br />
                    연락처 : 01093182316, woonyoengkim81@gmail.com</p>

                    <p>② 정보주체께서는 팀노바(주)(‘https://teamnovatech.co.kr/’이하 ‘회사) 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 팀노바(주)(‘https://teamnovatech.co.kr/’이하 ‘회사) 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</p>

                    <ol>
                    <li><p>개인정보 처리방침 변경<br />
                    ①이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.</p></li>

                    <li><p>개인정보의 안전성 확보 조치 "팀노바(주)"('회사')은(는) 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.</p></li>

                    <li><p>정기적인 자체 감사 실시<br />
                    개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.</p></li>

                    <li><p>개인정보 취급 직원의 최소화 및 교육<br />
                    개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.</p></li>

                    <li><p>내부관리계획의 수립 및 시행<br />
                    개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</p></li>

                    <li><p>해킹 등에 대비한 기술적 대책<br />
                    "팀노바(주)"('회사')은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.</p></li>

                    <li><p>개인정보의 암호화<br />
                    이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.</p></li>

                    <li><p>접속기록의 보관 및 위변조 방지<br />
                    개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.</p></li>

                    <li><p>개인정보에 대한 접근 제한<br />
                    개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</p></li>

                    <li><p>문서보안을 위한 잠금장치 사용<br />
                    개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.</p></li>

                    <li><p>비인가자에 대한 출입 통제<br />
                    개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.</p></li>
                    </ol>
                    </TabPanel>
                </TabView>
            </div>
        )
    }

}

export default service_terms;
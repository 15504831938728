// 데이터를 기반으로 워드 클라우드를 만들어주는 아이템 입니다.
import React from 'react';
//워드 클라우드 라이브러리
import Wordcloud from 'react-wordcloud';
//css
import './Word_Cloud.css';

class word_cloud extends React.Component{
    constructor(props) {
        super(props);
        this.state = { 
            chart_data : null,
            word_cloud : [{text : "데이터 로딩중..", value : 10}],
        };
    }

    static getDerivedStateFromProps(nextProps, prevState){
        
        if((nextProps.data !== prevState.word_cloud)&& nextProps.data !== null   && nextProps.data !== undefined) {
            // 기기의 해상도 측정을 통해, 워드 클라우드 max font size 를 정해줍니다.
            var size = {
                width: window.innerWidth || document.body.clientWidth,
                height: window.innerHeight || document.body.clientHeight 
            }
            let max_font_size = 0;

            if(size.width < 460){
                max_font_size = 20;
            }else if( size.width < 800 ){
                max_font_size = 30;
            }else if( size.width < 1025){
                max_font_size = 40;
            }else{
                max_font_size = 50;
            }
            var max_value = 0;
            max_value = nextProps.data["list"][0]["value"]*1;
            
            var one_per_by_max_val = max_font_size / max_value;

            //최대값
            max_value = -1;
            
            for(var i = 0 ; i< nextProps.data["list"].length;i++ ){
                // 너무 긴 텍스트 짜르기
                if(nextProps.data["list"][i]["text"].length > 7){
                    // nextProps.data["list"][i]["text"] = nextProps.data["list"][i]["text"].substr(0,7)+"..";
                }

                // 벨류값 조졍
                var val = (nextProps.data["list"][i]["value"]*1) / one_per_by_max_val;
                
                nextProps.data["list"][i]["value"] = val+"";
            }            
            
            return{
                word_cloud : nextProps.data["list"]
            };
        }else{
            return {
                word_cloud : [{text : "데이터 로딩중..", value : 10}]
            };
        }
    }

    shouldComponentUpdate(next_props,pre_props){ 
        // console.log("--------------------------------");
        // console.log("다음");
        // console.log(next_props);
        // console.log("이전");
        // console.log(pre_props);
        // 이전 워드 클라우드 데이터와 다음 워드 클라우드 데이터가 같으면 재 랜더링 하지 않음
        if(next_props["data"]){
            if(this.state.word_cloud.length === next_props["data"]["list"].length){
                // console.log("렌더링 x");
                return false;
            }else{
                // console.log(this.state.word_cloud);
                // console.log(next_props["data"]["list"]);
                // console.log("렌더링 o");
                return true;
            }
        }else{
            return false;
        }
    }


    render(){
        var size = {
            width: window.innerWidth || document.body.clientWidth,
            height: window.innerHeight || document.body.clientHeight 
        }
        let max_font_size = 0;
        let min_font_size = 0;
        if(size.width < 460){
            max_font_size = 30;
            min_font_size = 5;
        }else if( size.width < 800 ){
            max_font_size = 40;
            min_font_size = 10;
        }else if( size.width < 1025){
            max_font_size = 50;
            min_font_size = 15;
        }else{
            max_font_size = 60;
            min_font_size = 20;
        }
        return(
            <div id='word_cloud_main'>
                <Wordcloud 
                    words={this.state.word_cloud} 
                    options={{
                        colors: [
                            // 기존 컬러
                            // '#1f77b4',
                            // '#ff7f0e',
                            // '#2ca02c',
                            // '#d62728',
                            // '#9467bd',
                            // '#8c564b',

                            // 뉴컬러
                            '#e6194b', 
                            '#3cb44b', 
                            '#ffe119', 
                            '#4363d8', 
                            '#f58231', 
                            '#911eb4', 
                            '#46f0f0', 
                            '#f032e6', 
                            '#bcf60c', 
                            '#fabebe', 
                            '#008080', 
                            '#e6beff', 
                            '#9a6324', 
                            '#fffac8', 
                            '#800000', 
                            '#aaffc3', 
                            '#808000', 
                            '#ffd8b1', 
                            '#808080', 
                            '#ffffff', 
                        
                          ],         
                        enableTooltip: false,
                        deterministic: true, 
                        fontFamily: 'sans-serif',
                        fontSizes: [min_font_size, max_font_size],
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        padding: 5,
                        rotations: 2,
                        rotationAngles: [0, 0],
                        // scale: 'sqrt',
                        // spiral: 'archimedean',
                        scale : 'log',
                        spiral: 'rectangular',
                        transitionDuration: 0,
                    }}
                    />
            </div>
        );
    };
}

export default word_cloud;
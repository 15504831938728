import React from 'react';
import PropTypes from 'prop-types';
import './User_Button.css';
//로고
import Logo from '../../images/nova.png';
//배경
import {Card} from 'primereact/card';
//버튼 
import {Button} from 'primereact/button';
//인풋 필드
import {InputText} from 'primereact/inputtext';
//패스워드 
import {Password} from 'primereact/password';
// 링크이동
import {Link } from 'react-router-dom';
// 에러 메세지
import {Messages} from 'primereact/messages';
//다이얼로그
import {Dialog} from 'primereact/dialog';
//api 통신용
import * as Api from '../../utils/Api';
//유틸 함수
import {ck_email} from '../../utils/Func';
//언어관리
import * as lang_pack from '../../utils/language_pack'; 
//primereact 관련 css 
import 'primereact/resources/themes/nova-dark/theme.css'; 
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

//라우터
import {withRouter} from 'react-router-dom';

class user_btn extends React.Component{
    //저장하지 않는 스테이터스들
    constructor(props) {
        super(props);
        this.state = {
            e_address: "",
            pass_word: "",
            sign_email: "",
            sign_name:"",
            sign_pw:"",
            sign_pw2:"",
            sign_code:"",
            visible : false,
            // 로그인데이터 저장해두기, 업데이트 warning msg 를 피하기 위해서 여기저장했다가 이동합니다.
            login_idx :null,
            login_email :"",
            login_name :"",
            login_session_id :"",
            login_profile_img :"",
            login_default_explain :"",
            login_grade : "",
            // 패스워드 찾기
            show_find_pw : false,
            find_pw_email : "",
            find_pw_code : "",
            find_pw_change_pw1 : "",
            find_pw_change_pw2 : "",
            //비밀번호 찾기 다이얼로그의 화면을 바꿔주는 변수
            find_pw_view_number : 0,
            find_pw_view_0 : false,
            find_pw_view_1 : false,
            find_pw_view_2 : false,
            //언어 데이터
            lang_data : {},
            find_pw_err_text : "",
       };
    }

    // state 받기
    static propType = {
        insert_login : PropTypes.bool,
        is_login_click : PropTypes.bool,
        is_login_start: PropTypes.bool,
        is_sign_click : PropTypes.bool,
        is_login : PropTypes.bool,

        //회원가임
        idx : PropTypes.int,
        email :PropTypes.string,   
        name : PropTypes.string,
        sessionId : PropTypes.string,

        // 액션 핸들러
        user_login : PropTypes.func,
        user_sign : PropTypes.func,
        user_login_start : PropTypes.func,
        user_login_end : PropTypes.func,
        user_login_data_input : PropTypes.func,

        //언어
        lang : PropTypes.string
    }
    /************프로프 업데이트****************** */
    componentWillMount(){
        //console.log(this.props.lang);
        //언어 관리
        let lang_data;
        if(this.props.lang ===lang_pack.KOREAN){
            lang_data = lang_pack.lang_sign(lang_pack.KOREAN);
        }else if(this.props.lang ==="EN"){
            lang_data = lang_pack.lang_sign(lang_pack.ENGLISH);
        }
        this.setState({
            lang_data : lang_data
        })
    }
    componentWillReceiveProps(next_props) {
        //console.log(next_props);
        //언어 관리
        let lang_data;
        if(next_props.lang ===lang_pack.KOREAN){
            lang_data = lang_pack.lang_sign(lang_pack.KOREAN);
        }else if(next_props.lang ==="EN"){
            lang_data = lang_pack.lang_sign(lang_pack.ENGLISH);
        }
        this.setState({
            lang_data : lang_data
        })
      }
    /***********로그인 데이터 및 로그인 자체 체커*********** */
    componentDidUpdate(){
        var stater = this.state;
        //로그인데이터 전부 들어왔는지 체크
        if(stater.login_idx && stater.login_idx !== this.props.idx){
            //로컬스토리지에 email,session id 저장
            window.sessionStorage.setItem(
                "user_info",JSON.stringify({
                    idx : stater.login_idx
                })
            );
        }
        //로그인에 성공하게 되면 홈으로 돌려버린
        if(this.props.is_login === true){
            this.props.history.push('/home');
        }

        
    }
    //***************************메세지**************************************** */
    showError(text) {
        this.messages.show({ life: 5000,severity: 'error', summary: text, detail: '' });
    }
    // *********************다이얼로그*************************************
    //다이얼로그 숨기기
    onHide() {
        this.setState({visible: false});
    }
    // ******************로그인 관련 ***************************

    //로그인 메뉴 클릭
    user_login_click = () =>{
        if(!this.state.e_address || !this.state.pass_word) return;
        let current = this;
        // 이 펑션이 불리게 되면, is_login_click 이 변화하게 되면서 다이얼로그가 열립니다.
        // 기본적으로 유저가 로그인 메뉴를 클릭하게 되면 로그인 시작을 했는지 판단하는 is_login_start = false로 진행됩니다
        let data = Api.LOGIN("LOGIN_IN",this.state.e_address,this.state.pass_word);


        //showError 코드에 텍스트를 넣기 위해서 리턴값을 int 형으로 받습니다.
        //0 - 성공
        //1 - 아이디 패스워드 불일치
        //2 - 데이터 통신은 됫으나 필요 데이터가 들어오지 않음
        Api.axios_instance.post(`${process.env.REACT_APP_MEMBER_URL}`,data)
        .then(function(response){
            //성공 코드만 체크해서 진행하기
            var retJson = response.data;            
            if(retJson["retCode"] === 0){
                //console.log(retJson['retBody']);
                var idx = retJson["retBody"]["idx"];    //회원 인덱스 번호
                var email = retJson["retBody"]["e_address1"] + "@" + retJson["retBody"]["e_address2"];      //분할된 이메일을 하나로 합쳐줍니다.
                var name = retJson["retBody"]["name"];  //이름
                var session_id = retJson["retBody"]["sessionId"];       //서버 디비에 저장해둔 세션 아이디값
                var profile_img = process.env.REACT_APP_BASE_URL+retJson["retBody"]["profile_img"];   //프로필 이미지 주소
                var default_explain = retJson["retBody"]["default_explain"]; //기본 프로필 설명  
                var grade = retJson["retBody"]["grade"];               //유저 등급 
                //성공적으로 데이터 반환
                current.setState({
                    login_idx : idx,
                    login_email : email,
                    login_name : name,
                    login_session_id : session_id,
                    login_profile_img : profile_img,
                    login_default_explain : default_explain,
                    login_grade : grade
                });
                current.props.user_login_data_input(
                    idx,
                    email,
                    name,
                    session_id,
                    profile_img,
                    default_explain,
                    grade
                ) 
            }else if(retJson["retCode"] === 404){
                current.showError(current.state.lang_data.get("signin").get("err_404"));
            }
            else if (retJson["retCode"] === 1){
                //단순오류
                current.showError(current.state.lang_data.get("signin").get("err_undefined"));
            }
        }).catch(function(error){
            // console.log(error);
            //에러
            current.showError(current.state.lang_data.get("signin").get("err_undefined"));
        })
    }
    //유저가 회원정보 가입을 시작하는 함수!
   async user_sign_up_click(){
       let lang = this.state.lang_data.get("signup");
        //이름
        if(!this.state.sign_name){
            this.showError(lang.get("err_empty_name"));
            return;
        }
        //이메일
        if(!this.state.sign_email){
            this.showError(lang.get("err_empty_email"));
            return;
        }
        //이메일 유효성 검사를 진행합니다.
        if(!ck_email(this.state.sign_email)){
            this.showError(lang.get("err_email_type"));
            return;
        }
        
        //패스워드 특수문자로 시작하는지 판단. --> 특수문자로 시작하게되면 사용 못하게 합니다.
        if(this.state.sign_pw.startsWith("!" ||"@" || "#" || "$" || "%" || "^" || "&" || "*" || "(" || ")" || "<" || ">" || "/" || "?" || "-" || "_" || "=" ||"+")){
            this.showError(lang.get("err_wrong_password"));
            return;
        } 
        //패스워드
        if(!this.state.sign_pw){
            this.showError(lang.get("err_empty_password"));
            return;
        }
        //패스워드 동일한지 체크
        if(this.state.sign_pw !== this.state.sign_pw2 ){
            this.showError(lang.get("err_password_chk"));
            return;
        }
        //초대코드
        if(!this.state.sign_code){
            this.showError(lang.get("err_empty_invite_code"));
            return;
        }

        //통신 진행
        //에러코드 
        //500 -> 초대코드 에러
        //424 -> 아이디 중복
        //409 -> 마지막 회원가입 디비 입력 실패
        let data = Api.SIGN_UP(this.state.sign_name,this.state.sign_email,this.state.sign_pw,this.state.sign_code);
        let response = await Api.axios_instance.post(`${process.env.REACT_APP_MEMBER_URL}`,data)
        .then(function(response){
            //성공 코드만 체크해서 진행하기
            var retJson = response.data;
            if(retJson["retCode"] === 0){
                //성공적으로 데이터 반환
                //페이지 로그인창으로 이동
                // this.props.history.push('/signin');
                return retJson;
            }else if(retJson["retCode"] === 500){
                //초대코드 에러
                return 500;
            }else if(retJson["retCode"] === 424){
                //아이디중복
                return 424;
            }else if(retJson["retCode"] === 409){
                //디비 입력 실패
                return 409
            }else{
                //알수없는 이유
                return 1;
            }
        }).catch(function(error){
            //에러
            console.log(error);
            return 1;
        })
        //에러코드별로 에러 메세지 띄워주기
        switch(response){
            case 500:
                this.showError(lang.get("err_500"));
                return;
            case 424:
                this.showError(lang.get("err_424"));
                return;
            case 409:
                this.showError(lang.get("err_409"));
                return;
            case 1:
                this.showError(lang.get("err_1"));
                return;
            default : break;
        }

        //회원가입 성공 화면 이나 안내 처리하기 
        //안보임 처리 했던 다이얼로그를 보여준다.
        this.setState({visible: true});
        
        
    }
    // 비밀번호 찾기
    //비밀번호 찾기 다이얼로그 
    show_find_pw= () => {
        this.setState({
            find_pw_view_0 : true,
            find_pw_view_1 : false,
            find_pw_view_2 : false,
            find_pw_err_text : "",
        })
    }
    //비밀번호 찾기 통신
    find_pw_get_code = () => {
        let current = this;
        let email = this.state.find_pw_email;
        // 이메일 형식 체크
        if(!email.includes("@")){
            this.setState({
                find_pw_err_text : "올바른 이메일 형식을 사용해주세요"
            })
            return;
        }
        let e_address1 = email.split("@")[0];
        let e_address2 = email.split("@")[1];

        let data = Api.MEMBER_FIND_PW(e_address1,e_address2);

        Api.axios_instance.post(`${process.env.REACT_APP_MEMBER_URL}`,data)
        .then(function(response){
            //성공 코드만 체크해서 진행하기
            var retJson = response.data;
            // console.log(retJson);
            if(retJson["retCode"]===0){
                current.setState({
                    find_pw_view_number : 1,
                    find_pw_view_0 : false,
                    find_pw_view_1 : true,
                    find_pw_view_2 : false,
                    find_pw_err_text : ""                    
                })
            }else if(retJson["retCode"]===404){
                current.setState({
                    find_pw_err_text : "존재하지 않는 이메일입니다."                    
                })
            }
        }).catch(function(error){
            //에러
            console.log(error);
            return 1;
        })

    }

    // 비밀번호 찾기 코드 인증
    find_pw_code_check = () => {
        let current = this;
        let code = this.state.find_pw_code;
        let e_address1 = this.state.find_pw_email.split("@")[0];
        let e_address2 = this.state.find_pw_email.split("@")[1];

        let data = Api.MEMBER_FIND_PW_CHECK(code,e_address1,e_address2);
        Api.axios_instance.post(`${process.env.REACT_APP_MEMBER_URL}`,data)
        .then(function(response){
            //성공 코드만 체크해서 진행하기
            var retJson = response.data;
            if(retJson["retCode"]===0){
                current.setState({
                    find_pw_view_number : 2,
                    find_pw_view_0 : false,
                    find_pw_view_1 : false,
                    find_pw_view_2 : true,
                    find_pw_err_text : ""                  
                })
            }else{
                current.setState({
                    find_pw_err_text : "인증코드 불일치"                  
                })
            }
        }).catch(function(error){
            //에러 
            console.log(error);
            return 1;
        })
    }
    //비밀번호 바꾸기
    find_pw_change = () => {
        let current = this;
        let code = this.state.find_pw_code;
        let e_address1 = this.state.find_pw_email.split("@")[0];
        let e_address2 = this.state.find_pw_email.split("@")[1];
        let pass_1     = this.state.find_pw_change_pw1;
        let pass_2     = this.state.find_pw_change_pw2;

        if(pass_1 !== pass_2){
            current.setState({
                find_pw_err_text : "패스워드가 일치하지 않습니다."                  
            })
            return;
        }

        let data = Api.MEMBER_FIND_PW_CHANGE(code,e_address1,e_address2,pass_1);

        Api.axios_instance.post(`${process.env.REACT_APP_MEMBER_URL}`,data)
        .then(function(response){
            //성공 코드만 체크해서 진행하기
            var retJson = response.data;
            if(retJson["retCode"]===0){
                current.setState({
                    find_pw_view_number : 0,
                    find_pw_view_0 : false,
                    find_pw_view_1 : false,
                    find_pw_view_2 : false,
                    find_pw_err_text : ""                  
                })
            }else{
                current.setState({
                    find_pw_err_text : "비밀번호 변경에 실패 했습니다."                  
                })
            }
        }).catch(function(error){
            //에러
            console.log(error);
            return 1;
        })


    } 
    
   
    // 로그인 했는지 안했는지에 따라서 ui를 다르게 뿌려주게 됩니다.
    set_isLogin_ui = () =>{
         // 로그인창
         if(this.props.insert_login === true){
             //로그인 언어 팩
             let lang = this.state.lang_data.get("signin");
            return (
                <div>
                    <div id='main_div' >
                        <div id='signin_div'>
                        
                            <img alt='teamnova_logo' src={Logo}/>
                            <h3> </h3>
                            <Card>
                                <p className='signin_title'>{lang.get("email_addr")}</p>
                                <InputText className='signin_input_filed' value={this.state.value} onChange={(e) => this.setState({e_address: e.target.value})} />
                                <p className='signin_title'>{lang.get("password")}</p>
                                <span id='signin_forgot_pw' onClick={() => this.show_find_pw()}><p style={{color : "#007ad9", cursor : "pointer"}}>{lang.get("forgot")}</p></span>
                                <Password feedback={false} className='signin_input_filed' value={this.state.value} onChange={(e) => this.setState({pass_word: e.target.value})}/>
                                <br />
                                <br />
                                <Button id='signin_btn' label="Sign in" className="p-button-success" onClick={() => {this.user_login_click()}}/>
                                <br />
                                <Messages ref={(el) => this.messages = el}></Messages>
                            </Card>
                            <div id='signin_to_signup'>
                                <p>{lang.get("signup_exp")}  <Link to ="signup" className="Nav-Item-Link">{lang.get("signup")}</Link></p>
                            </div>
                        </div>
                    </div>
                    {/* 다이얼로그 컨트롤 */}
                    <Dialog 
                        header="비밀번호 찾기" 
                        visible={this.state.find_pw_view_0} 
                        style={{transform:"translateX(-50%) translateY(-50%)",width : "100%",maxWidth : "350px"}} modal={true} onHide={() => this.setState({find_pw_view_0: false})}>
                        <div>
                            <div>{"가입시 사용했던 이메일로 인증번호가 발송됩니다."}</div>
                            <br />
                            <InputText id="find_pw_email" className='signin_input_filed' placeholder = {"example@teamnovatech.co.kr"} style={{width:"100%",maxWidth:"250px"}}
                            onChange={(e) => this.setState({find_pw_email: e.target.value})}
                            />
                            <span><Button label="발송" onClick={()=>this.find_pw_get_code()}/></span>
                            <div style={{color : "red"}}>{this.state.find_pw_err_text}</div>
                        </div>
                    </Dialog>
                    <Dialog 
                        header="비밀번호 찾기" 
                        visible={this.state.find_pw_view_1} 
                        style={{transform:"translateX(-50%) translateY(-50%)",width : "100%",maxWidth : "350px"}} modal={true} onHide={() => this.setState({find_pw_view_1: false})}>
                        <div>
                            <div>{"해당 이메일로 받은 인증코드 입력해주세요"}</div>
                                <br />
                                <InputText className='signin_input_filed' placeholder = {"숫자 5자리"} style={{width:"100%",maxWidth:"250px"}}
                                value={this.state.value} onChange={(e) => this.setState({find_pw_code: e.target.value})}
                                />
                            <span><Button label="인증" onClick={()=>this.find_pw_code_check()}/></span>
                            <div style={{color : "red"}}>{this.state.find_pw_err_text}</div>
                        </div>
                    </Dialog>
                    <Dialog 
                        header="비밀번호 찾기" 
                        visible={this.state.find_pw_view_2} 
                        style={{transform:"translateX(-50%) translateY(-50%)",width : "100%",maxWidth : "350px"}} modal={true} onHide={() => this.setState({find_pw_view_2: false})}>
                        <div>
                            <div>{"변경할 비밀번호를 입력해주세요"}</div>
                            <Password feedback={false} style={{width:"100%",maxWidth:"250px"}}
                            value={this.state.value} onChange={(e) => this.setState({find_pw_change_pw1: e.target.value})}
                            />
                            <div>{"비밀번호 확인"}</div>
                            <Password feedback={false} style={{width:"100%",maxWidth:"250px"}}
                            value={this.state.value} onChange={(e) => this.setState({find_pw_change_pw2: e.target.value})}
                            />
                            <Button label="변경" style={{width:"100%",maxWidth:"250px"}} onClick={()=> this.find_pw_change()} />
                            <div style={{color : "red"}}>{this.state.find_pw_err_text}</div>
                        </div>
                    </Dialog>
                    
                    
                </div>
            )
        }else{
            // 회원가입 창
            //회원가입 언어 팩
            let lang = this.state.lang_data.get("signup");
            return (
                <div id='main_div'>
                    <Dialog className="sign_dialog" showHeader={false} visible={this.state.visible} modal={true} onHide={this.onHide}>
                        <div className="suc_div">
                            <img alt = "teamnova_logo" src={Logo} width={"100%"}/>
                        </div>

                        <div className="suc_div">
                            <h1>{lang.get("suc_title")}</h1>
                            <p>{lang.get("suc_sub_title")}</p>
                            <span><Link to ="home" className="Nav-Item-Link">{lang.get("suc_btn_home")}</Link></span>
                            <span><Link to ="signin" className="Nav-Item-Link">{lang.get("suc_btn_signin")}</Link></span>
                        </div>
                    </Dialog>

                    <div id='signup_div'>
                        <div id="sign_main_title">
                            <h1 id="sign_title">{lang.get("title")}</h1>
                            <p id="sign_subtitle">{lang.get("sub_title")}</p>
                        </div>

                        <p className='input_title'><i className="pi pi-user" /> {lang.get("user_name")}</p>
                        <InputText  className='input_filed' value={this.state.value} onChange={(e) => this.setState({sign_name: e.target.value})}/>
                        <p className='input_sub'>{lang.get("user_name_exp")}</p>

                        <p className='input_title'><i className="pi pi-envelope" />  {lang.get("user_email")}</p>
                        <InputText  className='input_filed' value={this.state.value} onChange={(e) => this.setState({sign_email: e.target.value})}/>
                        <p className='input_sub'>{lang.get("user_email_exp")}</p>

                        <p className='input_title'><i className="pi pi-lock" />  {lang.get("password")}</p>
                        <Password  className='input_filed' value={this.state.value} onChange={(e) => this.setState({sign_pw: e.target.value})}/>
                        <p className='input_sub'>{lang.get("password_exp")}</p>

                        <p className='input_title'><i className="pi pi-lock" />  {lang.get("password_chk")}</p>
                        <Password  className='input_filed' value={this.state.value} onChange={(e) => this.setState({sign_pw2: e.target.value})}/>
                        <p className='input_sub'>{lang.get("password_chk_exp")}</p>

                        <p className='input_title'><i className="pi pi-ban" />  {lang.get("invite_code")}</p>
                        <InputText  className='input_filed' value={this.state.value} onChange={(e) => this.setState({sign_code: e.target.value})}/>
                        <p className='input_sub'>{lang.get("invite_code_exp")}</p>
                        <br />

                        <Button label={lang.get("sign_btn")} className="p-button-success" onClick={(e) => {this.user_sign_up_click()}}/>
                        <Messages ref={(el) => this.messages = el}></Messages>
                    </div>  
                    {/* 개인정보 및 이용약관 동의 */}
                    <div id='signup_agree'>
                        <ul>
                            <li>
                                <Card>
                                    <p className='input_title'>{lang.get("agree_title")}</p>
                                    <hr />
                                    <p id="signup_long_text">{lang.get("agree_sub_title")}</p>
                                    <br />
                                    <div id='signup_agree_link_div'>
                                        <span><Link to ="/serviceterms" className="Nav-Item-Link">{lang.get("agree_terms")}</Link></span>
                                        <span><Link to ="/serviceterms" className="Nav-Item-Link">{lang.get("agree_personal")}</Link></span>
                                    </div>
                                </Card>
                            </li>
                            <li>
                                <Card>
                                    <p className='input_title'>{lang.get("code_title")}</p>
                                    <hr />
                                    <p id="signup_long_text">{lang.get("code_sub_title")}</p>
                                    <a href="https://cafe.naver.com/otodev" target="_blank" rel="noopener noreferrer">Private cafe</a>
                                </Card>
                            </li>
                        </ul>
                        </div>
                    </div>
                )
            }
    }   

    //*********************회원가입 관련 ***************************
    //유저 회원가입 클릭 함수
    user_sign_click(){
        // 이 펑션이 불리게 되면, is_sign_click 이 변화하게 되면서 다이얼로그가 열립니다.
        this.props.user_sign();
    }


    
    // 최종 렌더링
    render(){
        return(
            <div id="User_ul">
                {/* 다이얼로그창 */}
                {/* {this.set_dialog_ui()} */}
                {/* 여기에서는 회원 계정 관련 리스트를 관리합니다. */}
                {this.set_isLogin_ui()}
                
            </div>
        )
    }
}


export default withRouter(user_btn);
import React from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as userAction from '../store/modules/User';

class GlobalLang extends React.Component{

    // 마운트 된 다음 페이지 지동
    componentDidMount(){
        //들어온 주소값을 적음 /kr or /en
        let path = this.props.match.path;

        //주소값을 비교해서 해당 언어로 변경 처리 한뒤에, 홈 화면으로 보내버린다.
        if(path.includes("kr")){
            //한국어 페이지
            this.props.user_lang_ko();
        }else{
            //영어 페이지
            this.props.user_lang_en();
        }
        
        this.props.history.push('/');
    }

    render(){
        return(
            <div>
            
            </div>
        )
    }
}

// 스토어 연결
let mapStateToProps = ( state ) => {
	return {
        lang : state.user.lang,
	}
};

const map_dispatch_top_props = (dispatch) => ({
    user_lang_ko:() => dispatch(userAction.user_lang_ko()),
    user_lang_en:() => dispatch(userAction.user_lang_en()),
});

export default withRouter(connect(mapStateToProps,map_dispatch_top_props)(GlobalLang));
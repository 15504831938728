import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Link,withRouter} from 'react-router-dom';
import {InputText} from "primereact/inputtext";
// import {Button} from "primereact/button";
import {Growl} from 'primereact/growl';
import * as userAction from '../store/modules/User';
//언어 팩 참고
import * as lang_pack from '../utils/language_pack';

import './TopNavi.css';
//팀노바 로고
import Logo from '../images/nova.png';
//모바일 전용 메뉴 컨테이너
import MobileMenu from '../containers/M_Menu_Container';
class TopNavi extends Component{
    //이 페이지에서만 사용하는 변수
    //추가정보를 입력해야하니?
    add_info_alram = false;

    constructor(props){
        super(props);
        this.state ={
            lang_data : lang_pack.lang_menu(props.lang),  //언어 팩이 들어있는 state 입니다.
            info_add_chk : false,       //기본정보 입력을 위한 메세지창 한번만 띄우기
            search_values : "",         //검색어 입력 값
            grade : "0",           //회원 등급
            user_dropdown_menu : "none",    //유저 드롭다운 메뉴 컨트롤
            search_dropdown_menu : "none",  //검색 드롭다운 메뉴 컨트롤

        };
    }
    // state 받기
    static propType = {
        is_login : PropTypes.bool,
        profile_img : PropTypes.string
    }

    componentWillReceiveProps(nextProps){
        //언어팩 관리 -> 언어 변경 처리 props.lang 변경 차이가 나면 진행함 기본은 ko 입니다.
        //바뀌는 이벤트를 감지해서 언어를 바꿔줍니다.
        //받아오는 메뉴 관리
        //menu :{home : v, tech: v}
        //search : {search : v}
        //my_menu : {write: v, my : v, logout: v}
        let lang_data;
        switch(nextProps.lang){
            case lang_pack.KOREAN:
                //언어 팩 받아오기 map 형태
                lang_data = lang_pack.lang_menu(lang_pack.KOREAN);
                break;
            case lang_pack.ENGLISH:
                lang_data = lang_pack.lang_menu(lang_pack.ENGLISH); 
                break; 
            default:break;
        }
        this.setState({
            lang_data : lang_data,
            //드롭다운 메뉴 아이템 텍스트 바꿔주기
            grade : nextProps.grade
        })        
    }
    //생명주기
    componentDidUpdate(){
        if(this.props.is_login &&(this.props.profile_img === "/_nova/_img/_userprofile/default.png" || !this.props.default_explain)){
            //필요한 추가 정보가 없을때 들어오게됩니다.
            if(!this.add_info_alram){
                //한번만 들어오게 합니다.
                this.add_info_alram = true;
                this.info_add_chk();
            }
        }

    }
    // 페이지 이동
    navigate_to_write_page=()=>{
        this.setState({
            user_dropdown_menu : "none"
        },()=>{
            document.removeEventListener('click', this.user_menu_drop_down_end);
        })
        // 현제 페이지가 쓰기 페이지 일 때 예외처리
        const now_path = window.location.pathname;
        if(now_path !== '/write/new_board/0'){
            this.props.history.push('/write/new_board/0');
            this.props.user_write_start();
        }
    }
    //내 정보 페이지
    navigate_to_my_page=()=>{
        this.setState({
            user_dropdown_menu : "none"
        },()=>{
            document.removeEventListener('click', this.user_menu_drop_down_end);
        })
        // 현제 페이지가 쓰기 페이지 일 때 예외처리
        const now_path = window.location.pathname;
        if(now_path !== '/mypage'){
            this.props.history.push('/mypage');
        }
    }
    //*********정보전달********** */
    //기본정보를 입력하라고 재촉합니다!
    info_add_chk=()=> {
        
        const summary = <span><strong>{this.state.lang_data.get("a_info").get("summary")}</strong></span>;
        const detail = <Link to="mypage" >{this.state.lang_data.get("a_info").get("detail")}</Link> 
        this.growl.show({severity: 'warn', summary: summary, detail: detail,sticky: true});
    }
    
    
    
    // *************로그인 관련*********************
    // 로그아웃
    navigate_logout=()=>{
        this.setState({
            user_dropdown_menu : "none"
        },()=>{
            document.removeEventListener('click', this.user_menu_drop_down_end);
        })
        //로그아웃 진행
        this.props.user_logout();
        //로컬스토리지 삭제
        localStorage.removeItem("user_info");
    }
    // 유저 메뉴 드롭다운
    user_menu_drop_down = () =>{
        this.setState({
            user_dropdown_menu : "show"
        },()=> {
            document.addEventListener("click",this.user_menu_drop_down_end);
        })
    }
    // 드롭다운 끝
    user_menu_drop_down_end =() =>{
        this.setState({
            user_dropdown_menu : "none"
        },()=> {
            document.removeEventListener("click",this.user_menu_drop_down_end);
        })
    }
    // 검색어 입력을 시작하면 어떤 검색을 할지 보여주는 메서드
    search_input_start=()=>{
        this.setState({
            search_dropdown_menu : "show"
        }, () => {
            document.addEventListener("click",this.search_input_end);
        })
    }

    search_input_end = () =>{
        this.setState({
            search_dropdown_menu : "none"
        },() => {
            document.removeEventListener('click', this.search_input_end);
        });
    }
    //검색
    search_start=(type)=>{
        //console.log("들옴"+type);
        // console.log(this.state.search_values);
        //상단 검색 입니다.
        //태그를 기준으로 검색을 진행합니다.
        //검색어 안쓴거 예외처리
        if(!this.state.search_values){
            this.setState({
                search_dropdown_menu : "none"
            })
            return;
        }
        this.props.history.push('/tech/'+type+"/"+this.state.search_values);

    }
    //로그인 했는지 안 했는지에 따라서 바뀌는 ui
    login_ui_changer=()=>{
        
        if(this.props.is_login === true){
            // 로그인 했을 때
            return(
                <ul className="Nav_login_items">
                    <li className="Nav-Item" id="nav_search" >
                        <div className="p-col-12 p-md-4" >
                            <div className="p-inputgroup">
                                <InputText placeholder="Search teamnova" onClick={(e)=> this.search_input_start()} onChange={(e)=> this.setState({search_values : e.target.value})}/>
                            </div>
                        </div>    
                        <div className={"search_dropdown_menu_"+this.state.search_dropdown_menu}>
                                <div onClick={()=>this.search_start("tag")}><h3>{"Tag: "+this.state.search_values}</h3><i className="pi pi-search"></i></div>
                                <div onClick={()=>this.search_start("name")}><h3>{"Name: "+this.state.search_values}</h3><i className="pi pi-search"></i></div>
                        </div>
                    </li>
                    <li>
                        <div className='user_login_ui' onClick={()=>this.user_menu_drop_down()}>
                            <img src={this.props.profile_img} alt = "user_img"/>
                            <i className="pi pi-angle-down"></i>
                        </div>
                        <div className={"user_dropdown_menu_"+this.state.user_dropdown_menu}>
                            <div onClick={()=>this.navigate_to_write_page()}>{this.state.lang_data.get("my_menu").get("write")}</div>
                            <div onClick={()=>this.navigate_to_my_page()}>{this.state.lang_data.get("my_menu").get("my")}</div>
                            <div onClick={()=>this.navigate_logout()}>{this.state.lang_data.get("my_menu").get("signout")}</div>
                        </div>
                    </li>
                </ul>
            )
        }else{
            // 로그인 안 했을 떄
            return(
                <nav className="Nav-Item" id="nav_user">
                        <ul>
                            {/* 검색 */}
                            <li className="Nav-Item" id="nav_search" >
                                <div className="p-col-12 p-md-4" >
                                    <div className="p-inputgroup">
                                        <InputText placeholder="Search teamnova" onClick={(e)=> this.search_input_start()} onChange={(e)=> this.setState({search_values : e.target.value})}/>
                                    </div>
                                </div>    
                                <div className={"search_dropdown_menu_"+this.state.search_dropdown_menu}>
                                        <div onClick={()=>this.search_start("tag")}><h3>{"Tag: "+this.state.search_values}</h3><i className="pi pi-search"></i></div>
                                        <div onClick={()=>this.search_start("name")}><h3>{"Name: "+this.state.search_values}</h3><i className="pi pi-search"></i></div>
                                </div>
                            </li>
                            {/* 로그인 */}
                            <li ><Link to ="/signin" id="link_signin">{this.state.lang_data.get("my_menu").get("signin")}</Link></li>
                            {/* 회원가입*/}
                            <li ><span id='signup_border'><Link to ="/signup" id="link_signup">{this.state.lang_data.get("my_menu").get("signup")}</Link></span></li>
                        </ul>
                </nav>
            );
        }
    }
    //관리자인지 아닌지 파악해서 관리자 메뉴를 보여줄지 말지 결정
    get_admin_ui=(grade)=>{
        if(grade=== "10"){
            return(
                <li ><Link to ="/admin" className="Nav-Item-Link">{this.state.lang_data.get("menu").get("admin")}</Link></li>
            )
        }
    }
    render(){
        // 로그인 했을 때 프로필 클릭하면 내려오는 아이템 구성
        return(
            //라우터 설정을 진행합니다.
            <div id= "Nav">  
                <Growl ref={(el) => this.growl = el} />
                {/* 
                네비게이션 전체를 감싸는 div 입니다. 
                해당 네비게이션은 항상 보이도록 구현합니다. 
                */}
                {/* 모바일 대응 */}
                <div id="Mobile_Menu">
                    <MobileMenu lang={this.state.lang_data} user_lang_ko={this.props.user_lang_ko} user_lang_en={this.props.user_lang_en}/>
                </div>
                {/* 팀노바 로고 */}
                <Link to="/home" className="Nav-Item" id="Nav-Logo"><img src={Logo} alt="Logo"></img></Link>
                
                {/* 네비게이션에 들어가는 각 아이템들을  관리합니다.*/}
                <nav className="Nav-Item" id="Nav-Menu"> 
                    <ul>
                        {/* 여기에서는 메뉴 리스트를 관리합니다. */}
                        {/* 기본 경로인 홈 입니다. */}
                        <li ><Link to ="/home" className="Nav-Item-Link">{this.state.lang_data.get("menu").get("home")}</Link></li>
                        {/* 기술 내용 콘텐츠 전용 */}
                        <li ><Link to ="/tech/tag/default" className="Nav-Item-Link">{this.state.lang_data.get("menu").get("tech")}</Link></li>
                        {/* 트렌드 */}
                        <li ><Link to ="/trend" className="Nav-Item-Link">{this.state.lang_data.get("menu").get("trend")}</Link></li>
                        {/* 콘택트어스 */}
                        <li ><Link to ="/contectus" className="Nav-Item-Link">{this.state.lang_data.get("menu").get("contect")}</Link></li>
                        {/* 어드민 */}
                        {this.get_admin_ui(this.state.grade)}
                    </ul>
                </nav>
                {this.login_ui_changer()}
                <nav className="Nav-Item" id="nav_flag_con">
                    <button type="submit" onClick={this.props.user_lang_ko} ><img className="btn-img" alt="button_img" src={process.env.REACT_APP_BASE_URL+"/_nova/_img/_flags/South-Korea-flag.png"}/></button>
                    <button type="submit" onClick={this.props.user_lang_en} ><img className="btn-img" alt="button_img" src={process.env.REACT_APP_BASE_URL+"/_nova/_img/_flags/USA-flag.png"}/></button>
                </nav>
                {/* 라우터를 사용해서 각각의 페이지로 이동을 합니다. */}
                {/* 페이지들은 pages/ 에 포함되어 있습니다. */}
                
            </div>
            
            
        );
    }
}
// 스토어 연결
let mapStateToProps = ( state ) => {
	return {
        is_login : state.user.is_login,
        profile_img : state.user.profile_img,
        default_explain : state.user.default_explain,
        lang : state.user.lang,
        grade : state.user.grade,
	}
};

const map_dispatch_top_props = (dispatch) => ({
    user_write_start: () => dispatch(userAction.user_write_start()),
    user_write_end: () => dispatch(userAction.user_write_end()),
    user_logout:() => dispatch(userAction.user_logout()),
    user_lang_ko:() => dispatch(userAction.user_lang_ko()),
    user_lang_en:() => dispatch(userAction.user_lang_en()),

});




export default withRouter(connect(mapStateToProps,map_dispatch_top_props)(TopNavi));
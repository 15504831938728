// 이 컴포턴트는, 사용자가 올린 게시글을 데이터 베이스에서 긁어와서 화면에 뿌려주는 역활을 합니다.
// 화면에 뿌려 줄 때는 2가지 방식이 있습니다.
// 1. 최근 5개 뿌려주기
// 2. 페이징하는 형식으로 뿌려주기
// 구분은 생성자로 받습니다. 

import React from 'react';
//css
import './List_Blog_Item.css';
// import {Button} from 'primereact/button';
//페이지 이동
import {Link,withRouter } from 'react-router-dom';


import 'primereact/resources/themes/nova-dark/theme.css'; 
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import {lang_board_lv, KOREAN} from '../../utils/language_pack';

class list_item extends React.Component{
    //type을 네임으로 생성 타입을 받습니다.
    //1. recently -> 최근 5개
    //2. full -> 전체 페이징

    //기본 컨스트럭터
    constructor(props) {
        super(props);
        this.state = {
            List_item : [],
            count : 0,  //마지막 데이터 인덱스
            page : 0,  //페이징시 필요한 페이지 인덱스
            pagenation_idx : 0,
        };
    }

    
    //프로퍼티 업데이트를 진행할 떄!
    static getDerivedStateFromProps(data){
        //console.log(data);
         //데이터 파싱 및 널 체크
         if(data["data"] === null) {
             return{
                count : 0,
                page : 0
             }
         }
         //블로그형태의 데이터
         let blog_data = data["data"];
         let list = blog_data["list"];      //리스트 데이터만 파싱
         if(data["type"] ==='recently'){
             return{
                 count : 3,
                 List_item : list,
                 pagenation_idx : -1
             }
         }else{  
             return{
                 count : 5,
                 List_item : list,
                 pagenation_idx : data["pagenation_idx"]
             }
         }

    }

    shouldComponentUpdate(next_props,pre_props){
        // 데이터가 비어있을 경우 렌더링 안함
        if(!next_props["data"]){
            return false;
        }

        return true;
    }
  
    render(){
        // console.log(this.state.List_item);
        //type_get_data() 에서 가져온 데이터를 기반으로 화면 ui를 구성합니다
        return(
            <div>
                <div id="card_group">
                    {/* 카드 형태의 데이터를 반환합니다. */}
                    <BoardItemUi List_item = {this.state.List_item} count={this.state.count} lang={this.props.lang}/>
                </div>
            </div>
            
        );
    } 

}

export default withRouter(list_item);

// 리스트 아이템 서브 클래스

class BoardItemUi extends React.Component{


     //홈화면 보드 아이템 뿌리기
     get_board_ui(){
        if(!this.props.List_item){
            return(
                <div className="list_no_result">
                    <img alt="no_data_img" src ={process.env.REACT_APP_BASE_URL+"/_nova/_img/_boardimg/default/no_data.png"}/>
                </div>
            )
        }else{
            let res_data = this.props.List_item.map( (item) => {
                
                //태그를 리스트형태로 만들어 줍니다.
                //각각의 아이템을 생성합니다.
                // 이미지 검색을 상단에 노출시키기 위해 이미지 alt 속성에 tag를 넣습니다.
                let des = "팀노바 테크 ";
                let tag_split = item.tags.split(",");
                let lv_type = "";
                switch(item.lv){
                    case "1" :
                            lv_type = lang_board_lv(this.props.lang).get("lv_1");
                        break;
                    case "2" :
                            lv_type = lang_board_lv(this.props.lang).get("lv_2");
                        break;
                    case "3" :
                            lv_type = lang_board_lv(this.props.lang).get("lv_3");
                        break;
                }
                
                for(var i = 0 ; i < tag_split.length; i++){
                    des += tag_split[i]+" ";
                }
                return (
                        
                    <div className='bg_div' key={item.idx} title={item.title}>
                        

                        <div className = 'data_items' id='data_items_thumnail'>
                            <div id="lv_label" title={lang_board_lv(this.props.lang).get("title")}>
                                {lv_type}
                            </div>
                            <Link to={'/pageread/'+this.props.lang+"/"+item.idx}>
                                <img alt = {des} title={des} src={process.env.REACT_APP_BASE_URL+item.thumnail_img} />
                            </Link>
                        </div>
                            
                        <div className='data_items' id='data_items_left_content'>
                            <Link to={'/pageread/'+this.props.lang+"/"+item.idx}>
                                <pre className="list_title">{item.title}</pre>
                                <pre className="list_subtitle">{item.sub_title}</pre>
                            </Link>
                            <div className='tag_group'>
                                {/* 태그 리스트를 가져옵니다.*/}
                                {this.get_tag_list(item.tags)}
                            </div>
                        </div>
                        <div className='data_items' id='data_items_right_content'>
                            <div id="right_content_conarea">
                                <Link to={'/tech/name/'+item.name}>
                                    <div id="right_content_img_area">
                                        <img alt='writer_prop_img' src={process.env.REACT_APP_BASE_URL+item.profile_img}></img>
                                    </div>
                                    <p style={{color : "black"}}>{item.name}<br /> {item.reg_dt.split(" ")[0]}</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                    
                );
            });  
            return res_data;  
        }
       
    }
     //",,," 로 구분된 태그 들을 버튼형태로 반환해 줍니다.
     get_tag_list(arr_tags){
        var tag_list = [];
        //',' 구분자로 이루어진 태그들을 스플릿 후 
        var arr_tags_split = arr_tags.split(',');
        //버튼형태로 반환합니다.
        // 버튼 클릭시 해당 태그로 검색 시작

        var counter = 0;
        var text_length = 0;
        
        for(var i in arr_tags_split){
            var kr_check = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
            var is_kr = kr_check.test(arr_tags_split[i]);
            
            if(is_kr){
                // 한글일 경우 글자 폭은 영어의 2배
                text_length += arr_tags_split[i].length *2;
            }else{
                //영어
                text_length += arr_tags_split[i].length;
            }
            
            if(text_length > 60){
                tag_list.push("...");    
                break;
            }
            tag_list.push(arr_tags_split[i]);
        
        }                
        counter = 0;
        return tag_list.map((item) => {
            counter++;
            if(item ==="..."){
                return(
                    <span key={counter}>{item}</span>
                )
            }else{
                return(
                    <Link to={'/tech/tag/'+item} key={counter}>
                        <span>{"#"+item+"  "}</span>
                    </Link>
                )
            }
            
        })
    }
    render(){
        return (
            <div>
                {this.get_board_ui() }
            </div>
        )
    }
};










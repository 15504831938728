// 팀노바에 대한 설명 

import React from 'react';
import GoogleMap from '../items/GoogleMap';
import { connect } from 'react-redux';
//css
import './ContectUs.css';

// 언어 
import * as lang_pack from '../../utils/language_pack'; 

// seo 검색용의성을 위한 헬멧 추가
import {Helmet} from 'react-helmet'


export class ContectUs extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            lang_data : {}
        }
    }
    
    componentWillMount(){
        // console.log(this.props.lang);
        //언어 관리
        let lang_data;
        if(this.props.lang ===lang_pack.KOREAN){
            lang_data = lang_pack.lang_contect(lang_pack.KOREAN);
            
        }else if(this.props.lang ==="EN"){
            lang_data = lang_pack.lang_contect(lang_pack.ENGLISH);
        }
        this.setState({
            lang_data : lang_data
        })
        
    }
    componentDidMount(){
        window.scrollTo(0,0);
    }

    componentWillReceiveProps(next_props) {
        //console.log(next_props);
        //언어 관리
        let lang_data;
        if(next_props.lang ===lang_pack.KOREAN){
            lang_data = lang_pack.lang_contect(lang_pack.KOREAN);
        }else if(next_props.lang ==="EN"){
            lang_data = lang_pack.lang_contect(lang_pack.ENGLISH);
        }
        this.setState({
            lang_data : lang_data
        })
    }

    render(){
        return(
                <div id="contect_main">
                    <Helmet>
                        <title>팀노바테크 | 위치 및 문의하기</title>
                        <meta name="description" content="팀노바 테크 위치 및 문의하기"/>  
                        <meta name="keywords" content={"팀노바테크, 팀노바, 팀노바 위치, 팀노바 장소, 남성, 남성역, 팀노바 이메일"} />
                    </Helmet>
                    <h1>{this.state.lang_data.get("title")}</h1>
                    <hr/>
                    <ul>
                        <li id="conetct_google">
                            <GoogleMap conus={true}/>
                        </li>
                        <li id="conetct_non_map">
                            <h3>{this.state.lang_data.get("location_title")}</h3>
                            <p>{this.state.lang_data.get("location_text")}</p>
                            <h3>{this.state.lang_data.get("email_title")}</h3>
                            <p>{"teamnovatech0410@gmail.com"}</p>
                            <h3>{this.state.lang_data.get("official_phone_title")}</h3>
                            <p>{this.state.lang_data.get("official_phone")}</p>
                        </li>
                    </ul>
                </div>
                
            
        )
    }
    
}


let mapStateToProps = ( state ) => {
	return {
        lang : state.user.lang,
    }
}


export default connect(mapStateToProps)(ContectUs);


import React from 'react';
// 페이징용 컴포넌트들
import { Route,Switch } from 'react-router-dom';
import { ContectUs ,Signin, Signup, Write,Service_Terms,Page_Read,My_Page, page_404, Trend} from '../components/pages' 
// 공용 css
import './Content.css';
//컨테이너
import GlobalLang from '../containers/GlobalLang';  //seo 다국어 콘텐츠 통합 인식을 위한 언어 구분 페이지
import Home from '../containers/HomeContainer'; //홈화면
import Tech from '../containers/TechContainer'; //기술 블로그
import Admin from '../containers/AdminPage';    //관리자 페이지




//네비 컴포넌트에서 클릭시 페이지가 바뀌는 영역입니다.
class Content extends React.Component{
    
    
    render(){
        return(
            <div id="default_div">
                <Switch>
                    {/* 기본 패스 */}
                    <Route exact path="/" component={Home} >
                    </Route>
                    {/* 메인 패스 */}
                    <Route path="/home" component={Home} >
                    </Route>
                    {/* 글로벌 접속 주소 */}
                    {/* 한글 */}
                    <Route path="/kr" component={GlobalLang} /> 
                    {/* 영문 */}
                    <Route path="/en" component={GlobalLang} /> 
                    {/* 기술 블로그 영역 */}
                    <Route path="/tech/:search_type/:tag_name" component={Tech} />
                    {/* 기술 블로그 자세히 보기 영역 */}
                    <Route path="/pageread/:lang/:idx" component={Page_Read} >
                    </Route>
                    {/* 팀노바 설명 */}
                    <Route path="/contectus" component={ContectUs} />
                    {/* 트렌드*/}
                    <Route path="/trend" component={Trend} />
                    {/* 관리자페이지 */}
                    <Route path="/admin" component={Admin} />

                    {/* 로그인  */}
                    <Route path="/signin" component={Signin} />
                    {/* 회원가입 */}
                    <Route path="/signup" component={Signup} />

                    {/* 내정보 변경 */}
                    <Route path="/mypage" component={My_Page} />

                    {/* 글쓰기 */}
                    <Route path="/write/:type/:board_idx" component={Write} />

                    {/*서비스 약관 */}
                    <Route path='/serviceterms' component={Service_Terms} />

                    {/* 404페이지 */}
                    <Route component={page_404} />
                </Switch>
            </div>
        )
    }
}

export default Content;
// 내정보를 수정할 수 있는 페이지 입니다.
//로그인 하지 않았을 경우에는 들어오지 못합니다.

import React from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Button} from 'primereact/button';
import {InputText} from "primereact/inputtext"; 
import {TabMenu} from 'primereact/tabmenu';
import {Card} from 'primereact/card';
import {Password} from 'primereact/password';
import {Messages} from 'primereact/messages';
import {InputTextarea} from 'primereact/inputtextarea';
import * as Api from '../../utils/Api';
import * as userAction from '../../store/modules/User';
//언어관리
import * as lang_pack from '../../utils/language_pack'; 

import './My_Page.css';

class My_page extends React.Component{
    // 생성자
    constructor(props) {
        super(props);
        this.state = {
            items: [
                {label: '개인정보 수정', icon: 'pi pi-fw pi-info' , value : '0'},
                {label: '비밀번호 변경', icon: 'pi pi-fw pi-key', value : '1'},
                {label: '회원탈퇴', icon: 'pi pi-fw pi-user-minus', value : '2'},
            ],
            active_item : {label: '개인정보 수정', icon: 'pi pi-fw pi-info' , value : '0'},
            // 프로필 수정
            name : this.props.name,         //이름
            now_pw : "",                    //현재 비밀번호
            default_explain : "",           //기본 설명
            //비밀번호 변경
            default_password : "",          //기존 패스워드
            change_password : "",           //바뀐 패스워드
            change_password_chk : "",       //바뀐 패스워드 체크
            // 프로필 이미지 관련
            profile_file_chk : false,       //프로필 이미지 바뀌었는지 체크
            profile_file_src : this.props.profile_img,  //처음에는 글로벌 props 로 이미지 주소를 서버에서 받아오고 파일 입력을 따로 하게되면 파일 입력한 주소값으로 이미지 지정
            profile_file : null,        //서버로 보내야할 파일
            lang_data : {},             //언어 패키지
            
        };
        //이벤트 트리거 연걸
        this.file = React.createRef();
        //이벤트 연결
        this.file_change = this.file_change.bind(this);
    };

    static propType = {
        is_login : PropTypes.bool,
        sessionId : PropTypes.string,
        default_explain : PropTypes.string
    };
     /************프로프 업데이트****************** */
    componentWillMount(){
        // console.log(this.props.lang);
        //언어 관리
        let lang_data;
        if(this.props.lang ===lang_pack.KOREAN){
            lang_data = lang_pack.lang_my_page(lang_pack.KOREAN);
        }else if(this.props.lang ==="EN"){
            lang_data = lang_pack.lang_my_page(lang_pack.ENGLISH);
        }
        //기본 프로필 정보 
        let str_default_explain = " ";
        if(this.props.default_explain !== null && this.props.default_explain !== undefined){
            str_default_explain = this.props.default_explain;
        }
        // 이미지
        this.setState({
            lang_data : lang_data,
            items:[
                {label : lang_data.get("menu").get("menu_1"), icon : "pi pi-fw pi-info" , value : '0'},
                {label : lang_data.get("menu").get("menu_2"), icon : "pi pi-fw pi-key" , value : '1'},
                {label : lang_data.get("menu").get("menu_3"), icon : "pi pi-fw pi-minus" , value : '2'}
            ],
            default_explain : str_default_explain,

        })
    }
    componentWillReceiveProps(next_props) {
        //언어 관리
        let lang_data;
        if(next_props.lang ===lang_pack.KOREAN){
            lang_data = lang_pack.lang_my_page(lang_pack.KOREAN);
        }else if(next_props.lang ==="EN"){
            lang_data = lang_pack.lang_my_page(lang_pack.ENGLISH);
        }
        this.setState({
            lang_data : lang_data,
            items:[
                {label : lang_data.get("menu").get("menu_1"), icon : "pi pi-fw pi-info" , value : '0'},
                {label : lang_data.get("menu").get("menu_2"), icon : "pi pi-fw pi-key" , value : '1'},
                {label : lang_data.get("menu").get("menu_3"), icon : "pi pi-fw pi-minus" , value : '2'}
            ],
        })
      }
    /************************메세지 표시기*********************************** */
    showError(title,body) {
        this.messages.show({severity: 'error', summary: title, detail: body});
    }
    
    showSuccess(title,body) {
        this.messages.show({severity: 'success', summary: title, detail: body});
    }

    //로그인을 체크합니다.
    //로그인하지 않았을 경우에는 홈 화면으로 돌려보냅니다.,
    login_chk(){
        if(!this.props.is_login){
            this.props.history.push('/signin');
        }
    }

    /**********************개인정보 변경******************** */
    //프로필 체인지
    file_change() {
        //console.log("들어옴");
        // var file = this.refs.file.files[0];
        var file = this.file.files[0];
        var size = file.size;
        //사이즈 체크
        if(size > 2097152){
            this.showError(this.state.lang_data.get("my").get("err_file_size_title"),this.state.lang_data.get("my").get("err_file_size_subtitle"));
            return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        
        //파일 이미지 주소
        reader.onloadend = function (e) {
            //console.log(reader.result);
            this.setState({
                profile_file_src: [reader.result],
                profile_file_chk : true
            })
        }.bind(this);

    }
    //프로필 체인지 진행
    profile_change(){
        //console.log(this.state.default_explain);
        //각 항목이 변경 했는지 체크합니다.
        var default_explain_chk = false;
        var name_chk = false;
        var profile_img_chk = false;
        //기본 설명
        if(this.props.default_explain !== this.state.default_explain){
            default_explain_chk = true;
        }
        //이름
        if(this.props.name !== this.state.name){
            name_chk = true;
        }
        //프로필 이미지
        //console.log(this.props.profile_img);
        //console.log(this.state.profile_file_src);
        if(this.props.profile_img !== this.state.profile_file_src){
            profile_img_chk = true;
        }

        //변경한 것만 모두 진행, 변경이 아예 없다면 거절합니다. 
        if(!name_chk && !profile_img_chk && !default_explain_chk){
            this.showError(this.state.lang_data.get("my").get("err_profile_change"),this.state.lang_data.get("my").get("err_not_change"));
            return;
        }        
        //비밀번호 입력 체크
        if(!this.state.now_pw){
            this.showError(this.state.lang_data.get("my").get("err_profile_change"),this.state.lang_data.get("my").get("err_empty_pw"));
            return;
        }

        //변경 진행
        let current = this;     //현재값 연결
        //보내기전 이미지 데이터 파싱
        let profile_img_data = "";
        if(profile_img_chk){
            profile_img_data= this.state.profile_file_src.toString().replace(/\+/g,'__2B__');
        };
         

        let data = Api.MEMBER_EDIT(this.props.idx,this.state.name,this.state.now_pw,"",profile_img_data,this.state.default_explain);
        Api.axios_instance.post(`${process.env.REACT_APP_MEMBER_URL}`,data)
        .then(function(res){
            var retJson = res.data;
            //console.log(retJson);
            //변경 성공
            if(retJson['retCode']=== 0){
                //변경 성공시 나오게 되는 코드 작성
                //변경되서받는 정보
                //idx, name, e_address1, e_address2 , profile_img
                //데이터 props에 세팅
                var retBody = retJson['retBody'];
                
                //프롭 변경
                current.props.user_login_data_input(
                    retBody['idx'],
                    retBody['e_address1']+"@"+retBody['e_address2'],
                    retBody['name'],
                    current.props.sessionId,
                    process.env.REACT_APP_BASE_URL+retBody['profile_img']);
                //입력해놨던 데이터 변경 및 비밀번호 지우기
                current.setState({
                    name : retBody['name'],
                    now_pw : "",
                    profile_file_src : process.env.REACT_APP_BASE_URL+retBody['profile_img'],
                })
                
                //성공 메세지 표시
                current.showSuccess(current.state.lang_data.get("my").get("suc_ok"), current.state.lang_data.get("my").get("suc_ok_exp"));

            }else if( retJson['retCode']=== 500){
                //비밀번호 오류
                current.showError(current.state.lang_data.get("my").get("err_profile_change"),current.state.lang_data.get("my").get("err_500"));
            }else if(retJson['retCode']=== 404){
                //회원정보 찾을 수 없음
                current.showError(current.state.lang_data.get("my").get("err_profile_change"),current.state.lang_data.get("my").get("err_404")); 
            }else if(retJson['retCode']=== 600){
                //변경된 정보가 없다
                current.showError(current.state.lang_data.get("my").get("err_profile_change"),current.state.lang_data.get("my").get("err_600")); 
            }

        });
    }

    //비밀번호 변경
    pw_change(){
        //바인딩
        var current = this;
        //패스워드
        var now_pw = this.state.default_password;               //현 비밀번호
        var change_pw = this.state.change_password;             //바꿀 비밀번호
        var change_pw_2 = this.state.change_password_chk;       //바꿀 비밀번호 체크
        //기존 비밀번호 입력 안함
        if(!now_pw){
            this.showError(this.state.lang_data.get("pw").get("err_empty"),this.state.lang_data.get("pw").get("err_empty_exp"));
            return;
        }
        //사용할 수 없는 비밀번호 반려 체크
        if(change_pw.startsWith("!" || "@" || "#" || "$" || "%" || "^" || "&" || "*" || "(" || ")" || "<" || ">" || "/" || "?" || "-" || "_" || "=" ||"+")){
            this.showError(this.state.lang_data.get("pw").get("err_wrong"),this.state.lang_data.get("pw").get("err_wrong_exp"));
            return;
        }
        if(!change_pw){
            this.showError(this.state.lang_data.get("pw").get("err_change"),this.state.lang_data.get("pw").get("err_change_exp"));
            return;
        }
        //현 비밀번호와 바꿀 비밀번호 안맞음
        if(change_pw !== change_pw_2){
            this.showError(this.state.lang_data.get("pw").get("err_pw"),this.state.lang_data.get("pw").get("err_pw_exp"));
            return;
        }

        //비밀번호 변경 진행
        //폼 생성
        let data = Api.MEMBER_EDIT(this.props.idx,"",now_pw,change_pw,"","");
        //통신 진행
        Api.axios_instance.post(`${process.env.REACT_APP_MEMBER_URL}`,data)
        .then(function(res){
            var retJson = res.data;
            //변경 성공
            if(retJson['retCode']=== 0){
                //성공 메시지 표시
                current.showSuccess(current.state.lang_data.get("pw").get("suc_pw_change"), current.state.lang_data.get("pw").get("suc_pw_change_exp"));
                // 비밀번호 입력 칸 날려버리기
                current.setState({
                    default_password : "",
                    change_password : "",
                    change_password_chk : ""
                })
            }else if( retJson['retCode']=== 404){
                //비밀번호 오류
                current.showError(current.state.lang_data.get("my").get("err_profile_change"),current.state.lang_data.get("my").get("err_500"));
            }
        });
    }

    //회원탈퇴
    member_del(){
        //바인딩
        var current = this;
        //패스워드
        var now_pw = this.state.default_password;               //현 비밀번호
        if(!now_pw){
            this.showError(this.state.lang_data.get("bye").get("err_empty_pw"),this.state.lang_data.get("bye").get("err_empty_pw_exp"));
            return;
        }

        let data =  Api.MEMBER_DEL(this.props.idx,now_pw);
        //통신 진행
        Api.axios_instance.post(`${process.env.REACT_APP_MEMBER_URL}`,data)
        .then(function(res){
            var retJson = res.data;
            //변경 성공
            if(retJson['retCode']=== 0){
                //성공 메시지 표시
                current.showSuccess(current.state.lang_data.get("bye").get("suc_bye"), current.state.lang_data.get("bye").get("suc_bye_exp"));
                // 비밀번호 입력 칸 날려버리기
                current.props.user_logout();
            }else if( retJson['retCode']=== 404){
                //비밀번호 오류
                current.showError(current.state.lang_data.get("my").get("err_profile_change"),current.state.lang_data.get("my").get("err_500"));
            }
        });

    }
    //인풋 버튼을 숨기기위해서 사용하는 빈 펑션
    empty_func = () => {

    }
     //활성화된 액티비티 스테이터스에 따라서 보여주는 메뉴가 바뀌게 됩니다.
    menu_render(){

        switch(this.state.active_item['value']){
            case "0":
                return(
                    <Card className="mypage_card">
                        <div className="mypage_menu_item">
                            <Messages ref={(el) => this.messages = el} />
                            <div className="mypage_div">
                                <div>
                                    <img id="mypage_profile_img" src={this.state.profile_file_src} alt="profile_img"/> 
                                </div>
                                <div>
                                    <Button label={this.state.lang_data.get("my").get("img_btn")} onClick={()=> this.file.click()}/>
                                    <form id="mypage_profile_upload">
                                        <input  type="file" ref={file => this.file = file} name="user[image]"  onChange={this.file_change} accept=".jpg,.jpge,.png" style={{display : "none"}}/>
                                    </form>
                                </div>
                            </div>
                            {/* 추가 정보 입력 */}
                            <div className="mypage_div">
                                <p id="profile_text">{this.state.lang_data.get("my").get("profile_text_title")}</p>
                                <InputTextarea rows={11} cols={25} value={this.state.default_explain} onChange={(e) => this.setState({default_explain: e.target.value})} autoResize={true} />
                            </div>
                            <div className="mypage_div">
                                <div className="p-col-12 p-md-4">
                                    <p id="profile_text">{this.state.lang_data.get("my").get("email")}</p>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-envelope"></i>
                                        </span>
                                        <InputText value={this.props.email} readOnly="readonly" />
                                    </div>
                                </div>
                                
                                <div className="p-col-12 p-md-4">
                                    <p id="profile_text">{this.state.lang_data.get("my").get("name")}</p>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-user"></i>
                                        </span>
                                        <InputText value={this.state.name}  onChange={(e) => this.setState({name: e.target.value})}/>
                                    </div>
                                </div>                                
                                <div className="p-col-12 p-md-4">
                                    <p id="profile_text">{this.state.lang_data.get("my").get("password")}</p>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-envelope"></i>
                                        </span>
                                        <Password feedback={false} value={this.state.now_pw}  onChange={(e) => this.setState({now_pw: e.target.value})} />
                                    </div>
                                </div>
                                <br />
                                <Button className="p-button-success" label={this.state.lang_data.get("my").get("save_btn")} onClick={(e)=>this.profile_change()}/>
                            </div>
                        </div>
                    </Card>
                );
            case "1":
                return(
                    <Card className="mypage_card">
                        <Messages ref={(el) => this.messages = el} />
                        <div className="mypage_menu_item">
                            <div className="mypage_pass_ul">
                                <ul >
                                    <li>
                                        <p>{this.state.lang_data.get("pw").get("pre_pw")}</p>
                                        <Password name="pw_ori" feedback={false} value={this.state.default_password} onChange={(e) => this.setState({default_password: e.target.value})} />
                                    </li>
                                    <li>
                                        <p>{this.state.lang_data.get("pw").get("next_pw")}</p>
                                        <Password value={this.state.change_password} onChange={(e) => this.setState({change_password: e.target.value})} />
                                    </li>
                                    <li>
                                        <p>{this.state.lang_data.get("pw").get("next_pw_chk")}</p>
                                        <Password value={this.state.change_password_chk} onChange={(e) => this.setState({change_password_chk: e.target.value})} />
                                    </li>
                                    <li>
                                        <Button label={this.state.lang_data.get("pw").get("save_btn")}  className="p-button-success"onClick={(e)=>this.pw_change()}/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Card>
                );
            case "2":
                return(
                    <Card className="mypage_card">
                        <div className="mypage_menu_item">
                            <Messages ref={(el) => this.messages = el} />
                            <div className="mypage_pass_ul" id ="del_acount">
                                <ul >
                                    <li>
                                        <h1>{this.state.lang_data.get("bye").get("title")}</h1>
                                        <h3>{this.state.lang_data.get("bye").get("sub_title")}</h3>
                                    </li>
                                    <li>
                                        <p>{this.state.lang_data.get("bye").get("pw")}</p>
                                        <Password feedback={false} value={this.state.default_password} onChange={(e) => this.setState({default_password: e.target.value})} />
                                    </li>
                                    <li>
                                        <Button label={this.state.lang_data.get("bye").get("bye_btn")} className="p-button-success" onClick={(e)=>this.member_del()} />
                                    </li>
                                </ul>                       
                            </div>         
                        </div>
                    </Card>
                );
            default : break;
        }
    }
    render(){
        return(
            <div className="mypage_main_div">
                {this.login_chk()}
                <TabMenu id= "mypage_tab" model={this.state.items} activeItem={this.state.active_item} onTabChange={(e) => this.setState({active_item: e.value})}/>
                {this.menu_render()}
            </div>
        )
    }
}


let mapStateToProps = ( state ) => {
	return {
        is_login : state.user.is_login,
        profile_img : state.user.profile_img,
        email : state.user.email,
        name : state.user.name,
        idx : state.user.idx,
        sessionId : state.user.sessionId,
        default_explain : state.user.default_explain,
        lang : state.user.lang,
    }
}

//함수 연결
const map_dispatch_top_props = (dispatch) => ({
    user_login_data_input: (idx,email,name,sessionId,profile_img) => dispatch(userAction.user_login_data_input(idx,email,name,sessionId,profile_img)),
    user_logout :() => dispatch(userAction.user_logout())
});

export default withRouter(connect(mapStateToProps,map_dispatch_top_props)(My_page));
import 'core-js/es/map';
import 'core-js/es/set';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Root from './client/Root';

ReactDOM.render(
        <Root />
        , 
        document.getElementById('Root')     
    );
    
serviceWorker.unregister();
 
import React from "react";
//store 연결
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//css
import "./HomeContainer.css";
//기술 정보에 대한 리스트 ui
import LISTDATA from "../components/items/List_Blog_Item";
// //인기 기술 차트
// import PopularChart from '../components/items/Popular_Chart';
//통신 유틸
import * as Api from "../utils/Api";
//언어 팩 참고
import * as lang_pack from "../utils/language_pack";
// seo 검색용의성을 위한 헬멧 추가
import { Helmet } from "react-helmet";

import { get_previous_url } from "../utils/Func";

// 메인화면을 보여주는 홈 콘텐츠 입니다.
class HomeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blog_data: null,
      chart_data: null,
      top5_keyword: null,
      pop5_board: null,
      lang_data: {},
      lang: "KR"
    };
  }
  componentWillMount() {
    //언어팩 설정
    let lang_data;
    switch (this.props.lang) {
      case lang_pack.KOREAN:
        lang_data = lang_pack.lang_home(lang_pack.KOREAN);
        break;
      case lang_pack.ENGLISH:
        lang_data = lang_pack.lang_home(lang_pack.ENGLISH);
        break;
      default:
        break;
    }

    this.setState({
      lang_data: lang_data,
      lang: this.props.lang
    });

    get_previous_url();
  }

  //메인페이지 에서 필요한 정보를 가져옵니다.
  componentDidMount() {
    let current = this;
    //페이지가 렌더링 된 다음 진행되는 메서드
    //통신을 위한 데이터 형식 가져우기
    let data = Api.GET_HOME(current.props.lang);

    //통신 진행
    Api.axios_instance
      .post(`${process.env.REACT_APP_PAGES}`, data)
      .then(function(res) {
        //리턴값 체크
        var retJson = res.data;
        // console.log(retJson);
        //성공 코드
        if (retJson["retCode"] === 0) {
          //보드 데이터
          var board = retJson["retBody"]["board"];
          //차트 데이터
          var chart = retJson["retBody"]["chart_data"];
          //top5 키워드는 차트데이터에서 뽑아내서 사용합니다.
          var top5_keyword_list = [];
          for (var i = 0; i < 5; i++) {
            if (chart["list"][i]) {
              top5_keyword_list.push(chart["list"][i]);
            }
          }
          // 인기 게시판 5를 구합니다.
          var pop5_board_list = retJson["retBody"]["pop_board"]["list"];
          // console.log(pop5_board_list);
          current.setState({
            blog_data: board,
            chart_data: chart,
            top5_keyword: top5_keyword_list,
            pop5_board: pop5_board_list
          });
        }
      })
      .catch(function(err) {
        console.log(err);
      });

    window.scrollTo(0, 0);
  }
  componentWillReceiveProps(next_props) {
    //언어팩 관리 -> 언어 변경 처리 props.lang 변경 차이가 나면 진행함 기본은 ko 입니다.
    let lang_data;
    switch (next_props.lang) {
      case lang_pack.KOREAN:
        //언어 팩 받아오기 map 형태
        lang_data = lang_pack.lang_home(lang_pack.KOREAN);
        break;
      case lang_pack.ENGLISH:
        lang_data = lang_pack.lang_home(lang_pack.ENGLISH);
        break;
      default:
        break;
    }

    let current = this;
    //페이지가 렌더링 된 다음 진행되는 메서드
    //통신을 위한 데이터 형식 가져우기
    let data = Api.GET_HOME(next_props.lang);

    //통신 진행
    Api.axios_instance
      .post(`${process.env.REACT_APP_PAGES}`, data)
      .then(function(res) {
        //리턴값 체크
        var retJson = res.data;
        //성공 코드
        if (retJson["retCode"] === 0) {
          //보드 데이터
          var board = retJson["retBody"]["board"];
          //차트 데이터
          var chart = retJson["retBody"]["chart_data"];
          //top5 키워드는 차트데이터에서 뽑아내서 사용합니다.
          var top5_keyword_list = [];

          for (var i = 0; i < chart["list"].length; i++) {
            top5_keyword_list.push(chart["list"][i]);
          }
          // 인기 게시판 5를 구합니다.
          var pop5_board_list = retJson["retBody"]["pop_board"]["list"];

          current.setState({
            blog_data: board,
            chart_data: chart,
            lang_data: lang_data,
            lang: next_props.lang,
            top5_keyword: top5_keyword_list,
            pop5_board: pop5_board_list
          });
        }
      })
      .catch(function(err) {
        console.log(err);
      });
  }
  // 인기 키워드 탑 5 클릭
  pop5_board_click = target => {
    var get_idx = target.getAttribute("value");
    get_idx = get_idx * 1;
    // console.log(idx);
    // console.log(typeof(idx));
    // console.log(this.state.pop5_board);
    var idx = this.state.pop5_board[get_idx]["idx"];
    this.props.history.push("/pageread/" + this.props.lang + "/" + idx);
  };
  // top 5 보드 ul 뿌려주기
  pop5_ul_return = () => {
    let data = this.state.pop5_board;
    if (!data) {
      // 없으면 반환
      data = [];
    }
    let ul = [];
    for (var i = 0; i < 5; i++) {
      if (i < data.length) {
        ul.push(
          <li key={i}>
            <span id="home_top5_index">{i + 1}</span>
            <span
              value={i}
              id="home_top5_title"
              onClick={e => this.pop5_board_click(e.target)}
              title={data[i]["title"]}
            >
              {data[i]["title"]}
            </span>
          </li>
        );
      } else {
        ul.push(
          <li key={i}>
            <span id="home_top5_index">{i + 1}</span>
          </li>
        );
      }
    }

    return ul;
  };
  // 인기 게시판 5 제목 클릭
  top5_title_click = e => {
    var title = e.title;
    this.props.history.push("/tech/tag/" + title);
  };
  top5_ul_return = () => {
    let data = this.state.top5_keyword;
    // console.log(data);
    if (!data) {
      // 없으면 반환
      data = [];
    }
    // 더미 데이터 체크
    for (var i = 0; i < data.length; i++) {
      if (data[i] === undefined) {
        data.splice(i);
      }
    }

    let ul = [];
    for (var y = 0; y < 5; y++) {
      if (y < data.length) {
        ul.push(
          <li key={y}>
            <span id="home_top5_index">{y + 1}</span>
            <span
              id="home_top5_title"
              onClick={e => this.top5_title_click(e.target)}
              title={data[y]["name"]}
            >
              {data[y]["name"]}
            </span>
          </li>
        );
      } else {
        ul.push(
          <li key={y}>
            <span id="home_top5_index">{y + 1}</span>
          </li>
        );
      }
    }

    return ul;
  };
  render() {
    //언어 설정
    let lang_banner = this.state.lang_data.get("banner");
    let lang_item = this.state.lang_data.get("item_title");
    return (
      <div id="Home">
        <Helmet>
          <title>팀노바테크 | 팀노바 팀원 연구 데이터</title>
          <meta name="description" content="팀노바 팀원들의 연구 성과" />
          <meta
            name="keywords"
            content={
              "팀노바 테크,팀노바테크,팀노바,연구,성과,기술,팀노바라이트"
            }
          />

          <meta property="og:type" content="website" />
          <meta property="og:title" content="팀노바테크" />
          <meta property="og:description" content="팀노바 팀원들의 연구 성과" />
          <meta
            property="og:image"
            content="https://www.teamnovatech.co.kr/teamnova_kakao_icon.png"
          />
          <meta property="og:url" content="http://www.teamnovatech.co.kr" />
        </Helmet>

        <div id="cover">
          <div id="cover-inner">
            <h1 className="cover_text">{lang_banner.get("title")}</h1>
            <p className="cover_text">{lang_banner.get("sub_title")}</p>
          </div>
        </div>
        <div id="con_area">
          <div id="Content">
            <div className="home_list_container">
              {/* 최근 5개의 항목이 보여지게 됩니다. */}
              <h3>{lang_item.get("recently")}</h3>
              {/* 재사용 가능한 데이터 리스트를 사용합니다. 사용 방법은 해당 컴포넌트로 들어가서 확인 */}
              <LISTDATA
                type="recently"
                data={this.state.blog_data}
                lang={this.state.lang}
              />
            </div>
            <div id="home_chart">
              {/* 차트 부분 -> 이차트는 사용 기술을 모두 모아서 랭킹 형식을 보여줍니다. 
                                월별 데이터를 가지고 있어도 좋을듯..?
                            */}
              {/* <h3>{lang_item.get("populor")}</h3>
                            {/* 차트 부분도 데이터 넣어줌! */}
              {/* <PopularChart data = {this.state.chart_data} /> */}
            </div>
            <div id="side_content">
              <h3>TOP 5</h3>
              <ul>{this.pop5_ul_return()}</ul>
              <br />
              <h3>TOP KEYWORDS</h3>

              <ul>{this.top5_ul_return()}</ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//props 값으러 넣을 상태값 정의
const map_state_to_props = state => ({
  lang: state.user.lang
});

export default withRouter(connect(map_state_to_props)(HomeContainer));

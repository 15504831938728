// 로그인 창 화면 입니다. 따로 페이지 작업을 진행합니다.
// 로그인 완료
import React from 'react';
//유저 버튼
import UserBtn from '../../containers/User_Container';
// seo 검색용의성을 위한 헬멧 추가
import {Helmet} from 'react-helmet'

class Signin extends React.Component{


    render(){
        return (
            <div>
                <Helmet>
                    <title>팀노바 테크 - 로그인</title>
                    <meta name="description" content="팀노바 테크 로그인"/> 
                </Helmet> 
                <UserBtn insert_login={true}/>
                <br /><br /><br /><br />
            </div>
        )
    }

}



export default Signin;

import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import "./GoogleMap.css";

const mapStyles = {
  width: 'auto',
  height: 'auto',
};

export class MapContainer extends Component {
    state = {
        showingInfoWindow: false,  //Hides or the shows the infoWindow
        activeMarker: {},          //Shows the active marker upon click
        selectedPlace: {},          //Shows the infoWindow to the selected place upon a marker
        marker_data : [
            {
                count : "1",
                loc : "37.4828969,126.973829"
            }
        ],
        default_lat : 37.4828969,
        default_lng : 126.973829,
        zoom : 4,
        map_width : "80%",
        map_height : "500px"
    };

    onMarkerClick = (props, marker, e) =>
        this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
    });

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({ 
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };
    // 어디서 불려오는지 설정
    componentWillMount(){
        if(this.props.loc){
            this.setState({
                zoom : 4
            })
        }else if (this.props.conus === true){
            this.setState({
                zoom : 16
            })
        }

    }
    //마커리턴
    marker_return=()=>{
        if(this.props.loc){
            let id = 0;
            let data = this.props.loc.map(function(marker){
                let split = marker['loc'].split(',');
                let lat = parseFloat(split[0]);
                let lng = parseFloat(split[1]);
                id++;
                return(
                    <Marker
                    key = {id}
                    position = {{lat : lat , lng : lng}}
                    />
                )
            })
            return data;
        }else if(this.props.conus===true){
            let id = 0;
            let data = this.state.marker_data.map(function(marker){
                let split = marker['loc'].split(',');
                let lat = parseFloat(split[0]);
                let lng = parseFloat(split[1]);
                id++;
                return(
                    <Marker
                    key = {id}
                    position = {{lat : lat , lng : lng}}
                    />
                )
            })
            return data;
        }


        
    }
   
    render() {
      
      return (
        <div id="map_div">
            <Map
            className="markercluster-map"
            google={this.props.google}
            zoom={this.state.zoom}
            style={mapStyles}
            containerStyle={{width:this.state.map_width, height:this.state.map_height}}
            initialCenter={{
            lat: 37.4828969,
            lng: 126.973829
            }}
            >
            {this.marker_return()}
        
            </Map>
        </div>
      );
    }
  }
  
  export default GoogleApiWrapper({
    apiKey: 'AIzaSyC9t7o7pcGv40OxtkJT12AX3dDe2ExTOH0'
  })(MapContainer);
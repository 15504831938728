// 데이터 통신을 진행할 수 있게 도와주는 기본 구조 틀 입니다.
//모든 통신은 이 객체를 통해서 사용됩니다.

import axios from 'axios';
var crypto = require('crypto');


//**************axios 설정 instance****************/
export const axios_instance = axios.create({    
    timeout : 10000,
    headers: {
        'RX-Consumer-Key':`${process.env.REACT_APP_RX_KEY}`,
        'RX-Consumer-Secret':`${process.env.REACT_APP_RX_SECRET}`,
        'JWT' : "",
        'HTTP_DEVICE_ID': 'WEB',
    }
}) 
/***************************************************/
/*********************   로그   ********************/
/**************************************************/
//쌓기
export function ACCESS_LOG(city,region,country,ip,location,org){
    //데이터 형식 만들어주기
    var str_data ="";
    str_data +="cmd=ACCESS_LOG";
    str_data +="&city=";
    str_data +=city;
    str_data +="&region=";
    str_data +=region;
    str_data +="&country=";
    str_data +=country;
    str_data +="&ip=";
    str_data +=ip;
    str_data +="&location=";
    str_data +=location;
    str_data +="&org=";
    str_data +=org;

    //console.log(str_data);

    //폼형식으로 전송
    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
//접속 경로 로그
export function PREVIOUS_URL(pre_url, now_url){
    //데이터 형식 만들어주기
    var str_data ="";
    str_data +="cmd=PREVIOUS_URL";
    str_data +="&pre=";
    str_data +=pre_url;
    str_data +="&now=";
    str_data +=now_url;

    // console.log(str_data);

    //폼형식으로 전송
    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}


//일간접속자
export function ACCESS_DAY(order,limit){
    var str_data ="";
    str_data +="cmd=ACCESS_DAY";
    str_data +="&order=";
    str_data +=order;
    str_data +="&limit=";
    str_data +=limit;

    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
//주간접속자
export function ACCESS_WEEK(order,limit){
    var str_data ="";
    str_data +="cmd=ACCESS_WEEK";
    str_data +="&order=";
    str_data +=order;
    str_data +="&limit=";
    str_data +=limit;

    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
//월간접속자
export function ACCESS_MONTH(order,limit){
    var str_data ="";
    str_data +="cmd=ACCESS_MONTH";
    str_data +="&order=";
    str_data +=order;
    str_data +="&limit=";
    str_data +=limit;

    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
//인기 국가
export function ACCESS_COUNTRY(order,limit){
    var str_data ="";
    str_data +="cmd=ACCESS_COUNTRY";
    str_data +="&order=";
    str_data +=order;
    str_data +="&limit=";
    str_data +=limit;

    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
// 댓글 페이징
export function COMMENT_PAGENATION(first){
    var str_data ="";
    str_data +="cmd=BAORD_COMMENT_LIST";
    str_data +="&first=";
    str_data +=first;
    

    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
/*********************페이지*************************** */
// 메인페이지 정보 불러오기
export function GET_HOME(type){
    //데이터 형식 만들어주기
    var str_data ="";
    str_data +="cmd=HOME";
    str_data +="&type=";
    str_data +=type;

    //폼형식으로 전송
    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
// 테크페이지 정보 불러오기
export function GET_TECH(type,page,search_tag_name,search_type){
    //데이터 형식 만들어주기
    var str_data ="";
    str_data +="cmd=TECH";
    str_data +="&type=";
    str_data +=type;
    str_data +="&page=";
    str_data +=page;
    str_data += "&search_tag_name=";
    str_data +=search_tag_name;
    str_data += "&search_type=";
    str_data +=search_type;

    //폼형식으로 전송
    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
//어드민 페이지
export function GET_ADMIN(){
    var str_data ="";
    str_data +="cmd=ADMIN";

    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
// 트렌드
export function GET_TREND(){
    //데이터 형식 만들어주기
    var str_data ="";
    str_data +="cmd=TREND";
    

    //폼형식으로 전송
    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
/***************************유저관련*************************************/

// 로그인 필요 메서드
//  CMD
//  E_ADDRESS1
//  E_ADDRESS2
//  PASSWORD
export  function LOGIN(cmd,e_address,password){
    // 이메일 형식을 맞추기 위해 분할
    var email_split = e_address.split('@');
    var e_address1 = email_split[0];
    var e_address2 = email_split[1];

    // 데이터 형식 만들어주기
    var str_data ="";
    str_data += "cmd=";
    str_data += cmd;
    str_data += "&e_address1=";
    str_data += e_address1;
    str_data += "&e_address2=";
    str_data += e_address2;
    str_data += "&password=";
    str_data += password;
    //폼형식으로 전송
    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
//자동로그인
export function AUTO_LOGIN(idx_member){
    var str_data ="";
    str_data += "cmd=";
    str_data += "AUTO_LOGIN";
    str_data += "&idx_member=";
    str_data += idx_member;

    //console.log(str_data);

    //폼형식으로 전송
    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}

export function MEMBER_FIND_PW(e_address1,e_address2){
    var str_data ="";
    str_data += "cmd=";
    str_data += "MEMBER_FIND_PW";
    str_data += "&e_address1=";
    str_data += e_address1;
    str_data += "&e_address2=";
    str_data += e_address2;

    //console.log(str_data);

    //폼형식으로 전송
    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}

export function MEMBER_FIND_PW_CHECK(code,e_address1,e_address2){
    var str_data ="";
    str_data += "cmd=";
    str_data += "MEMBER_FIND_PW_CHECK";
    str_data += "&code=";
    str_data += code;
    str_data += "&e_address1=";
    str_data += e_address1;
    str_data += "&e_address2=";
    str_data += e_address2;

    //console.log(str_data);

    //폼형식으로 전송
    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}

export function MEMBER_FIND_PW_CHANGE(code,e_address1,e_address2,password){
    var str_data ="";
    str_data += "cmd=";
    str_data += "MEMBER_FIND_PW_CHANGE";
    str_data += "&code=";
    str_data += code;
    str_data += "&e_address1=";
    str_data += e_address1;
    str_data += "&e_address2=";
    str_data += e_address2;
    str_data += "&password=";
    str_data += password;
    //console.log(str_data);

    //폼형식으로 전송
    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
//회원가입 필요 메서드
//회원가입을 진행하는 메서드입니다.
//
export function SIGN_UP(name,email,pw,code){
    var email_split = email.split('@');
    var e_address1 = email_split[0];
    var e_address2 = email_split[1];

    //데이터 형식 만들어주기
    var str_data= "";
    str_data += "cmd=MEMBER_ADD";
    str_data += "&name=";
    str_data += name;
    str_data += "&e_address1=";
    str_data += e_address1;
    str_data += "&e_address2=";
    str_data += e_address2;
    str_data += "&password=";
    str_data += pw;
    str_data += "&code=";
    str_data += code;

    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;

}
// 유저 정보 변경 메서드
// 유저 정보를 변경하는데사용하는 메서드입니다.

export function MEMBER_EDIT(idx,name, password,pass_change,profile_img,default_explain){
    var str_data= "";
    str_data += "cmd=MEMBER_EDIT";
    str_data += "&idx=";
    str_data += idx;
    str_data += "&name=";
    str_data += name;
    str_data += "&password=";
    str_data += password;
    str_data += "&password_change=";
    str_data += pass_change;
    str_data += "&profile_img=";
    str_data += profile_img;
    str_data += "&default_explain=";
    str_data += default_explain;

    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
//회원 탈퇴 처리
export function MEMBER_DEL(idx,password){

    var str_data= "";
    str_data += "cmd=MEMBER_DEL";
    str_data += "&idx=";
    str_data += idx;
    str_data += "&password=";
    str_data += password;

    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}

/*********************************게시판 임시저장*************************************** */
//게시판 임시저장
export function BOARD_ADD_TEMP(idx_member,type,tags,title,thumnail_img,content,sub_title){
    var str_data = "";
    str_data += "cmd=BOARD_ADD_TEMP";
    str_data += "&idx_member=";
    str_data += idx_member;
    str_data += "&type=";
    str_data += type;
    str_data += "&tags=";
    str_data += tags;
    str_data += "&title=";
    str_data += title;
    str_data += "&thumnail_img=";
    str_data += thumnail_img;
    str_data += "&content=";
    str_data += content;
    str_data += "&sub_title=";
    str_data += sub_title;
    

    // console.log(str_data);

    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
//게시판 임시 저장 있는지 확인
export function BOARD_CHK_TEMP(idx_member){
    var str_data = "";
    str_data += "cmd=BOARD_CHK_TEMP";
    str_data += "&idx_member=";
    str_data += idx_member;

    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}

/*********************************게시판 저장*************************************** */
export function BOARD_ADD(idx_member,type,tags,title,thumnail_img,content,sub_title,lv){
    var str_data = "";
    str_data += "cmd=BOARD_ADD";
    str_data += "&idx_member=";
    str_data += idx_member;
    str_data += "&type=";
    str_data += type;
    str_data += "&tags=";
    str_data += tags;
    str_data += "&title=";
    str_data += title;
    str_data += "&thumnail_img=";
    str_data += thumnail_img;
    str_data += "&content=";
    str_data += content;
    str_data += "&sub_title=";
    str_data += sub_title;
    str_data += "&lv=";
    str_data += lv;

    // console.log(str_data); 

    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}

/*********************************게시판 데이터 불러오기 (1개) *************************************** */

export function BOARD_GET(idx,type){
    var str_data = "";
    str_data += "cmd=BOARD_GET_INFO";
    str_data += "&type=";
    str_data += type;
    str_data += "&idx=";
    str_data += idx;

    //console.log(str_data);
    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
/********************게시판에 사용하는 이미지 업로드******************************** */
export function BOARD_IMG_UPLOAD(idx,file){
    var str_data = "";
    str_data += "cmd=BOARD_IMG_UPLOAD";
    str_data += "&idx_member=";
    str_data += idx;
    str_data += "&file=";
    str_data += file;

    //console.log(str_data); 

    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
/**************************게시판 수정 전 데이터 불러오기************************ */
export function BOARD_MODIFY_GET(board_idx,type){
    var str_data = "";
    str_data += "cmd=BOARD_MODIFY_GET";
    str_data += "&type=";
    str_data += type;
    str_data += "&board_idx=";
    str_data += board_idx;

    //console.log(str_data);
    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
/**************************게시판 수정하기 (업데이트)*********************** */
export function BOARD_MODIFY_UPDATE(board_idx,idx_member,type,tags,title,thumnail_img,content,sub_title,lv){
    var str_data = "";
    str_data += "cmd=BOARD_MODIFY_UPDATE";
    str_data += "&board_idx=";
    str_data += board_idx;
    str_data += "&idx_member=";
    str_data += idx_member;
    str_data += "&type="; 
    str_data += type;
    str_data += "&tags=";
    str_data += tags;
    str_data += "&title=";
    str_data += title;
    str_data += "&thumnail_img=";
    str_data += thumnail_img;
    str_data += "&content=";
    str_data += content;
    str_data += "&sub_title=";
    str_data += sub_title;
    str_data += "&lv=";
    str_data += lv;

    //console.log(str_data);

    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
/**************************게시판 삭제*********************************** */
export function BOARD_DEL(board_idx, type){
    var str_data = "";
    str_data += "cmd=BOARD_DEL";
    str_data += "&type=";
    str_data += type;
    str_data += "&board_idx=";
    str_data += board_idx;

    //console.log(str_data);

    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
/********************************댓글********************************* */
// 댓글 추가
export function BOARD_COMMENT_ADD(board_type,board_idx,password,comment,ori_no,depth){
    var str_data = "";
    str_data += "cmd=BOARD_COMMENT_ADD";
    str_data += "&board_type=";
    str_data += board_type;
    str_data += "&board_idx=";
    str_data += board_idx;
    str_data += "&password=";
    str_data += password;
    str_data += "&comment=";
    str_data += comment;
    str_data += "&ori_no=";
    str_data += ori_no;
    str_data += "&depth=";
    str_data += depth;
    

    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
// 댓글 수정 벤 삭제
export function BOARD_COMMENT_MANAGE(board_type,board_idx,password,comment,ori_no,depth,btn_type){
    var str_data = "";
    str_data += "cmd=BOARD_COMMENT_MANAGE";
    str_data += "&board_type=";
    str_data += board_type;
    str_data += "&board_idx=";
    str_data += board_idx;
    str_data += "&password=";
    str_data += password;
    str_data += "&comment=";
    str_data += comment;
    str_data += "&ori_no=";
    str_data += ori_no;
    str_data += "&depth=";
    str_data += depth;
    str_data += "&btn_type=";
    str_data += btn_type;
    
    //console.log(str_data);
    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}
// (관리자용) 댓글 벤 복구
export function BOARD_COMMENT_ADD_RECOVERY(comment_idx,board_type,request_type){
    var str_data = "";
    str_data += "cmd=BOARD_COMMENT_ADD_RECOVERY";
    str_data += "&comment_idx=";
    str_data += comment_idx;
    str_data += "&board_type=";
    str_data += board_type;
    str_data += "&request_type=";
    str_data += request_type;
    
    //console.log(str_data);
    let form = new FormData();
    form.append('e',encrypt(str_data));
    return form;
}


/*************공용*************************** */
// AES256 암호화... 됬다!!
function encrypt(text){
    var key =process.env.REACT_APP_AES_KEY;
    var iv = [0x00,0x00,0x00,0x00,0x00,0x00,0x00,0x00,0x00,0x00,0x00,0x00,0x00,0x00,0x00,0x00];

    let cipher = crypto.createCipheriv('aes-256-cbc', key, iv);
    let encryptedData = cipher.update(text, 'utf8', 'base64') + cipher.final('base64');

    return encryptedData;
}
   



// 자주 쓰이는 함수들을 모아놓은 유틸 스크립트입니다.
import {axios_instance,PREVIOUS_URL} from "./Api.js";

// 정규식을 사용해 숫자 3자리마다 콤마를 찍어줍니다.
export function number_with_comma(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


//이메일 유요성 검사를 진행합니다. 
export function ck_email(str) {

    var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    if (regExp.test(str)) return true;

    else return false;

}

// 인터넷 브라우저가 ie인지 파악
export function ie_check(){
    var agent = navigator.userAgent.toLowerCase();
    if ( (navigator.appName === 'Netscape' && agent.indexOf('trident') !== -1) || (agent.indexOf("msie") !== -1)) {
        // ie일 경우
        return true;
    }else{
        // ie가 아닐 경우
        return false;
    }
}


// 2019-09-28 이전페이지를 어디에서접속 했는가를 판단하기 위해서 붙여 줌.\
// 사용한 곳 -> Page_Read.js

export function get_previous_url(){
    var ex_url = document.referrer; // 이전 주소
    var now_url = window.location.href; // 현제 주소

    // 190928 // 14:28 -> 이 정보를 어떻게 보여주고 어떻게 표현할지 생각해보자.
    
    let send_data = PREVIOUS_URL(ex_url,now_url);

    axios_instance.post(`${process.env.REACT_APP_LOG}`,send_data)
    .then(function(res){
    })

}
import React, {Component} from 'react';
//UI 구성
import ViewMenu from '../components/items/Mobile_Navi_Menu';
//store 연결
import {connect} from 'react-redux';

import * as userAction from '../store/modules/User';

class m_menu_container extends Component{

    render(){
        return(
            <ViewMenu 
                user_logout = {this.props.user_logout}
                profile_img = {this.props.profile_img}
                is_login ={this.props.is_login}
                lang = {this.props.lang}
                user_lang_ko={this.props.user_lang_ko}
                user_lang_en={this.props.user_lang_en}
            />
        );
    }
}

//props 값으러 넣을 상태값 정의
const map_state_to_props = (state) => ({
    is_login : state.user.is_login,
    profile_img : state.user.profile_img
});

//props 값으로 넣어줄 액션값 정의
const map_dispatch_to_props =(dispatch) => ({
    user_logout : () => dispatch(userAction.user_logout())
});
//컴포넌트 연동
//connect()의 결과는 컴포넌트에 props 를 넣어주는 함수를 반환.

export default connect(map_state_to_props,map_dispatch_to_props)(m_menu_container);
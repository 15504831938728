// 글쓰기 페이지입니다.
// 로그인을 했을 경우에만 들어오게 되고 로그인 하지 않은 경우는 메인으로 이동하게 됩니다.

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import './Write.css';
import * as userAction from '../../store/modules/User';
// 에디터관련
import {Editor } from 'react-draft-wysiwyg';
//import {convertToRaw,convertFromRaw,EditorState,ContentState} from 'draft-js';
import {convertToRaw,EditorState,ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {Messages} from 'primereact/messages';
import {InputTextarea} from 'primereact/inputtextarea';
import {InputText} from 'primereact/inputtext';
import {Chips} from 'primereact/chips';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {RadioButton} from 'primereact/radiobutton';
import {Dialog} from 'primereact/dialog';

// 통신관련
import * as Api from '../../utils/Api';

class Write extends React.Component{
    // 생성자
    constructor(props) {
        super(props);
        this.state = {
            write_type : "",            //쓰기 타입 저장 new_board or modify_board
            tags : [],                  //태그저장
            board_type : this.props.lang,          //한글 , 영문 타입 체크
            lv_check : "",          //단계 체크, lv_1 => 기초 , lv_2 => 응용, lv_3 => 응용2
            title : "",                 //제목
            sub_title : "",             //제목 밑에 나오는 간단 설명
            thumb_file : [process.env.REACT_APP_BASE_URL+"/_nova/_img/_boardimg/default/no_data.png"],            //썸네일 파일
            content : "",               //콘텐츠
            dialog_visible : false,     //다이얼로그 보여줄꺼니?
            content_state : EditorState.createEmpty(),              //콘텐츠 내용 저장
            // 새로 글쓰기와 수정 데이터 구분을 하기 위해서 사용하는 데이터
            //2019-05-30 업데이트
            upload_btn_name : "",
            board_idx : "",
            

            add_btn_disabled : false,

        };
        //펑션 바인드
        this.on_content_state_change = this.on_content_state_change.bind(this);
        this.editor_img_upload_callback = this.editor_img_upload_callback.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.showError = this.showError.bind(this);
    };
    // state 받기
    static propType = {
        is_login : PropTypes.bool,
        is_user_write_start : PropTypes.bool,
        user_write_end : PropTypes.func,
        idx : PropTypes.string
    };

    /******************컴포넌트 생명주기***************** */
    // static getDerivedStateFromProps(data){
        componentDidMount(){
        
        //여기에서 새글 쓰기인지, 수정인지 판단합니다.
        let url_chk = this.props.match.params.type;
        //console.log(url_chk);
        let upload_btn_name;
        //새글쓰기
        if(url_chk === "new_board"){
            //컴포넌트가 마운트 될 때 서버에서 임시 저장한 아이템이 있는 체크합니다.
            this.upload_temp_chk();
            upload_btn_name = "등록하기";
        }else if(url_chk ==="modify_board"){
            //수정 페이지.
            //수정페이지 들어오면 url param 을 체크해서 게시판의 idx값을 가져옵니다.
            let board_idx = this.props.match.params.board_idx;
            this.modify_get_data(board_idx);
            upload_btn_name = "수정하기";
        };

        try{
            this.setState({
                write_type : url_chk,
                upload_btn_name : upload_btn_name
            })
        }catch(e){

        }
        
    };
    
    // 컴포넌트가 마운트 해제 될 때 
    componentWillUnmount(){
        // 스토어에 유저가 쓰기를 끝 냈다고 알려 줍니다.
        this.props.user_write_end(); 
        
    };
    /*****************메세지 관련***************************** */
    showSuccess(title,content) {
        this.messages.show({severity: 'success', summary: title, detail: content});
    }

    showInfo(title,content) {
        this.messages.show({severity: 'info', summary: title, detail: content});
    }

    showWarn(title,content) {
        this.messages.show({severity: 'warn', summary: title, detail: content});
    }
    showError(title,content) {
        this.messages.show({severity: 'error', summary: title, detail: content});
    }
    /************************게시판 수정 관련************************ */
    //수정 전 데이터 불러오기
    async modify_get_data(board_idx){
        try{
            
            let current = this;
            //폼 데이터 형성
            let data =  Api.BOARD_MODIFY_GET(board_idx,this.state.board_type);
            //통신 시작!
            await Api.axios_instance.post(`${process.env.REACT_APP_BOARD_URL}`,data)
            .then(function(response){
                //성공 코드만 체크해서 진행하기
                var retJson = response.data;
                //console.log(retJson);
                if(retJson["retCode"] === 0){
                    
                    //태그들
                    let temp_tag = [];
                    if(retJson["retBody"]["tags"] === null){
                        //빈거
                        //그대로 갑니다
                    }else if(!retJson["retBody"]["tags"].includes(",")){
                        //','가 포함되어 있지 않으면 1개라고 판단. 
                        temp_tag.push(retJson["retBody"]["tags"]);
                    }else{
                        //console.log(retJson["retBody"]["tags"]);
                        temp_tag = retJson["retBody"]["tags"].split(",");
                        // temp_tag = JSON.parse("[" + retJson["retBody"]["tags"] + "]");
                    }

                    //콘텐츠
                    let content;
                    //일단 널값 체크
                    if(retJson['retBody']["content"] === null){
                        content = EditorState.createEmpty();
                    }else{
                        //https://www.npmjs.com/package/html-to-draftjs 참고하기
                        const {contentBlocks, entityMap} = htmlToDraft(retJson['retBody']["content"]);
                        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                        content = EditorState.createWithContent(contentState);
                    }
                    //썸네일 이미지 url 을 base64로 변환합니다.
                    //let thum_img_base64;
                    
                    let thumnail_img_url = process.env.REACT_APP_BASE_URL+retJson['retBody']["thumnail_img"];
                    //방식 변경, 썸네일 이미지는 불러올 때 url 로 사용하고, 만약 src 주소값이 url 일경우 null값 처리 하고 변경하지 않음.
                    current.setState({
                        thumb_file : thumnail_img_url,
                        title : retJson['retBody']['title'],
                        sub_title : retJson['retBody']['sub_title'],
                        tags : temp_tag,
                        content_state : content,
                        board_idx : board_idx
                    })
                    
                }else{
                    current.showError("게시글 수정", "데이터를 불러오는데 실패 했습니다.");
                }
            }).catch(function(error){
                //에러
                console.log(error);
            })
            
        }catch(e){
            console.log("수정전 데이터 불러오기 실패");
        }
        
    }
    /***********************임시 글 등록 관련*************************** */
    upload_temp = () => {    //업로드
        let currnet = this;
        currnet.setState({
            add_btn_disabled : true
        })
        //콘텐츠를 저장하기 위해서 json 형태로 바꿔서 저장합니다.
        //엄청난 양의 json 데이터가 나오게 되지만 현재로써는 이 상태로 진행합니다.
        //이후 생각해 볼 것은 editorState -> html 저장한 뒤에 html -> editorState 방식을 고려중입니다.
        //2019-05-03 editorState -> html 로 변경해서 저장합니다.

        // const json_content = JSON.stringify(convertToRaw(this.state.content_state.getCurrentContent()));
        // json_content.replace(/\r/gi, '\\r').replace(/\n/gi, '\\n').replace(/\t/gi, '\\t').replace(/\f/gi, '\\r');

        let html_content = draftToHtml(convertToRaw(this.state.content_state.getCurrentContent()));
        // 작성자가 임시로 글을 저장하도록 구현합니다.
        //2019_05_16 임시 저장 시에는 대표 이미지 저장 안하는 것 변경

        //console.log(html_content);
        let data = Api.BOARD_ADD_TEMP(
            this.props.idx,             //회원 IDX번호
            this.state.board_type,      //저장 보드 타입 KR EN TEMP
            this.state.tags,            //태그들
            this.state.title,           //제목
            "",                         //썸네일 이미지 ,TEMP 는 저장 안된다.
            html_content,   //콘텐츠 정보 (HTML으로 파싱)
            this.state.sub_title);      //부제목
        Api.axios_instance.post(`${process.env.REACT_APP_BOARD_URL}`,data)
        .then(function(response){
            //성공 코드만 체크해서 진행하기
            var retJson = response.data;
            currnet.setState({
                add_btn_disabled : false
            })
            // console.log(retJson);
            if(retJson["retCode"] === 0){
                //성공적으로 데이터 반환
                currnet.showSuccess("임시 저장 ","글쓰기 데이터가 임시 저장되었습니다.");
            }else{
                currnet.showError("임시 저장 실패", "임시 저장에 실패 했습니다.");
            }
        }).catch(function(error){
            currnet.setState({
                add_btn_disabled : false
            })
            //에러
            console.log(error);
            return 1;
        })

        //console.log(res);
    };
    //임시 저장하기 데이터가 있는지 체크
    upload_temp_chk(){
        try{
            var self = this;            
            //회원 idx번호 조회합니다.
            let data = Api.BOARD_CHK_TEMP(this.props.idx);

            Api.axios_instance.post(`${process.env.REACT_APP_BOARD_URL}`,data)
            .then(function(response){
                //성공 코드만 체크해서 진행하기
                var retJson = response.data;
                //console.log(retJson);
                if(retJson["retCode"] === 0){
                    //널갑 체크
                    //console.log(retJson)
                    //태그들 파싱 , 빈거 , 1개 , 2개이상
                    let temp_tag = [];
                    if(retJson["retBody"]["tags"] === null){
                        //빈거
                        //그대로 갑니다
                    }else if(!retJson["retBody"]["tags"].includes(",")){
                        //','가 포함되어 있지 않으면 1개라고 판단.
                        temp_tag.push(retJson["retBody"]["tags"]);
                    }else{
                        // console.log(retJson["retBody"]["tags"]);
                        let tag_split = retJson["retBody"]["tags"].split(",");
                        for (let i in tag_split){
                            temp_tag.push(tag_split[i]);
                        }
                        // temp_tag = JSON.parse("[" + retJson["retBody"]["tags"] + "]");
                    }

                    // let temp_tag = (retJson["retBody"]["tags"] === null) ? [] : JSON.parse("[" + retJson["retBody"]["tags"] + "]");
                    //콘텐츠 내용을 html -> draftjs로 바꿔줍니다.
                    let content;
                    //일단 널값 체크
                    if(retJson["retBody"]["content"] === null){
                        content = EditorState.createEmpty();
                    }else{
                        //https://www.npmjs.com/package/html-to-draftjs 참고하기
                        
                        const {contentBlocks, entityMap} = htmlToDraft(retJson["retBody"]["content"]);
                        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                        content = EditorState.createWithContent(contentState);

                    }
                    
                    //성공적으로 데이터 반환
                    self.setState({
                        dialog_visible : true,                      //임시저장 데이터 있다는 다이얼로그 불러오기
                        title : retJson["retBody"]["title"],        //제목
                        tags :  temp_tag,                           //태그 있으면 파싱 없으면 빈 어레이
                        board_type : retJson["retBody"]["type"],    //보드 타입 kr en temp
                        content_state : content,    //내용
                        sub_title : retJson["retBody"]["sub_title"],//부제목
                    })
                }
            }).catch(function(error){
                //에러
                console.log(error);
            })
        }catch(e) {
            console.error(e);
        }
    }
 
    /*********************************글등록************************************ */
    //글 등록하기
    upload_board=()=>{
        var self = this;
        //일단 빈값을 체크합니다.
        //제목
        //부제
        //태그
        //내용
        let title = this.state.title;
        let sub_title = this.state.sub_title;
        let tags = this.state.tags;
        let content = this.state.content_state;
        let lv = this.state.lv_check;
        // 길이체크
        
        if(!title || !sub_title || tags.length === 0 || !content){
            self.showError("제목, 본문요약, 태그, 내용을 모두 작성해주세요");
            return;
        }
        if(title.length >= 100){
            self.showError("제목은 100글자 이내");
            return;
        }
        if(sub_title.length >= 100){
            self.showError("요약은 100글자 이내");
            return;
        }
        // 본인의 현재 단계 입력 했는지 체크
        if(!lv || lv.length === 0 ){
            self.showError("본인이 연구한 시점의 단계를 선택해 주세요.");
            return;
        }else{
            if(lv === "lv_1"){
                lv = "1";
            }else if(lv === "lv_2"){
                lv = "2";
            }else{
                lv = "3"
            }
        }
        this.setState({
            add_btn_disabled : true
        })

        //실제 글을 등록하는 부분입니다.
         // 이미지 전송할 떄 특수문자 변환
        var text_thum = this.state.thumb_file
        var hex_thumnail = text_thum.toString().replace(/\+/g,'__2B__');
        // 작성자가 임시로 글을 저장하도록 구현합니다.
        //2019_05_16 임시 저장 시에는 대표 이미지 저장 안하는 것 변경
        let html_content = draftToHtml(convertToRaw(this.state.content_state.getCurrentContent()));
        let data;

        //새로 글 쓰는 것과 수정을 구분해줍니다.
        //등록, 수정에 따라 바뀌는 버튼 이름으로 구분해줍니다.
        //이미지 기본 이미지인지 판단
        let th_img = (text_thum[0].includes("https://")) ? "" : hex_thumnail;

        
        if(this.state.upload_btn_name === "등록하기"){
            //새글 쓰기
            data = Api.BOARD_ADD(
                this.props.idx,
                this.state.board_type,
                this.state.tags,
                this.state.title,
                th_img,
                html_content,
                this.state.sub_title,
                lv
                );
            
        }else{
            //수정 하기
            //이미지의 주소값이 HTTP 일 경우 보내지 않는다.
            //http 일경우는 기존 이미지에서 수정하지 않는 경우만 해당됩니다.
            if(hex_thumnail.includes("https://")){
                hex_thumnail = "";
            }
            data = Api.BOARD_MODIFY_UPDATE(
                this.state.board_idx,
                this.props.idx,
                this.state.board_type,
                this.state.tags,
                this.state.title,
                hex_thumnail,
                html_content,
                this.state.sub_title,
                lv
            );
            //console.log(html_content);
        }        
        //통신 시작
            Api.axios_instance.post(`${process.env.REACT_APP_BOARD_URL}`,data)
            .then(function(response){
                //성공 코드만 체크해서 진행하기
                var retJson = response.data;
                self.setState({
                    add_btn_disabled : false
                })
                // console.log(retJson);
                if(retJson["retCode"] === 0){
                    //성공적으로 데이터 반환
                    //성공적으로 데이터를 반환하면 --> 바로 작성한 본문 글 보기로 이동
                    //리턴한 보드 idx값 가져오기
                    var upload_board_idx = retJson["retBody"];
                    //받은 값으로 본문 페이지 이동
                    self.props.history.push('/pageread/'+self.state.board_type+"/"+upload_board_idx);
                    
                    
                }else{
                    self.showError("글 등록 에러","글 등록에 실패 했습니다.");
                }
            }).catch(function(error){
                //에러
                console.log(error);
                self.setState({
                    add_btn_disabled : false
                })
            })
        
    }
    // 태그 등록에 있어서 중복 검사를 진행하고 중복이 없다면 등록 진행
    add_tag=(current_tag)=>{
        // 중복제거
        var unique = current_tag.reduce(function(a,b){
            if(a.indexOf(b) < 0) a.push(b);
            return a;
        },[])
        
        var p = /[`~!@#$%^&*|\\//'";:?]/gi;

        if(p.test(unique)){
            this.showError("태그 등록 에러","태그는 특수문자를 사용할 수 없습니다.");
            return;
        }        
        //특수문자 파악
        // console.log(unique);
        this.setState({
            tags : unique
        })
    }

    // 임시저장된 글을 가져 올지 아닐지 파악
    temp_board_get = (intbool) => {
        if(intbool === 0){
            // 가져온다.
            this.setState({
                dialog_visible : false
            })            
        }else{
            // 안가져온다. 
            // 모든 정보 삭제
            this.setState({
                write_type : "",            //쓰기 타입 저장 new_board or modify_board
                tags : [],                  //태그저장
                board_type : this.props.lang,          //한글 , 영문 타입 체크
                title : "",                 //제목
                sub_title : "",             //제목 밑에 나오는 간단 설명
                thumb_file : [process.env.REACT_APP_BASE_URL+"/_nova/_img/_boardimg/default/no_data.png"],            //썸네일 파일
                content : "",               //콘텐츠
                dialog_visible : false,     //다이얼로그 보여줄꺼니?
                content_state : EditorState.createEmpty(),              //콘텐츠 내용 저장
            })
        }
    }
    //로그인 했는지 안 했는지를 체크해서 글쓰기를 보여주거나, 아니면 홈으로 보내버립니다.
    login_checker(){ 
        //if(true){
        if(this.props.is_login){
            let toolbar_oprion = this.editor_toolbar_option();
            return(
                <div className='writer_main_ui'>
                 <Dialog header ="임시 저장한 글이 있습니다." visible={this.state.dialog_visible} style={{width: '70%', textAlign:'center'}} onHide={(e) => this.setState({dialog_visible : false})} maximizable={false}>
                        기존에 작성했던 글이 있습니다.
                        <br />
                        <br />
                        [ 임시 저장된 글 불러오기]
                        <br />
                        <br />
                        <div>
                            <span style={{marginLeft : '5px'}}><Button label={"Yes"} onClick={()=>this.temp_board_get(0)}/></span>
                            <span style={{marginLeft : '5px'}}><Button label={"No"} onClick={()=>this.temp_board_get(1)}/></span>
                        </div>
                </Dialog>
                <Card >
                    <div className='editor_info'>
                        <div className='editor_info_left'>
                            <p>작성 언어</p> 
                            <span className="type_select_span">
                                <RadioButton inputId="rb1" name="type" value="KR" onChange={(e) => this.setState({board_type: e.value})} checked={this.state.board_type === 'KR'} />
                                <label htmlFor="rb1" className="p-radiobutton-label">한글</label>
                            </span>
                            <span className="type_select_span">
                                <RadioButton inputId="rb1" name="type" value="EN" onChange={(e) => this.setState({board_type: e.value})} checked={this.state.board_type === 'EN'} />
                                <label htmlFor="rb1" className="p-radiobutton-label">영문</label>
                            </span>
                            <p>본인의 현재 단계</p> 
                            <span className="type_select_span">
                                <RadioButton inputId="rb3" name="type" value="lv_1" onChange={(e) => this.setState({lv_check: e.value})} checked={this.state.lv_check === 'lv_1'} />
                                <label htmlFor="rb3" className="p-radiobutton-label">기초</label>
                            </span>
                            <span className="type_select_span">
                                <RadioButton inputId="rb4" name="type" value="lv_2" onChange={(e) => this.setState({lv_check: e.value})} checked={this.state.lv_check === 'lv_2'} />
                                <label htmlFor="rb4" className="p-radiobutton-label">응용1</label>
                            </span>
                            <span className="type_select_span">
                                <RadioButton inputId="rb5" name="type" value="lv_3" onChange={(e) => this.setState({lv_check: e.value})} checked={this.state.lv_check === 'lv_3'} />
                                <label htmlFor="rb5" className="p-radiobutton-label">응용2</label>
                            </span>

                            <p>타이틀로 사용되는 제목을 입력 (100자 이내)</p> 
                            <InputText className='write_title_input' placeholder="제목" value={this.state.title} onChange={(e) => this.setState({title : e.target.value})} />
                            <p>본문을 요약한 내용을 (100자, 3줄 이내로 작성)</p>
                            <InputTextarea className='write_title_input' rows={3}  cols={150} placeholder="본문 내용 요약" value={this.state.sub_title} onChange={(e) => this.setState({sub_title: e.target.value})} autoResize={true} />
                            <p>자신의 주제에 맞는 태그를 작성후 엔터로 등록 (태그당 20자 이내)</p>
                            <Chips className='write_title_input' placeholder="태그 등록(Enter)" max={10} value={this.state.tags} onChange={(e) => this.add_tag(e.value)}></Chips>
                            <p></p>
                        </div>
                        <div className="editor_info_thum">
                            {/*썸네일 등록란 */}
                            <p>썸네일 이미지 (16:9 이미지 추천)</p>
                            <img id="thumnail_img_preview" alt ="thumnail_img_preview" src={this.state.thumb_file}/> 
                            <p></p>
                            <input type='file' ref="thumnail_img_ref" id="thumnail_img_upload"onChange={this.thum_upload.bind(this)}/>
                            <p></p>
                        </div>
                        <div className='editor_info_right'>
                            <Button label="임시 저장" className="p-button-rounded p-button-secondary" onClick={(e) => this.upload_temp()} disabled={this.state.add_btn_disabled}/>
                            <Button label={this.state.upload_btn_name} className="p-button-rounded p-button-success" onClick={(e) => this.upload_board()} disabled={this.state.add_btn_disabled}/> 
                        </div>
                    </div>
                    <Messages ref={(el) => this.messages = el}></Messages>
                    {/* 에디터 객체 */}
                    <Editor 
                        style={{height:'inherit'}}
                        editorState={this.state.content_state}       //에티더 스테이트 연결
                        wrapperClassName  ='editor_wrap'
                        editorClassName  = 'editor_editor' 
                        onEditorStateChange={(e) =>this.on_content_state_change(e)}       //변화값 저장
                        localization={{
                            locale: 'ko',
                          }}        //툴바 한국어 변경 
                        toolbar = {toolbar_oprion}
                        />                    
                    {/* <textarea
                        disabled
                        value={draftToHtml(convertToRaw(this.state.content_state.getCurrentContent()))}
                    /> */}
                </Card>
                </div>
            )
        }else{
            this.props.history.push('/home');
        }
    }
    // 메인 렌더링
    render(){
        
        return(
            <div className="write_main_div">
                {this.login_checker()}
            </div>
        )
    }

    /****************************************************** */
    /***************에디터 관련 함수************************ */
    /***************************************************** */
    //글 내용 (콘텐츠) 상태값(내용) 저장
    on_content_state_change(content_state){
        this.setState({
          content_state : content_state
        });
    };
    // 에디터 상태값 
    on_editor_state_change(){
        //console.log("Asd");
    }
    //이미지 업로드 callBack 
    //서버로 데이터를 업로드 한뒤에 그 주소값(url 을 리턴하는 방식)
    editor_img_upload_callback(file){

        return new Promise(
            (resolve, reject) => {
                let current = this;
                //파일을 전송할 string 값으로 변환
                let file_value = file;
                if(file_value){
                    let file_reader = new FileReader();
                    //로드되면
                    file_reader.onload =  function(e){
                        let str_file = e.target.result;
                        //특수문자 변환
                        str_file = str_file.toString().replace(/\+/g,'__2B__');
                        //전송요 from 데이터 만들기
                        let data = Api.BOARD_IMG_UPLOAD(
                            current.props.idx,
                            str_file);
                            
                        Api.axios_instance.post(`${process.env.REACT_APP_BOARD_URL}`,data)
                        .then( function(response){
                            let retJson = response.data;
                            //에러코드 확인
                            if(retJson['retCode'] === 0){
                                //성공 데이터
                                //리턴 형식을 {data : {link : "dataUrl"}} 형식으로 반환해야합니다아아아아아아아앙 !!
                                resolve({data : { link : process.env.REACT_APP_BASE_URL+retJson['retBody']} });
                            }
                        });
                    }
                    file_reader.readAsDataURL(file_value);
                }else{
                    alert("파일 업로드 실패");
                }
            }
        )

    }
    //툴바 옵션 반환
    editor_toolbar_option(){
        const toolbar_option ={
            options: ['inline','blockType','fontSize','fontFamily', 'list','textAlign','colorPicker','link','image','remove', 'history'],
            inline : {
                inDropdown: true,
                options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
            },
            blockType: {
                inDropdown: true,
                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
            },
            fontSize: {
                options: [3,5,8, 9, 10, 11, 12, 14, 16, 18],
                className : "edit_font_size",
            },
            fontFamily: {
                options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
            },
            list: {
                inDropdown: false,
                options: ['unordered', 'ordered', 'indent', 'outdent'],
            },
            textAlign: {
                inDropdown: false,
                options: ['left', 'center', 'right', 'justify'],
            },
            colorPicker: {
                colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                  'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                  'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                  'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                  'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                  'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
            },
            link: {
                inDropdown: false,
                showOpenOptionOnHover: true,
                defaultTargetOption: '_self',
                options: ['link', 'unlink'],
            },
            image: {
                urlEnabled: true,
                uploadEnabled: true,
                alignmentEnabled: true,
                uploadCallback: this.editor_img_upload_callback,
                previewImage: true,
                inputAccept: 'image/jpeg,image/jpg,image/png',
                alt: { present: true, mandatory: false },
                defaultSize: {
                  height: 'auto',
                  width: 'auto',
                },
            },
            remove: { 
            },
            history: {
                inDropdown: false,
                options: ['undo', 'redo'],
            },

        };

        return toolbar_option;
    }

    /******************특수문자 변환 코드************************/
    

    /********************이미지 파일 처리********************* */
    thum_upload=()=>{
        var file = this.refs.thumnail_img_ref.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            //console.log(reader.result);
            this.setState({
                thumb_file : reader.result
            })
          }.bind(this);
      
    }
        
    // //picture_files 바운더리
    // //picture_base64 base64 정보 array return
    // thumb_img = (picture_files, picture_base64) => {
    //     // 기본 스테이터스에 바운더리 정보를 저장해둡니다.
    //     this.setState({
    //         thumb_file: picture_base64
    //     });

    // };
}


let mapStateToProps = ( state ) => {
	return {
        is_login : state.user.is_login,
        is_user_write_start : state.user.is_user_write_start,
        idx : state.user.idx,
        lang : state.user.lang
    }
}

//함수 연결
const map_dispatch_top_props = (dispatch) => ({
    user_write_end: () => dispatch(userAction.user_write_end())
});

export default withRouter(connect(mapStateToProps,map_dispatch_top_props)(Write));
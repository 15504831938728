// 유저의 상태 관리를 하는 액션 스크립트 입니다.

import {createAction} from 'redux-actions';

//액션 타입
//로그인
const USER_LOGIN = 'user/USER_LOGIN';   //유저 로그인 메뉴 진입
const USER_LOGIN_START = 'user/USER_LOGIN_START'; //유저가 로그인을 진행
const USER_LOGIN_DATA_INPUT = 'user/USER_LOGIN_DATA_INPUT'; //유저가 로그인 성공했을 때 그 정보를 STORE에 넣어주는 액션자
//회원가입
const USER_SIGN = 'user/USER_SIGN';     //유저 회원가입 메뉴 진입
//글작성
const USER_WRITE_START = 'user/USER_WRITE_START';   //유저 글작성 시작
const USER_WRITE_END = 'user/USER_WRITE_END';       //유저 글 작성 끝
//로그아웃
const USER_LOGOUT = "user/USER_LOGOUT";             //유저 로그아웃 시키기

/*************유저 언어 관련********** */
//언어 선택
const USER_LANG_KO = 'user/KR';        //한글
const USER_LANG_EN = 'user/EN';        //영어

/******************페이지네이션 저장***************** */
const USER_PAGENATION_COUNT = 'user/USER_PAGENATION_COUNT';
const USER_PAGENATION_RESET = 'user/USER_PAGENATION_RESET';

/**********************유저 검색어 글로벌 저장********************** */
const USER_SEARCH_WORDS = 'user/USER_SEARCH_WORDS'; //유저 검색어를 최대 2개까지 저장해 둡니다.

//액션 생성자
//로그인
export const user_login = createAction(USER_LOGIN);  //로그인 메뉴 클릭
export const user_login_start = createAction(USER_LOGIN_START); //로그인 시도
export const user_login_data_input = (idx,email,name,sessionId,profile_img,default_explain,grade) => {
    return{
        type : USER_LOGIN_DATA_INPUT,
        idx : idx,
        email : email,
        name : name,
        sessionId : sessionId,
        profile_img : profile_img,
        default_explain : default_explain,
        grade : grade,
    }
}
//export const user_login_data_input = createAction(USER_LOGIN_DATA_INPUT);   //데이터 입력 액션 생성자  로그인후 사용자 정보를 가지고 있습니다.
//회원가입
export const user_sign = createAction(USER_SIGN); //회원가입 메뉴 클릭
//글작성
export const user_write_start = createAction(USER_WRITE_START); //유저 글 작성 시작
export const user_write_end = createAction(USER_WRITE_END); //유저 글 작성 끝
//로그아웃
export const user_logout = createAction(USER_LOGOUT);       //유저 로그아웃

/*************유저 언어 관련********** */
export const user_lang_ko = createAction(USER_LANG_KO); //한글 선택 액션자
export const user_lang_en = createAction(USER_LANG_EN); //영어 선택 액션자

// ***************페이지네이션 저장**************************
export const user_pagenation_count = (tech_pagenation_count) =>{
    return{
        type : USER_PAGENATION_COUNT,
        tech_pagenation_count : tech_pagenation_count,
    }
};

export const user_pagenation_reset = createAction(USER_PAGENATION_RESET);

// 유저 검색어 저장 
export const user_search_words = (words) => {
    return {
        type : USER_SEARCH_WORDS,
        words : words
    }
}

//초기화
const user_initialState={ 
    is_login_click : false,  //로그인 메뉴로 들어가게 되는 state
    is_login_start : false,  //로그인 시도
    is_login : false,        //로그인 중 이라면 true 반환
    is_sign_click : false,  //회원가입 메뉴로 들어가게 되는 state
    is_user_write_start : false, //유저가 쓰기를 시작했는지
    idx : -1,
    email : "",     //로그인 했을 경우 넣어주는 이메일
    name : "",
    sessionId : "",
    profile_img: "",
    default_explain : "",        //기본설명
    lang : 'KR',        //기본언어
    grade : "0",          //회원 등급
    tech_pagenation_count : 0,
    search_words : [],      //검색했던 목록
};

//기본 액션 지정
export default (state = {user_initialState}, action) => {
    
    switch(action.type){
        //로그인 메뉴 클릭 시 값이 TRUE 반환
        case USER_LOGIN:
            return{ 
                ...state,
                is_login_click : !state.is_login_click,
                is_login_start : false 
            };
        //로그인 시작함.
        case USER_LOGIN_START:
            return{
                ...state,
                is_login_start : !state.is_login_start
            };
        //로그인이 성공해서 유저에 대한 정보를 넣어줍니다.
        case USER_LOGIN_DATA_INPUT:
            return{
                ...state,
                idx : action.idx,
                email : action.email,
                name : action.name,
                sessionId : action.sessionId,
                profile_img : action.profile_img,
                default_explain : action.default_explain,
                is_login : true,
                grade : action.grade
            };
        //회원가입 메뉴 클릭 시 값이 TRUE 반환 
        case USER_SIGN:
            return{
                ...state,
                is_sign_click : !state.is_sign_click
            };
        // 유저 글 작성 시작
        case USER_WRITE_START:
            return{
                ...state,
                is_user_write_start : true
            };
        // 유저 글 작성 끝
        case USER_WRITE_END:
            return{
                ...state,
                is_user_write_start : false
            };
        //유저 로그아웃
        case USER_LOGOUT:
            return{
                ...state,
                is_login : false,
                idx : -1,
                email : "",
                name : "",
                sessionId : "",
                profile_img : "",
                default_explain : "",
                grade : "0",
            }
        //유저 언어 코드 변경
        //한글
        case USER_LANG_KO:
            return{
                ...state,
                lang : 'KR',
                tech_pagenation_count : 0
            }
        //영어
        case USER_LANG_EN:
            // console.log(state.tech_pagenation_count);
            return{
                ...state,
                lang : 'EN',
                tech_pagenation_count : 0
            }
            // 유저 페이지네이션
        case USER_PAGENATION_COUNT:
            // console.log(state.tech_pagenation_count);
            return{
                ...state,
                idx : state.idx,
                email : state.email,     //로그인 했을 경우 넣어주는 이메일
                name : state.name,
                sessionId : state.sessionId,
                profile_img: state.profile_img,
                default_explain : state.default_explain,        //기본설명
                lang : state.lang,        //기본언어
                grade : state.grade,          //회원 등급
                tech_pagenation_count : action.tech_pagenation_count,
            }
        case USER_PAGENATION_RESET:
                return{
                    ...state,
                    tech_pagenation_count : 0,
                }
        case USER_SEARCH_WORDS:
            // 유저 검색어 목록을 바꿔줍니다.
            return{
                ...state,
                search_words : [...state.search_words,action.words],
            }
            
        default :
            return user_initialState;
    }
}
// 관리자페이지 전용
import React from 'react';
//css연결
import './AdminPage.css';   
//store 연결
import {connect} from 'react-redux';
//강제이동
import {withRouter} from 'react-router-dom';
//통신용
import * as API from '../utils/Api';
//ui라이브러리
import {Card} from 'primereact/card';
import {Chart} from 'primereact/chart';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
// import {Paginator} from 'primereact/paginator';

//구글맵
import GoogleMap from '../components/items/GoogleMap';




class AdminPage extends React.Component{
    constructor(props){
        super(props);
        this.state={
            access_today : "0",                         //오늘 접속한 사람 수
            access_week : "0",                          //이번주 접속자 수
            access_month : "0",                         //이번 달 접속자 수
            new_member : "0",                           //신규 멤버
            all_member : "0",                           //전체 멤버 수
            pop_country : [],                         //가장 접속을 많이한 국가
            chart_h1_text : "일간 접속자",                         //차트 상단에 표시할 h1태그 타이틀
            day_chart_data :{                               //차트에 들어갈 데이터 기본 형식
                labels : ['1','2','3','4','5'],
                datasets :[
                    {
                        label : 'none',
                        data  : [1,2,3,4,5],
                        fill : false,
                        backgroundColor: '#42A5F5',
                        borderColor: '#42A5F5'
                    }
                ]
            },
            week_chart_data :{                               //차트에 들어갈 데이터 기본 형식
                labels : ['1','2','3','4','5'],
                datasets :[
                    {
                        label : 'none',
                        data  : [1,2,3,4,5],
                        fill : false,
                        backgroundColor: '#42A5F5',
                        borderColor: '#42A5F5'
                    }
                ]
            },
            month_chart_data :{                               //차트에 들어갈 데이터 기본 형식
                labels : ['1','2','3','4','5'],
                datasets :[
                    {
                        label : 'none',
                        data  : [1,2,3,4,5],
                        fill : false,
                        backgroundColor: '#42A5F5',
                        borderColor: '#42A5F5'
                    }
                ]
            },
            domain_chart_data:{                               //차트에 들어갈 데이터 기본 형식
                labels : ['1','2','3','4','5'],
                datasets :[
                    {
                        data  : [1,2,3,4,5],
                        fill : true,
                        backgroundColor: [
                            '#8b00ff',
                            '#0099a4',
                            '#c0c0c0',
                            '#edacb1',
                            '#2038ff',
                            '#f29886',
                            '#808080',
                            '#ece6cc',
                            '#964b00',
                            '#ff2020',
                        ],
                        borderColor: [
                            '#8b00ff',
                            '#0099a4',
                            '#c0c0c0',
                            '#edacb1',
                            '#2038ff',
                            '#f29886',
                            '#808080',
                            '#ece6cc',
                            '#964b00',
                            '#ff2020',
                        ],
                    }
                ]
            },
            map_option : {                              //구글 지도 옵션
                center: {lat: 37, lng: 126},
                zoom: 12
            },
            board_kr:[],                                //인기 게시판 kr
            board_en:[],                                //인기 게시판 en
            last_connect_member : [],                   //최근 로그인 5명
            map_mark_loc : "",                          //접속한 사람을 마커로 표시하기 위한 데이터
            
            pre_url : [],                               //이전 URL 을 파악하기위한 데이터

            // 댓글 관리용 상태 관리자
            board_comment : [],             //댓글 리스트
            // 댓글 리스트 페이지네이션
            board_comment_first : 0,
            board_comment_rows : 10,
            board_comment_total : 0,


        }

        // 페이지네이션 바인드
        this.comment_page_change = this.comment_page_change.bind(this);
        this.comment_ban_add = this.comment_ban_add.bind(this);
        this.comment_ban_remove = this.comment_ban_remove.bind(this);
    }
    componentDidMount(){
        let current = this;
        //일단 한번 걸러주고
        if(this.props.grade !== "10") return;

        let data = API.GET_ADMIN();
        //페이지 통신을 진행합니다.
        API.axios_instance.post(`${process.env.REACT_APP_PAGES}`,data)
        .then(function(res){
            var retJson = res.data; 
            // console.log(retJson); 
            if(retJson['retCode']===0){
                let retBody = retJson['retBody'];
                

                //파싱
                let day         =   retBody['day']['list'];                         //일간 접속자 수, 차트를 보여주기 위해 처음에는 얘를 10개까지 파싱함
                let week        =   retBody['week']['list'];            //주간 접속자 수
                let month       =   retBody['month']['list'];           //월간 접속자 수
            
                let all_member  =   retBody['all_member']['list'][0]['count'];     //전체 멤버
                let new_member  =   (retBody['new_member']['list'] !== false) ? retBody['new_member']['list'][0]['count'] : "0";      //신규 멤버

                let country     =   retBody['country']['list']         //많은 국가

                let board_en    =   retBody['board_en']['list'];       //영어 게시판 인기 top 5
                let board_kr    =   retBody['board_kr']['list'];        //한글 게시판 인기 top 5

                let last_member =   retBody['last_member']['list'];     //최근접속 회원 5명
                let loc         =   retBody['loc']['list'];             //접속 위치 리스트 -> 카운트해서 들어온다.

                let comment_en = retBody['board_comment']['en']['list'];
                let comment_kr = retBody['board_comment']['kr']['list'];
                // 댓글 총 개수 파악
                let comment_total = parseInt(retBody['board_comment']['en']['totCnt'])+parseInt(retBody['board_comment']['kr']['totCnt']);

                var comment_parse = new Map();
                // 두개로 나눠온 댓글 데이터 파싱
                for(var i = 0; i <comment_kr.length; i++){
                    // state = Y 면 사용자가 삭제 하지 않음 NULL처리
                    if(comment_kr[i]['state']==="Y") comment_kr[i]['state']="X";
                    else comment_kr[i]['state']="O";
                    // 관리자가 삭제했으면 복구처리 가능하게 변수 구분
                    let admin_delete = false;
                    // visible = Y 면 관리자가 삭제 하지 않음 NULL처리
                    if(comment_kr[i]['visible']==="Y") comment_kr[i]['visible']="X";
                    else {
                        comment_kr[i]['visible']="O";
                        admin_delete = true;
                    }

                    if(admin_delete){
                        comment_kr[i].ban = 
                            <button id="comment_btn_re" value={comment_kr[i]['idx']+"_KR"} onClick={(e)=>current.comment_ban_remove(e.target.value)}>
                                복구</button>;
                    }else{
                        comment_kr[i].ban = <button id="comment_btn_del" value={comment_kr[i]['idx']+"_KR"} onClick={(e)=>current.comment_ban_add(e.target.value)}>
                            삭제</button>;
                    }
                    
                    comment_parse.set(parseInt(comment_kr[i]['idx']),comment_kr[i]);
                }
                for(var y = 0; y <comment_en.length; y++){
                    let admin_delete = false;
                    // state = Y 면 사용자가 삭제 하지 않음 NULL처리
                    if(comment_en[y]['state']==="Y") comment_en[y]['state']="X";
                    else comment_en[y]['state']="O";
                    // visible = Y 면 관리자가 삭제 하지 않음 NULL처리
                    if(comment_en[y]['visible']==="Y") comment_en[y]['visible']="X";
                    else {
                        comment_en[y]['visible']="O";
                        admin_delete = true;
                    }

                    if(admin_delete){
                        comment_en[y].ban = <button id="comment_btn_re" value={comment_en[y]['idx']+"_EN"} onClick={(e)=>current.comment_ban_remove(e.target.value)}>
                            복구</button>;
                    }else{
                        comment_en[y].ban = <button id="comment_btn_del" value={comment_en[y]['idx']+"_EN"} onClick={(e)=>current.comment_ban_add(e.target.value)}>
                            삭제</button>;
                    }

                    comment_parse.set(parseInt(comment_en[y]['idx']),comment_en[y]);
                }
                // 맵을 댓글 idx값을 최신순으로 정렬
                let comment_list = Array.from((new Map([...comment_parse.entries()].sort()).values()));
                comment_list.reverse();
                // console.log(comment_list.reverse());
                //일간 접속자 데이터 파싱
                //데이터 라벨
                let day_chart_label = day.map(function(arr_day){
                    return arr_day['date']
                })
                //데이터 파싱
                let day_chart_data = day.map(function(arr_day){
                    return parseInt(arr_day['count'])
                })

                // 주간 데이터 파싱
                let week_chart_label = week.map(function(arr_day){
                    return arr_day['date']
                })
                //데이터 파싱
                let week_chart_data = week.map(function(arr_day){
                    return parseInt(arr_day['count'])
                })

                //월간 데이터 파싱
                let month_chart_label = month.map(function(arr_day){
                    return arr_day['date']
                })
                //데이터 파싱
                let month_chart_data = month.map(function(arr_day){
                    return parseInt(arr_day['count'])
                })

                //인기게시판 kr 파싱
                let rank = 1;
                let board_kr_parse = board_kr.map(function(arr_board_kr){
                    arr_board_kr['rank'] = rank;
                    rank++;
                    return arr_board_kr;
                })

                //인기게시판 en 파싱
                rank = 1;
                let board_en_parse = board_en.map(function(arr_board_en){
                    arr_board_en['rank'] = rank;
                    rank++;
                    return arr_board_en;
                })

                let last_connect_member_list = last_member.map(function(arr_last){
                    return arr_last;
                })

                // 2019-10-05
                // 들어온 경로를 파악하기 위해서 이전 URL을 파싱합니다.
                let pre_url = retBody['pre_url'].filter(function(element,index){
                    if(element['domain'] === "NULL"){
                        element['domain'] = 'Direct Link';
                    }
                    return element['domain'].indexOf("teamnovatech") === -1 && index <= 10;
                })
                // console.log(pre_url);
                let domain_chart_label = pre_url.map(function(domain_name){
                    return domain_name['domain'];
                    
                })
                let domain_chart_data = pre_url.map(function(domain_count){
                    return parseInt(domain_count['count']);
                })

                let country_parse = country.map(function(element){
                    return element; 
                })

                

                current.setState({
                    access_today    :   day[0]['count'],
                    access_week     :   week[0]['count'],
                    access_month    :   month[month.length-1]['count'],
                    
                    new_member      :   new_member,
                    all_member      :   all_member,

                    pop_country     :   country_parse,
                    day_chart_data      :{
                        labels : day_chart_label.reverse(),
                        datasets :[
                            {
                                label : '일간 접속자',
                                data  : day_chart_data.reverse(),
                                fill : false,
                                backgroundColor: '#42A5F5',
                                borderColor: '#42A5F5'
                            }
                        ]
                    },
                    week_chart_data      :{
                        labels : week_chart_label.reverse(),
                        datasets :[
                            {
                                label : '주간 접속자',
                                data  : week_chart_data.reverse(),
                                fill : false,
                                backgroundColor: '#42A5F5',
                                borderColor: '#42A5F5'
                            }
                        ]
                    },
                    month_chart_data      :{
                        labels : month_chart_label,
                        datasets :[
                            {
                                label : '월간 접속자',
                                data  : month_chart_data,
                                fill : false,
                                backgroundColor: '#42A5F5',
                                borderColor: '#42A5F5'
                            }
                        ]
                    },
                    current_chart_data : {
                        labels : day_chart_label,
                        datasets :[
                            {
                                label : '일간 접속자',
                                data  : day_chart_data,
                                fill : false,
                                backgroundColor: '#42A5F5',
                                borderColor: '#42A5F5'
                            }
                        ]
                    },
                    domain_chart_data      :{
                        labels : domain_chart_label,
                        datasets :[
                            {
                                label : '접속 경로',
                                data  : domain_chart_data,
                                backgroundColor: [
                                    '#8b00ff',
                                    '#0099a4',
                                    '#c0c0c0',
                                    '#edacb1',
                                    '#2038ff',
                                    '#f29886',
                                    '#808080',
                                    '#ece6cc',
                                    '#964b00',
                                    '#ff2020',
                                ],
                                borderColor: [
                                    '#8b00ff',
                                    '#0099a4',
                                    '#c0c0c0',
                                    '#edacb1',
                                    '#2038ff',
                                    '#f29886',
                                    '#808080',
                                    '#ece6cc',
                                    '#964b00',
                                    '#ff2020',
                                ],
                            }
                        ]
                    },
                    board_kr        :   board_kr_parse,
                    board_en        :   board_en_parse,
                    last_connect_member :   last_connect_member_list,
                    map_mark_loc        :   loc,

                    board_comment : comment_list,
                    board_comment_total : comment_total,

                    pre_url : pre_url,
                });
                
                

            }
            
        }).catch(function(error){
            //에러
            console.log(error);
        })

        window.scrollTo(0,0);

    }
    // 카드클릭 이벤트 함수
    card_click = (type) =>{ 
        //입력받은 값에 따라서 차트 데이터를 새로이 파싱합니다.

        // let data;              //통신용 데이터

        //이미 보고있는 화면은 그냥 리턴합니다.
        switch(type){
            case "day":
                    if(this.state.chart_h1_text === "일간 접속자") return;
                    this.setState({
                        current_chart_data : this.state.day_chart_data,
                        chart_h1_text : "일간 접속자"
                    })
                break;
            case "week":
                    if(this.state.chart_h1_text === "주간 접속자") return;
                    this.setState({
                        current_chart_data : this.state.week_chart_data,
                        chart_h1_text : "주간 접속자"
                    })
                break;
            case "month":
                    if(this.state.chart_h1_text === "월간 접속자") return;
                    this.setState({
                        current_chart_data : this.state.month_chart_data,
                        chart_h1_text : "월간 접속자"
                    })
                break;
            default :
                break;
        }

    }

    //들어갈 떄 회원 등급 확인, 아니라면 추방
    chk_user_grade=()=>{
        let grade = this.props.grade;
        if(grade !== "10"){
            this.props.history.push('/home');
        }
    }
    // 댓글 밴 처리하기
    comment_ban_add = (value) => {
        let current = this;

        let split = value.split("_");
        let comment_idx = split[0];
        let board_type = split[1];
        let request_type = "add";

        let data = API.BOARD_COMMENT_ADD_RECOVERY(
            comment_idx,
            board_type,
            request_type
        )
        API.axios_instance.post(`${process.env.REACT_APP_BOARD_URL}`,data)
        .then(function(res){
            let retJson = res.data;
            // console.log(retJson);
            if(retJson["retCode"] === 0){
                let list = current.state.board_comment;
                // console.log(list);
                for(var i in list){
                    if(list[i]["idx"] === comment_idx){
                        list[i]["visible"] = "O";
                        list[i]["ban"] = 
                        <button id="comment_btn_re" value={value} onClick={(e)=>current.comment_ban_remove(e.target.value)}>
                        복구</button>;
                    }
                }

                current.setState({
                    board_comment : list
                })
            }
        })
    }
    // 밴된 댓글 복구하기
    comment_ban_remove = (value) => {
        let current = this;

        let split = value.split("_");
        let comment_idx = split[0];
        let board_type = split[1];
        let request_type = "remove";

        let data = API.BOARD_COMMENT_ADD_RECOVERY(
            comment_idx,
            board_type,
            request_type
        )
        API.axios_instance.post(`${process.env.REACT_APP_BOARD_URL}`,data)
        .then(function(res){
            let retJson = res.data;
            if(retJson["retCode"] === 0){
                let list = current.state.board_comment;
                // console.log(list);
                for(var i in list){
                    if(list[i]["idx"] === comment_idx){
                        list[i]["visible"] = "X";
                        list[i]["ban"] = 
                        <button id="comment_btn_del" value={value} onClick={(e)=>current.comment_ban_add(e.target.value)}>
                        삭제</button>;
                    }
                }

                current.setState({
                    board_comment : list
                })
            }
        })
    }
    // 댓글 관리 페이지네이션
    comment_page_change(event){
        let current = this;

        // 통신진행
        let data = API.COMMENT_PAGENATION(event.page);
        // console.log(event);
        API.axios_instance.post(`${process.env.REACT_APP_BOARD_URL}`,data)
        .then(function(res){
            let retJson = res.data;
            // console.log(retJson); 
            //성공 했을 경우
            if(retJson['retCode'] === 0){
                let retBody = retJson['retBody'];
                let comment_en = retBody['board_comment']['en']['list'];
                let comment_kr = retBody['board_comment']['kr']['list'];
                // 댓글 총 개수 파악
                let comment_total = parseInt(retBody['board_comment']['en']['totCnt'])+parseInt(retBody['board_comment']['kr']['totCnt']);

                var comment_parse = new Map();
                // 두개로 나눠온 댓글 데이터 파싱
                for(var i = 0; i <comment_kr.length; i++){
                    // state = Y 면 사용자가 삭제 하지 않음 NULL처리pre_url
                    if(comment_kr[i]['state']==="Y") comment_kr[i]['state']="X";
                    else comment_kr[i]['state']="O";
                    // 관리자가 삭제했으면 복구처리 가능하게 변수 구분
                    let admin_delete = false;
                    // visible = Y 면 관리자가 삭제 하지 않음 NULL처리
                    if(comment_kr[i]['visible']==="Y") comment_kr[i]['visible']="X";
                    else {
                        comment_kr[i]['visible']="O";
                        admin_delete = true;
                    }

                    if(admin_delete){
                        comment_kr[i].ban = 
                            <button id="comment_btn_re" value={comment_kr[i]['idx']+"_KR"} onClick={(e)=>current.comment_ban_remove(e.target.value)}>
                                복구</button>;
                    }else{
                        comment_kr[i].ban = 
                            <button id="comment_btn_del" value={comment_kr[i]['idx']+"_KR"} onClick={(e)=>current.comment_ban_add(e.target.value)}>
                                삭제</button>;
                    }
                    
                    comment_parse.set(parseInt(comment_kr[i]['idx']),comment_kr[i]);
                }
                for(var y = 0; y <comment_en.length; y++){
                    let admin_delete = false;
                    // state = Y 면 사용자가 삭제 하지 않음 NULL처리
                    if(comment_en[y]['state']==="Y") comment_en[y]['state']="X";
                    else comment_en[y]['state']="O";
                    // visible = Y 면 관리자가 삭제 하지 않음 NULL처리
                    if(comment_en[y]['visible']==="Y") comment_en[y]['visible']="X";
                    else {
                        comment_en[y]['visible']="O";
                        admin_delete = true;
                    }

                    if(admin_delete){
                        comment_en[y].ban = 
                            <button id="comment_btn_re" value={comment_en[y]['idx']+"_EN"} onClick={(e)=>current.comment_ban_remove(e.target.value)}>
                                복구</button>;
                    }else{
                        comment_en[y].ban = 
                            <button id="comment_btn_del" value={comment_en[y]['idx']+"_EN"} onClick={(e)=>current.comment_ban_add(e.target.value)}>
                                삭제</button>;
                    }

                    comment_parse.set(parseInt(comment_en[y]['idx']),comment_en[y]);
                }
                // 맵을 댓글 idx값을 최신순으로 정렬
                let comment_list = Array.from((new Map([...comment_parse.entries()].sort()).values()));
                comment_list.reverse();
                current.setState({
                    board_comment_first : event.first,
                    board_comment : comment_list,
                    board_comment_total : comment_total,

                })
            }
        })
    }

    // 이전 페이지 경로 데이터를 파싱해서 뿌려주는 메서드
    referrer_url = () => {
        let return_data = [];
        // 이너 클래스 생성해서 객체로 카드뷰를 뿌려줍니다.
        // 반복 돌면서 아이템 싹 만들어서 return_data 에 넣어준다.
        this.state.pre_url.map((contact, i ) => {

            return_data.push(
                <li key={i}>
                    <Card id="card_box" title={contact.domain}>
                        <h1>{contact.count}</h1>
                    </Card> 
                </li>
            );
            
            return;
        });

        return return_data;
    }

    // 국가별로 카드 아이템을 만들어줍니다.
    country_card_item = () => {
        let return_data = [];

        this.state.pop_country.map((contact, i ) => {

            return_data.push(
                <li key={i}>
                    <Card id="card_box" title={contact.country}>
                        <h1>{contact.count}</h1>
                    </Card> 
                </li>
            );
            return;
        });

        return return_data;
    }
   
    render(){
        this.chk_user_grade()
        // console.log(this.state.board_comment_first);
        // console.log(this.state.board_comment_rows);
        // console.log(this.state.board_comment_total);
        return (
            <div className="admin_main">
                <h1>접속자 (클릭)</h1>

                <div className="admin_card_box">
                    <ul>
                        <li onClick={(e) => this.card_click("day")}>
                            <Card id="card_box" title="일간 접속자">
                                <h1>{this.state.access_today} </h1>
                            </Card> 
                        </li>
                        <li onClick={(e) => this.card_click("week")}>
                            <Card id="card_box" title="주간 접속자">
                                <h1>{this.state.access_week}</h1>
                                {/* <h1>{this.state.access_month}</h1> */}
                            </Card>
                        </li>
                        <li onClick={(e) => this.card_click("month")}>
                            <Card id="card_box" title="월간 접속자">
                                <h1>{this.state.access_month}</h1>
                                {/* <h1>{this.state.access_week}</h1> */}
                            </Card>
                        </li>
                        {/* <li onClick={(e) => this.card_click("new_member")}>
                            <Card id="card_box" title="신규 멤버">
                                <h1>{this.state.new_member}</h1>
                            </Card>
                        </li> */}
                        {/* <li onClick={(e) => this.card_click("all_member")}>
                            <Card id="card_box" title="총 회원">
                                <h1>{this.state.all_member}</h1>
                            </Card>
                        </li> */}
                        {/* <li onClick={(e) => this.card_click("country")}>
                            <Card id="card_box" title="인기 국가">
                                <h1>{this.state.pop_country}</h1>
                            </Card>
                        </li> */}
                    </ul>
                </div> 

                <div className="admin_chart">
                    <h1>{this.state.chart_h1_text}</h1>
                    <Chart type="line" data={this.state.current_chart_data} />
                </div>
                 {/* 이전 페이지가 어떤 페이지에서 접속하는지 */}
                <div className="admin_card_box">
                    <h1>이전 페이지 경로</h1>
                    <ul>
                        {this.referrer_url()}
                    </ul>
                </div>
                {/* 접속 비율로 나누는 막대 그래프 차트. */}
                <div className="admin_chart">
                    <Chart type="horizontalBar" data={this.state.domain_chart_data} /> 
                </div> 
                
                <div className = "admin_pop_board_table">
                    {/* <h1>댓글 리스트</h1>
                    <DataTable value={this.state.board_comment}>
                        <Column field="board_type"          header="언어" />
                        <Column field="ip"                  header="ip주소" />
                        <Column field="title"               header="게시판 제목" />
                        <Column field="comment"             header="댓글 내용" />
                        <Column field="state"               header="사용자 삭제" />
                        <Column field="visible"             header="관리자 삭제" />
                        <Column field="ban"                 header="삭제" />
                    </DataTable>
                    <Paginator first={parseInt(this.state.board_comment_first)} rows={10} totalRecords={parseInt(this.state.board_comment_total)} onPageChange={this.comment_page_change}></Paginator> */}
                    <h1>인기 게시판 KR TOP 5</h1>
                    <DataTable value={this.state.board_kr}>
                        <Column field="rank"                header="순위" />
                        <Column field="idx"                 header="보드 번호" />
                        <Column field="title"               header="제목" />
                        <Column field="view_count"          header="조회수" />
                        <Column field="idx_member"          header="작성자 idx 번호" />
                    </DataTable>

                    <h1>인기 게시판 EN</h1>
                    <DataTable value={this.state.board_en}>
                        <Column field="rank"                header="순위" />
                        <Column field="idx"                 header="보드 번호" />
                        <Column field="title"               header="제목" />
                        <Column field="view_count"          header="조회수" />
                        <Column field="idx_member"          header="작성자 idx 번호" />
                    </DataTable>
                </div>
                country_card_item
                <div className = "admin_recent_access_member">
                    <h1>최근 접속 회원</h1>
                    <DataTable value={this.state.last_connect_member}>
                        <Column field="idx"                 header="회원 번호" />
                        <Column field="e_address1"          header="이메일" />
                        <Column field="e_address2"          header="도메인" />
                        <Column field="name"                header="이름" />
                        <Column field="last_dt"             header="마지막 로그인" />
                    </DataTable>
                </div>

                <div className = "admin_access_country">
                    <h1>접속 국가</h1>
                    <div className="admin_card_box">
                        <ul>
                            {this.country_card_item()}
                        </ul>
                    </div>
                    
                    <GoogleMap loc={this.state.map_mark_loc}/>
                </div>
            </div>
        )
    }
}

//props 값으러 넣을 상태값 정의
const map_state_to_props = (state) => ({
    grade : state.user.grade
});


export default withRouter(connect(map_state_to_props)(AdminPage));
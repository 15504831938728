import React from 'react';
import './Trend.css';

import {Menubar} from 'primereact/menubar';

import {Chart} from 'primereact/chart';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet'
import * as lang_pack from '../../utils/language_pack';
import * as Api from '../../utils/Api';
import {get_previous_url} from '../../utils/Func';


// 뷰 컨트롤용 변수들
var MAIN_VIEW = 0;
var SEARCH_VIEW = 1;



// 팀노바 페이지를 이용하는 사람들의 검색어를 기준으로 트렌드를 검색하는 기능입니다.
class Trend extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            // 뷰 컨트롤
            now_view : MAIN_VIEW,
            // 언어
            lang_data : lang_pack.lang_trend(props.lang),  //언어 팩이 들어있는 state 입니다.
            // 헤더
            head_input_placeholder : "",
            head_input_value : "",
            // 최근 인기 검색어
            recent_pop_data : [],
            recent_pop_color : ['#CCCCCC','#AAAAAA','#888888','#666666','#444444','#222222'],

            // 검색어 페이지
            search_value_list : [],
            search_value_color : ['#30A9DE','#EFDC05','#E53A40','#8CD790'],        
            search_value_input : "",

            // 바디
            // 관심도 변화
            interest_chart_item : {},

            // 연관검색어
            related_search_item : {},

            // 메인에서 선택함에 따라 바뀌는 관심도 변화 담기 , 기본 0번 
            main_pop_chart_item : [],

        };

   
    }

    
    componentWillMount(){
        get_previous_url();
    }

    componentWillReceiveProps(nextProps){
        //언어 관리
        let lang_data;
        switch(nextProps.lang){
            case lang_pack.KOREAN:
                //언어 팩 받아오기 map 형태
                lang_data = lang_pack.lang_trend(lang_pack.KOREAN);
                break;
            case lang_pack.ENGLISH:
                lang_data = lang_pack.lang_trend(lang_pack.ENGLISH); 
                break; 
            default:break;
        }
        this.setState({
            lang_data : lang_data,
            head_input_placeholder : lang_data.get("head").get("placeholder")
        })        
    }

    componentDidMount(){
        var current = this;
        // console.log(this.state.lang_data); 
        this.setState({
            head_input_placeholder : this.state.lang_data.get("head").get("placeholder")
        })

        // 데이터 통신 진행 첫화면 리스트 받아오기
        let data = Api.GET_TREND();

        Api.axios_instance.post(`${process.env.REACT_APP_PAGES}`,data)
        .then(function(res){
            var retJson = res.data;
            // console.log(retJson);
            if(retJson['retCode']===0){
                current.setState({
                    recent_pop_data : retJson['retBody']['pop_list']['list']
                })
            }
        })

        // 뒤로가기 키 제어
        // 비교 데이터를 들어 갔을 때 뒤로가기 키를 누르면 트렌드 메인 화면으로 들어가게 되는 것이 아니라 이전에 들렀던 페이지로 들어가게 된다.
        // 그것을 막기 위해서 비교중 일 때 메인 페이지로 이동하도록 구현해준다.
        window.history.pushState(null,null,window.location.href);
        window.onpopstate = function(){
                // 현 상태 비교중인지 판단.
                if(current.state.now_view === SEARCH_VIEW){
                    // 비교 페이지 && 뒤로가기 버튼 클릭
                    current.setState({
                        // 화면을 바꿔줌.
                        now_view : MAIN_VIEW
                    })
                }
        }

    }


    
    
    // 헤더
    // 인풋 포커스
    head_input_focus = () => {
        this.setState({
            head_input_placeholder : ""
        })
    }
    // 인풋 포커스 아웃
    head_input_blur = () => {
        this.setState({
            head_input_placeholder : this.state.lang_data.get("head").get("placeholder")
        })
    }
    // 인풋 아이콘 클릭 --> 검색 시작
    head_input_click = () => {
        alert(this.state.lang_data.get("err").get("not_yet")); 
        // // 임시로 가져오는 검색어 리스트
        // let temp_value_list  = this.state.search_value_list;
        // temp_value_list.push(this.state.head_input_value);

        // this.setState({
        //     now_view : SEARCH_VIEW,
        //     search_value_list : temp_value_list
        // })
    }
    // 메인 검색어 입력 후 엔터
    head_input_keypress = (event) => {
        if(event.key === "Enter"){
            alert(this.state.lang_data.get("err").get("not_yet"));
            // // 임시로 가져오는 검색어 리스트
            // let temp_value_list  = this.state.search_value_list;
            // temp_value_list.push(this.state.head_input_value);

            // this.setState({
            //     now_view : SEARCH_VIEW,
            //     search_value_list : temp_value_list
            // })
        }
    }
    // 날짜 비교 
    dateDiff(_date1, _date2) {
        var diffDate_1 = _date1 instanceof Date ? _date1 : new Date(_date1);
        var diffDate_2 = _date2 instanceof Date ? _date2 : new Date(_date2);
     
        diffDate_1 = new Date(diffDate_1.getFullYear(), diffDate_1.getMonth()+1, diffDate_1.getDate());
        diffDate_2 = new Date(diffDate_2.getFullYear(), diffDate_2.getMonth()+1, diffDate_2.getDate());
     
        var diff = Math.abs(diffDate_2.getTime() - diffDate_1.getTime());
        diff = Math.ceil(diff / (1000 * 3600 * 24));
     
        return diff;
    }
    
    
    // 바디
    // 인기 검색어
    recent_pop_content = () => {
        let div = [];

        for(var idx in this.state.recent_pop_data){

            var title = this.state.recent_pop_data[idx]['word'];
            // 검색어 숫자 요약 숫자
            var temp_count = parseInt(this.state.recent_pop_data[idx]['search_count']);
            var count = 0;
            if(temp_count > 10000){
                count = parseInt((temp_count /10000)) + "+M";
            }
            else if(temp_count > 1000){
                count = parseInt((temp_count /1000)) + "+K";
            }else if (temp_count > 10){
                count = parseInt((temp_count /10))+"0+";
            }else {
                count = temp_count+"+";
            }
            let temp_labels = [];
            let temp_data = [];
            var list = this.state.recent_pop_data[idx]['reg_list']['list'];
            // console.log(list);
            for(var item in this.state.recent_pop_data[idx]['reg_list']['list']){
                temp_labels.push(list[item].d);
                temp_data.push(parseInt(list[item].counter));
                //  console.log(list.length +"/"+item);
                // if(parseInt(item)+1 === list.length  && this.dateDiff(list[item].d,new Date()) >= 1){
                //     // 데이트 차이를 구했으니 여기에서 오늘 날짜부터 빈 날짜를 채워주고 데이터도 0으로 넣어줍니다.
                //     for(var date_count = 1 ; date_count <= this.dateDiff(list[item].d,new Date()) ; date_count++){
                //         // console.log(list[item].d + "///" + list[item].counter + "///" + date_count);
                //         // 데이트 형식으로 바꾸기
                //         var ori_date = new Date(list[item].d);
                //         ori_date.setDate(ori_date.getDate() + date_count);

                //         var plus_date =ori_date.getFullYear()+"-"+(ori_date.getMonth()+1)+"-"+ori_date.getDate();
                //         temp_labels.push(plus_date);
                //         temp_data.push(0);
                //     }
                // }
            }

            // this.state.recent_pop_data[idx]['reg_list']['list'].map( function(v,i){
            //     temp_labels.push(v.d);
            //     temp_data.push(parseInt(v.counter));
            //     // console.log(v.d);
            //     return v+i;
            // })

            const data = {
                labels: temp_labels,
                datasets: [
                    {
                        label: 'First Dataset',
                        data: temp_data,
                        fill: false,
                        backgroundColor: this.state.recent_pop_color[idx],
                        borderColor: this.state.recent_pop_color[idx]
                    },
                ]   
            };
            const options = {
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                },
                tooltips: {
                    enabled: false,
                },
                layout : {
                    padding : {
                        left : 5,
                        right : 5,
                        top : 5,
                        bottom : 5,
                    }
                },
                scales: {
                    yAxes: [{
                        display: false,
                    }],
                    xAxes : [{
                        display: false,
                    
                    }]
                },
                animation : false,
            };
            div.push(
                
                <div id={idx} key={idx} className={"items"} onClick={(e) => this.setState({main_pop_chart_item : this.state.recent_pop_data[e.currentTarget.id]})}> 
                    <span id="title">{title}</span>
                    <span id="count">{count}</span>
                    <div id="chart" >
                        <Chart type="line" data={data} options={options} style={{width : "100px"}}/> 
                    </div>
                </div>
            )
        }

        div.push(
                
            <div key={6} className={"items"} onClick={()=>alert(this.state.lang_data.get("err").get("not_yet"))}>
                <span>{this.state.lang_data.get("body").get("pop_more")}</span>
            </div>
        )
        
        return div;
    }
    // 검색 관련
    // 검색어 추가하기 인풋 키 프레스 이벤트
    search_item_add = (event) => {
        // 엔터키 누르면 검색 시작
        if(event.key === "Enter"){
            //입력값 가져오기
            let value = this.state.search_value_input;
            //빈값 체크
            if(value === "") {
                this.setState({
                    search_value_input : "",
                })
                return;
            }

            // 중복값 체크
            let temp_list = this.state.search_value_list;
            for(var i in temp_list){
                if(temp_list[i] === value){
                    this.setState({
                        search_value_input : "",
                    })
                    return;
                }
            }
            //없다면 아이템 추가
            temp_list.push(value);

            this.setState({
                search_value_list : temp_list,
                search_value_input : "",
            })

        }
    }
    // 검색어 추가하기 버튼 클릭
    search_item_add_click =() =>{
        alert(this.state.lang_data.get("err").get("not_yet")); 
        // let value = this.state.search_value_input;
        
            //빈값 체크
            // if(value === "") {
            //     this.setState({
            //         search_value_input : "",
            //     })
            //     return;
            // }

            // // 중복값 체크
            // let temp_list = this.state.search_value_list;
            // for(var i in temp_list){
            //     if(temp_list[i] === value){
            //         this.setState({
            //             search_value_input : "",
            //         })
            //         return;
            //     }
            // }
            // //없다면 아이템 추가
            // temp_list.push(value);

            // this.setState({
            //     search_value_list : temp_list,
            //     search_value_input : "",
            // })
    }
    // 검색어 삭제하기 버튼 클릭
    search_item_del = (e) => {
        var value = e.target.getAttribute('value');
        let temp_list = this.state.search_value_list;

        temp_list.splice(temp_list.indexOf(value),1);
        
        this.setState({
            search_value_list : temp_list
        })
    }
    // 검색 페이지에서 검색어 기준 동적으로 화면을 추가해주는 펑션
    search_box_add=()=>{
        let search_list = this.state.search_value_list;

        let view_div = [];
        // 단어 를 반복적으로 보여주고
        for(var i in search_list){
            view_div.push(
                <div key={i} className="item">
                    <span id="circle"><div style={{backgroundColor:this.state.search_value_color[i]}}>&nbsp;</div></span>
                    <span id="title">{search_list[i]}</span>
                    <span id="delete"><i className="pi pi-times" value={search_list[i]} onClick={(e)=> this.search_item_del(e)}></i></span>
                </div>
            )
        }
        //아이템의 개수가 3개가 되면 추가 검색어는 넣지 않는다.
        if(search_list.length >= 3){
            return view_div;
        }
        i++;
        view_div.push(
            <div key={4} className="item">
                <span id="circle"><div style={{backgroundColor:this.state.search_value_color[i]}}>&nbsp;</div></span>
                <span id="add"><input value={this.state.search_value_input} placeholder={"검색어 추가"} onKeyPress={this.search_item_add} onChange={(e)=> this.setState({search_value_input : e.target.value})}/></span>
                <span id="plus" onClick={()=>this.search_item_add_click()}><i className="pi pi-plus"></i></span>
            </div>
        );

        return view_div;
    }

    // 바디부분
    // 관심도 변화
    interest_change = () => {
        let return_div;
        // 차트에 들어가는 맵 데이터
        let chart_map = this.state.interest_chart_item;
        // 메인에서 접속할 때 보여주는지 , 아닌지를 구분
        switch(this.state.now_view){
            case MAIN_VIEW:
                    // 메인 처음 로딩시에는 가장 인기있는 데이터를 보여줍니다.
                    // 차트 데이터가 없다면 리턴
                    if(this.state.recent_pop_data === undefined || this.state.recent_pop_data.length === 0) return;
                    let pop_list;
                    if(this.state.main_pop_chart_item.length === 0 ){
                        //선택 아이템이 없다면 첫번째로 지정
                        this.setState({
                            main_pop_chart_item : this.state.recent_pop_data[0]
                        })
                        pop_list = this.state.recent_pop_data[0];
                    }else{
                        // 있다면 선택했던 아이템으로 지정.
                        pop_list = this.state.main_pop_chart_item;
                    }



                    let temp_labels = [];   //라벨 파싱
                    let temp_data = [];     //데이터 파싱
                    let max_value = -1;     //최고값 파싱

                    for(var item in pop_list['reg_list']['list']){
                        temp_labels.push(pop_list['reg_list']['list'][item].d);
                        temp_data.push(parseInt(pop_list['reg_list']['list'][item].counter));

                        let value = parseInt(pop_list['reg_list']['list'][item].counter);
                        if(max_value < value) max_value = value;
                    }
                    // 최고값 에 대해서 10% 추가해서 최대 값을 측정해줍니다.
                    if(max_value < 10){
                        // 10미만일 경우 추가가 되지 않기 때문에 +1을 해줍니다.
                        max_value = max_value + 1;
                    }else{
                        max_value = parseInt(max_value + (max_value * 0.1));
                    }
                    let data = {
                        labels: temp_labels,
                        datasets: [
                            { 
                                label: pop_list['word'],
                                data: temp_data,
                                fill: true,
                                backgroundColor: this.state.recent_pop_color[0],
                                borderColor: this.state.recent_pop_color[0]
                            },
                        ]   
                    };
                    let options = {
                        title: {
                            display: true,
                            text : pop_list['word']+", "+pop_list['search_count']+this.state.lang_data.get("body").get("pop_count"),
                            fontSize : 24
                        },
                        legend: {
                            display: false,
                        },
                        tooltips: {
                            enabled: true,
                        },
                        layout : {
                            padding : {
                                left : 20,
                                right : 20,
                                top : 20,
                                bottom : 20,
                            }
                        },
                        scales: {
                            yAxes: [{
                                display: true,
                                ticks : {
                                    beginAtZero: true,
                                    max : max_value,
                                    
                                }
                            }],
                            xAxes : [{
                                display: true,
                                ticks:{
                                    maxRotation: 0,
                                    minRotation: 0,
                                    callback: function(tick, index, array) {
                                        // 시작과  끝은 일단 날짜를 보여준다.
                                        if(index === 0) return tick;
                                        if(index === array.length-1) return tick;
                                        // 최대 3개 까지 더 보여주도록 한다.
                                        var count_mid = array.length-3; //첫번째와 마지막 개수를 뺴준 전체 개수
                                        var count_per = parseInt(count_mid / 3 ); 
                                        if(index % count_per === 0){
                                            return tick;
                                        }else{
                                            return "";
                                        }
                                  }
                                },
                                
                            }]

                        },
                        
                        animation : false,
                        responsive: true, 
                    };
                    // console.log(options);
                    // 반환용 ui
                    return_div = (
                        // 메인페이지 관심도 변화 차트 그래프
                        <div id="time_graph_chart">
                            {/* 왼 이동 아이콘 - 클릭하게되면 이전 차트 데이터로 넘어감 */}
                            <div id="arrow_icon" style={{flexGrow:"1"}}><i className="pi pi-chevron-left" onClick={()=>this.main_chart_left_move()}></i></div>
                            {/* 차트 */}
                            <div id="chart" style={{flexGrow:"2"}}><Chart type="line" data={data} options={options} style={{width : "100%"}}/></div>
                            {/* 우이동 아이콘 - 클릭하게 되면 다음 차트 데이터로 넘어감*/}
                            <div id="arrow_icon" style={{flexGrow:"1"}}><i className="pi pi-chevron-right" onClick={()=>this.main_chart_right_move()}></i></div>
                        </div>
                    )
                break;
            case SEARCH_VIEW:
                    if(!chart_map.labels){
                        // 비어있을 때 화면 리런
                        return_div = (
                            <div id="not_found">
                                <img 
                                    alt={"teamnova_chart_error"}  
                                    src={process.env.REACT_APP_BASE_URL+"/test/_nova/_img/_icon/error_icon.png"} 
                                />
                                <h3>
                                    데이터가 없습니다
                                </h3>
                                <h5>
                                    검색어를 올바르게 입력했는지 확인 하거나, 좀 더 대중적인 용어를 검색하세요
                                </h5>
                            </div>
                        )
                    }else{
                        console.log("들어있네");
                    }
                break;
            default : break;
        }
        
    
        return return_div; 
    }
    // 메인 차트 왼쪽 이동
    main_chart_left_move = () => {
        // 현재 차트의 인덱스 찾기
        let now_item = this.state.main_pop_chart_item;  //현 선택 아이템
        let item_index = this.state.recent_pop_data.indexOf(now_item); // 올 아이템에서 인덱스 찾기
        // console.log(item_index);

        if(item_index === 0){
            //0이라면 마지막 아이템의 인덱스를 현 아이템으로 넣어주기
            this.setState({
            main_pop_chart_item : this.state.recent_pop_data[this.state.recent_pop_data.length-1]
            })
        }else{
            //아니라면 1씩 감소
            this.setState({
                main_pop_chart_item : this.state.recent_pop_data[item_index-1]
            })
        }
    }
    // 메인 차트 오른쪽 이동
    main_chart_right_move = () => {
        // 현재 차트의 인덱스 찾기
        let now_item = this.state.main_pop_chart_item;  //현 선택 아이템
        let item_index = this.state.recent_pop_data.indexOf(now_item); // 올 아이템에서 인덱스 찾기
        
        if(item_index === this.state.recent_pop_data.length-1){
            //마지막 아이템 인덱스라면 0으로 데이터 넣어주기
            this.setState({
                main_pop_chart_item : this.state.recent_pop_data[0]
                })
        }else{
            //아니라면 1씩 증가
            this.setState({
                main_pop_chart_item : this.state.recent_pop_data[item_index+1]
            })
        }
    }
    // 연관 검색어 보여주기 ( 반복 )
    related_search_loop = () => {
        // 검색어 기준으로 연관 검색어를 반복해서 보여줍니다.
        let return_div;
        
        return_div = (
            <div className="related_search">
                <div id="title"><h3>연관검색어 - (검색어)</h3></div>
                <div id="data">
                    {this.realted_search_data()}
                </div>
            </div>
        )

        return return_div;
    }
    // 연관검색어 데이터
    realted_search_data = () => {
        let return_div;

        let data_map = this.state.related_search_item;

        if(!data_map.data){
            return_div = (
                <div id="not_found">
                    <img 
                        alt={"teamnova_chart_error"} 
                        src={process.env.REACT_APP_BASE_URL+"/test/_nova/_img/_icon/error_icon.png"} 
                    />
                    <h3>
                        데이터가 없습니다
                    </h3>
                    <h5>
                        검색어를 올바르게 입력했는지 확인 하거나, 좀 더 대중적인 용어를 검색하세요
                    </h5>
                </div>
            )
        }
        
        
        return return_div;
    }
    
    // 페이지 이동 없이 화면을 바꿔주는 역활을 하는 펑션
    view_changer = (idx) => {
        
        if(idx === MAIN_VIEW){
            // 메인뷰
            return(
                <div>
                    <div className="trend_head_con_area">
                        <div className="head_title">
                            <h1>{this.state.lang_data.get("head").get("title")}</h1>
                        </div>
                        {/* <div className={"head_input"}>
                            <input 
                                placeholder={this.state.head_input_placeholder} 
                                onFocus={()=>this.head_input_focus()} 
                                onBlur={()=>this.head_input_blur()} 
                                value = {this.state.head_input_value}
                                onChange={(e)=>this.setState({head_input_value : e.target.value})}
                                onKeyPress={this.head_input_keypress}
                                >
                                </input>
                            <img 
                                alt={"teamnova_input_icon"} 
                                src={process.env.REACT_APP_BASE_URL+"/test/_nova/_img/_icon/white_search_icon.png"} 
                                onClick={()=>this.head_input_click()}
                            />
                        </div> */}
                    </div>
                    <div className="trend_mid_con_area">
                        {/* 인기 검색어 */}
                        <div className="pop">
                            <div className="title">
                                <h3>{this.state.lang_data.get("body").get("pop_title")}</h3>
                            </div>
                            <div className="sub_title">
                                <h5>{this.state.lang_data.get("body").get("pop_sub_title")}</h5>
                            </div>
                            <div className="content">
                                {this.recent_pop_content()}
                            </div>
                        </div>
                        {/* 인기 비교 데이터*/}
                        <br /><br />
                    </div>

                    <div className="search_body_con_area">
                        <div className="wallpaper">
                            {/* 관심도 변화 */}
                            <div className="time_graph">
                                <div id="title"><h3>{this.state.lang_data.get("body").get("pop_change_interest")}</h3></div>
                                <div id="left_chart">
                                    {this.interest_change()}  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }else if(idx === SEARCH_VIEW){
            const drop_down_menu_bar = [
                {
                    label:'대한민국',
                    icon:'pi pi-map-marker',
                    items:[
                        {
                            label:'대한민국',
                         },
                         {
                            label:'미국',
                         }
                    ]
                 },
                 {
                    label:'지난 30일',
                    icon:'pi pi-calendar',
                    items:[
                        {
                            label:'지난 12개월',
                         },
                         {
                            label:'지난 6개월',
                         },
                         {
                            label:'지난 30일',
                         },
                         {
                            label:'지난 15일',
                         },
                         {
                            label:'지난 7일', 
                         },
                         {
                            label:'지난 1일',
                         },
                         {
                            label:'지난 12시간',
                         }
                    ]
                 },
            ]
            // 검색 을 하게 되면 들어오게 되는 뷰
            return(
                <div>
                    <div className="search_head_con_area">
                        <div className="search_box_con">
                            <div className="search_box">
                                {this.search_box_add()}
                            </div>
                        </div>
                        <div  className="search_compare_picker_area">
                            <div className="con_area"> 
                                <Menubar model={drop_down_menu_bar}/>
                            </div>
                        </div>
                    </div>
                    <div className="search_body_con_area">
                        <div className="wallpaper">
                            {/* 관심도 변화 */}
                            <div className="time_graph">
                                <div id="title"><h3>관심도 변화</h3></div>
                                <div id="left_chart">
                                    {this.interest_change()} 
                                </div>
                            </div>
                            {/* 관련 검색어 */}
                            {this.related_search_loop()}
                        </div>
                    </div>
                </div>
            )
        }
    }
    render(){
        return(
            <div className="trend_main">
                <Helmet>
                        <title>팀노바테크 | 팀노바 트렌드</title>
                        <meta name="description" content="팀노바 최신 트렌드 분석"/>  
                        <meta name="keywords" content={"팀노바테크, 팀노바, 팀노바 인기, 팀노바 기술, 팀노바 트렌드"} />
                </Helmet>

                {this.view_changer(this.state.now_view)}
                
            </div>
        )
    }
}

let mapStateToProps = ( state ) => {
	return {
        lang : state.user.lang,
	}
};

export default connect(mapStateToProps)(Trend);

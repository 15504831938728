import React from 'react';
import './page_404.css';
import {withRouter} from 'react-router-dom';

class page_404 extends React.Component{
    back_home =() =>{
        this.props.history.push('/home');
    }
    render(){
        return(
            <div className="page_404_main_div">
                <img alt="팀노바 404page" src={process.env.REACT_APP_BASE_URL+"/_nova/_img/_icon/404.png"} />
                <h1>Oh no! Page not found.</h1>
                <h3 onClick={() =>this.back_home()}>back to the HOME</h3>

            </div>
        )
    }
} 

export default withRouter(page_404);